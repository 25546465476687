/**
  Room data display in hotel Details page  
**/

import React, {useState} from 'react';
import Modal from 'react-modal';

const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
};

export const HotelRoomsData = ({sks, roomImg, roomName, cancellationPolicy, totalPass, meal, totalPrice, flightUrl, roomkey, selectedRoom, bookRoom}) => {

    const [modalCancellation, setModalCancellation] = useState(false);
    

    const openModalCancellation = () => {
        setModalCancellation(true);
    }

    const closeModalCancellation = () => {
        setModalCancellation(false);
    }

    let hotelTotalPass = 0;
  
    //Display Default Image
    const addDefaultSrc = (ev) => {
        ev.target.src = process.env.PUBLIC_URL +"/assets/images/noimage.jpg";
    }
    
    return (
        <div key={'hlst_'+sks} className="hotel-list bg-white">
            <div className="d-flex flex-wrap">
                {roomImg ? (
                    <div className="col-md-4 position-relative hotel-left">
                        <div className="hotel-image">
                            <a><img src={roomImg}  className="img-fluid" /></a>
                        </div>
                    </div>) :
                    (<div className="col-md-1 position-relative hotel-left"></div>)
                }
                <div className="col-md-10 hotel-right"> {/*if roomImg col-md-8 and name md-7*/}
                    <div className="d-flex flex-wrap hotel-details">
                        <div className="col-md-12 col-sm-12 pl-0">
                        <h5><a>{roomName}</a></h5>
                        </div>
                        {cancellationPolicy != ''
                        ? 
                            <div className="col-md-5 col-sm-5 cancelation text-left text-sm-right text-md-right pr-0">
                                <span className="canpl" onClick={() => (openModalCancellation())}><i className="fas fa-info-circle"></i><strong>Cancellation Policy &
                                    Important Information</strong>
                                </span>
                            </div>
                        : ''
                        }

                        <Modal
                            isOpen={modalCancellation}                                                             
                            onRequestClose={closeModalCancellation}
                            style={customStyles}

                        >
                            
                            <div className="modal-header">
                                <div className="modal-title h4">Cancellation Policy</div>
                                {/* <button type="button" className="close"><span aria-hidden="true">×</span><span className="sr-only">Close</span></button> */}
                            </div>
                            <div className="modal-body">
                                <strong>
                                    <div>
                                        {cancellationPolicy}
                                        <div className="py-3 font-weight-normal">*Cancellation policies are based on date and time of destination.</div>
                                    </div>
                                </strong>
                                <div className="txt-gray-blue">

                                </div>
                            </div>
                            <div className="passenger_modal_btn">
                                <button type="button" onClick={closeModalCancellation}>close</button>
                            </div>
                            
                        </Modal>
                    </div>
                    
                    <div className="hotel-services">
                        <ul className="p-0 m-0 d-flex flex-wrap">
                            <li className="guests"><span>Guests <i className="fas fa-user" aria-hidden="true"></i> <b>X {totalPass}</b></span></li>
                            <li>Meal: <span>{meal}</span></li>
                        </ul>
                    </div>
          
                    <div className="d-flex flex-wrap justify-content-between align-items-end">
                        <div className="flight-dest">
                        Hotel & Flight
                        <span className="flight-price">{totalPrice}</span>
                        
                        </div>
                        {selectedRoom.includes(roomkey)
                        ? <div className="check-price"><a className="roomBooked">Booked</a></div>
                        : <div className="check-price"><a onClick={() => bookRoom(roomkey)}>Book Now</a></div>
                        }
                        {/* <div className="check-price"><a href={`${flightUrl}/${roomkey}`}>Book Now</a></div> */}
                        {/* <div className="check-price"><a onClick={() => bookRoom(sks)}>Book Now</a></div> */}
                    </div>
                </div>
            </div>
        </div>
    )
    
}
