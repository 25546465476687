/**
  Flight List Page.
  Html and data display by API,
  User can modify search by click on "Modify Search" in left top button
  User can filter data by left section Stops, Price, Departure Time, Total flight duration,  Layout airport and All airlines 
  Also can filter data by price ordering Ase or Desc from right top "Cheapest"
**/

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { getFlights } from "../redux/actions/dataActions";
import { commanConst } from "../redux/constants/commanConst";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { FlightItem } from "./FlightItem";
import { useParams } from "react-router-dom";
import moment from "moment";
import { ItemPagination } from "./ItemPagination";
import { FlightAirlines } from "./FlightAirlines";
import { FlightAirPorts } from "./FlightAirPorts";
import { Range } from "rc-slider";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import { FlightListModifySearch } from "./FlightListModifySearch";
import cloneDeep from "lodash/cloneDeep";
import { CLIENT_INFO } from "../redux/constants/commanConst";

export const FlightList = () => {
  const showMenu = false;

  let disNumOfPage = 5; //default Page number ex: 1,2,3..
  let ustartPage = "";
  let uendPage = "";

  const flight = useSelector((state) => state);
  const dispatch = useDispatch();
  const {
    a,
    budget,
    depacityName,
    depacity,
    destinationName,
    destination,
    destLat,
    destLon,
    depadate,
    returndate,
    passengers,
    totalAdult,
    totalChild,
    totalInfant,
    totalYouth,
    c_key,
    hid,
    room_key,
  } = useParams();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemPerPage] = useState(10);
  const [startPage, setStartPage] = useState(1);
  const [endPage, setEndPage] = useState(5);
  const [showNoOfAirport, setShowNoOfAirport] = useState(2);
  const [airPortFilt, setAirPortFilt] = useState([]);
  const [airLinsFilt, setAirLinsFilt] = useState([]);
  const [allAirlinesChbox, setAllAirlinesChbox] = useState([]);
  const [allAirportsChbox, setAllAirportsChbox] = useState([]);
  const [hotelsLoader, setHotelsLoader] = useState("block");
  const [stopFilter, setStopFilter] = useState([]);
  const [minPrice, setMinPrice] = useState(0);
  const [maxPrice, setMaxPrice] = useState(0);
  const [maxPriceLimit, setMaxPriceLimit] = useState(0);
  const [depMinTime, setDepMinTime] = useState(0);
  const [depMaxTime, setDepMaxTime] = useState(24);
  const [retMinTime, setRetMinTime] = useState(0);
  const [retMaxTime, setRetMaxTime] = useState(24);
  const [depMinFlightDur, setDepMinFlightDur] = useState(0);
  const [depMaxFlightDur, setDepMaxFlightDur] = useState(0);
  const [depFlightDur, setDepFlightDur] = useState(0);
  const [retMinFlightDur, setRetMinFlightDur] = useState(0);
  const [retMaxFlightDur, setRetMaxFlightDur] = useState(0);
  const [retFlightDur, setRetFlightDur] = useState(0);
  const [flightsOrdering, setFlightsOrdering] = useState("ASC");
  const [filterForm, setFilterForm] = useState("show");
  const [sideFilter, setSideFilter] = useState("block");

  let totalFlights = 0;
  let currentItems = "";
  let currentAirport = "";
  let airportsArr = [];
  let airlinesArr = {};
  let flightData = [];
  let modifyHighestPr = 0;
  let cheapestPrice = 0;
  let ttpage;
  let airportFilterArr = [];
  let airlinesFilterArr = [];
  let combineFilterArr = [];
  let stopsFilterArr = [];
  let airlinesFilterChboxArr = {};
  let airPortsFilterChboxArr = {};
  let priceFilterArr = [];
  let departureOutFilterArr = [];
  let departureRetFilterArr = [];
  let flightDurOutArr = [];
  let flightDurOutDataArr = [];
  let flightDurRetArr = [];
  let flightDurRetDataArr = [];

  let ddate = moment(depadate, "MM/DD/YYYY").format("MM/DD/YYYY");
  let rdate = moment(returndate, "MM/DD/YYYY").format("MM/DD/YYYY");

  //Display Dates
  let disdepadate = moment(depadate, "MM/DD/YYYY").format("MMM DD");
  let disreturndate = moment(returndate, "MM/DD/YYYY").format("MMM DD");

  //Get Flight List
  const fetchFlight = async () => {
    await axios
      .get(
        `https://package.militaryfares.com/?method=pricevalidate&lang=en&curr=USD&affiliate=${a}&master=${a}&flight[from][0]=${depacity}&flight[from][1]=${destination}&flight[to][0]=${destination}&flight[to][1]=${depacity}&flight[outdate][0]=${ddate}&flight[outdate][1]=${rdate}&flight[adult]=${totalAdult}&flight[senior]=0&flight[youth]=${totalYouth}&flight[child]=${totalChild}&flight[infant]=${totalInfant}&flight[cabin]=Y&flight[trip]=round&hotel[cache_key]=${c_key}&hotel[code]=${hid}&hotel[room_key]=${room_key}`
      )
      .then((response) => {
        if (
          typeof response != "undefined" &&
          response != null &&
          response.status === 200
        ) {
          if (response.data.status != "ERR") {
            dispatch(getFlights(response.data));

            setTimeout(() => {
              selAllAirlines(true);
              selAllAirPorts(true);
              modifyHighestPrice();
              setHotelsLoader("none");
            }, 300);
          } else {
            dispatch(getFlights(""));
            setHotelsLoader("none");
          }
        }
      })
      .catch((err) => {
        dispatch(getFlights(""));
        console.log("Err", err);
        setHotelsLoader("none");
      });
  };

  useEffect(() => {
    setHotelsLoader("block");
    dispatch(getFlights(""));
    fetchFlight();

    setTimeout(() => {
      setFilterForm("");
      setSideFilter("none");
    }, 300);
    console.log(
      `https://package.militaryfares.com/?method=pricevalidate&lang=en&curr=USD&affiliate=${a}&master=${a}&flight[from][0]=${depacity}&flight[from][1]=${destination}&flight[to][0]=${destination}&flight[to][1]=${depacity}&flight[outdate][0]=${ddate}&flight[outdate][1]=${rdate}&flight[adult]=${totalAdult}&flight[senior]=0&flight[youth]=${totalYouth}&flight[child]=${totalChild}&flight[infant]=${totalInfant}&flight[cabin]=Y&flight[trip]=round&hotel[cache_key]=${c_key}&hotel[code]=${hid}&hotel[room_key]=${room_key}`
    );
  }, []);

  let UserInfo = cloneDeep(CLIENT_INFO);
  let isMobile =
    /iPhone|iPad|iPod|Android|BlackBerry|Windows Phone|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront/i.test(
      navigator.userAgent
    );
  if (isMobile) {
    UserInfo.device_type = "mobile";
  } else {
    UserInfo.device_type = "computer";
  }

  const TimeToms = (date1, date2) => {
    let duration = Math.abs(date2 - date1);
    return duration;
  };

  // Hotel data
  const tolGuest =
    parseInt(totalAdult) +
    parseInt(totalChild) +
    parseInt(totalInfant) +
    parseInt(totalYouth);

  const hotelDetsils = useSelector((state) => {
    if (
      state.HotelDetail.hotelDetls !== undefined &&
      state.HotelDetail.hotelDetls !== ""
    ) {
      return state.HotelDetail.hotelDetls.hotels;
    }
    return null;
  });

  const [slidimg, setSlidimg] = useState(0);

  let hotalDeta = [];

  let totalSlid = 0;
  if (hotelDetsils !== null && hotelDetsils !== undefined) {
    if (
      hotelDetsils[0].details !== undefined &&
      hotelDetsils[0].details !== "" &&
      hotelDetsils[0].details !== null
    ) {
      if (hotelDetsils[0].details.length !== 0) {
        totalSlid = hotelDetsils[0].details.imgs.length;
      }
    }
  }
  useEffect(() => {
    sliderimg("");
  }, [slidimg]);

  let timer1 = null;
  const sliderimg = (e) => {
    if (e == "stop") {
      clearTimeout(timer1);
    } else {
      timer1 = setTimeout(() => {
        let slidcount = slidimg + 1;
        if (slidcount < totalSlid) {
          setSlidimg(slidcount);
        } else {
          setSlidimg(0);
        }
      }, 3000);
    }
  };

  const nextImage = (e) => {
    sliderimg("stop");
    let slidcount = slidimg + 1;
    if (slidcount < totalSlid) {
      setSlidimg(slidcount);
    } else {
      setSlidimg(0);
    }
  };

  const prevImage = () => {
    sliderimg("stop");
    let slidcount = slidimg - 1;
    if (slidcount < 0) {
      setSlidimg(totalSlid - 1);
    } else {
      setSlidimg(slidcount);
    }
  };

  if (hotelDetsils !== null && hotelDetsils !== undefined) {
    hotalDeta = hotelDetsils.map((hotel, i) => {
      let disfacil = [];
      let morefacil = [];
      let service = hotel.service;
      let hotelName = hotel.name;
      let hotelDesc = hotel.desc;
      let hotelAddress =
        hotel.location.address +
        ", " +
        hotel.location.city +
        ", " +
        hotel.location.country;
      let hotelImg = hotel.thumb;

      let hotelImgs = "";
      let hotelImgs1 = [];

      //Multiple Images

      // if (
      //   hotel.details !== undefined &&
      //   hotel.details !== "" &&
      //   hotel.details !== null
      // ) {
      //   if (hotel.details.length !== 0) {
      //     hotelImgs = hotel.details.imgs.map((item, i) => {
      //       hotelImgs1.push(item.url);
      //     });
      //   } else {
      //     hotelImgs1.push(
      //       process.env.PUBLIC_URL + "/assets/images/noimage.jpg"
      //     );
      //   }
      // } else {
      //   if (hotel.thumb !== null) {
      //     hotelImgs1.push(hotel.thumb);
      //   } else {
      //     hotelImgs1.push(
      //       process.env.PUBLIC_URL + "/assets/images/noimage.jpg"
      //     );
      //   }
      // }

      let final_rating = hotel.stars;
      let floor_rating = Math.floor(hotel.stars);
      let ceil_rating = Math.ceil(hotel.stars);
      let half_rating = final_rating - floor_rating;
      let not_sel_star = 5 - ceil_rating;
      let facilities = hotel.facilities;

      if (facilities != null) {
        //differentiate facilities display and more list
        const renderList = facilities.map((item) => {
          let modiname = item
            .replace(/[^a-zA-Z ]/g, "")
            .replace(/ /g, "")
            .toLowerCase(); //remove space and special charectes
          if (commanConst.DISPLAYFACILITIES.indexOf(modiname) == -1) {
            morefacil.push(item); // More facilities
          } else {
            disfacil.push(item); //Display facilities
          }
        });
      }

      //remove duplicate value from array
      let disfacilits = [];
      disfacilits = disfacil.filter(function (item, pos, self) {
        return self.indexOf(item) == pos;
      });

      //remove duplicate value from array
      let morefacilits = [];
      morefacilits = morefacil.filter(function (item, pos, self) {
        return self.indexOf(item) == pos;
      });

      //Display facilities list
      let dispfacilitis = "";
      if (disfacilits.length != 0) {
        dispfacilitis = disfacilits.map((item, i) => {
          let modiname = item
            .replace(/[^a-zA-Z ]/g, "")
            .replace(/ /g, "")
            .toLowerCase(); //remove space and special charectes
          let disfacility =
            commanConst.DISPLAYFACILITIESICON.facility[modiname];
          return (
            <div key={i + "falty"} className="col-md-2">
              <div className="facility-slide">
                <i className={disfacility} aria-hidden="true"></i>
                <span>{item}</span>
              </div>
            </div>
          );
        });
      }

      //More facilities list
      let morefacilitis = "";
      if (morefacilits.length != 0) {
        morefacilitis = morefacilits.map((item, i) => {
          return (
            <div key={i + "mrfacty"} className="col-md-6">
              <div className="facility-more">
                <i className="fa fa-check" aria-hidden="true"></i>
                <span>{item}</span>
              </div>
            </div>
          );
        });
      }

      //Display start rating hotel wise
      const starRaring = () => {
        let startitem = [];
        {
          for (let k = 0; k < floor_rating; k++) {
            startitem.push(
              <React.Fragment key={k + "fillsr"}>
                <label
                  aria-label="0.5 stars"
                  className="rating__label rating__label--half"
                  htmlFor="rating2-05"
                >
                  <i
                    className="rating__icon rating__icon--star fa fa-star-half"
                    aria-hidden="true"
                  ></i>
                </label>
                <input
                  className="rating__input"
                  name="rating2"
                  id="rating2-05"
                  type="radio"
                />
                <label
                  aria-label="1 star"
                  className="rating__label"
                  htmlFor="rating2-10"
                >
                  <i
                    className="rating__icon rating__icon--star fa fa-star"
                    aria-hidden="true"
                  ></i>
                </label>
                <input
                  className="rating__input"
                  name="rating2"
                  id="rating2-10"
                  type="radio"
                />
              </React.Fragment>
            );
          }
          if (half_rating != 0) {
            startitem.push(
              <React.Fragment key={"hlfrat"}>
                <label
                  aria-label="0.5 stars"
                  className="rating__label rating__label--half"
                  htmlFor="rating2-05"
                >
                  <i
                    className="rating__icon rating__icon--star fa fa-star-half"
                    aria-hidden="true"
                  ></i>
                </label>
                <input
                  className="rating__input"
                  name="rating2"
                  id="rating2-05"
                  type="radio"
                />
                <label
                  aria-label="1 star"
                  className="rating__label"
                  htmlFor="rating2-10"
                >
                  <i
                    className="rating__icon rating__icon--star fa fa-star"
                    aria-hidden="true"
                    style={startnotsel}
                  ></i>
                </label>
                <input
                  className="rating__input"
                  name="rating2"
                  id="rating2-10"
                  type="radio"
                />
              </React.Fragment>
            );
          }

          if (not_sel_star != 0) {
            for (let k = 0; k < not_sel_star; k++) {
              startitem.push(
                <React.Fragment key={k + "ntselsr"}>
                  <label
                    aria-label="0.5 stars"
                    className="rating__label rating__label--half"
                    htmlFor="rating2-05"
                  >
                    <i
                      className="rating__icon rating__icon--star fa fa-star-half"
                      aria-hidden="true"
                      style={startnotsel}
                    ></i>
                  </label>
                  <input
                    className="rating__input"
                    name="rating2"
                    id="rating2-05"
                    type="radio"
                  />
                  <label
                    aria-label="1 star"
                    className="rating__label"
                    htmlFor="rating2-10"
                  >
                    <i
                      className="rating__icon rating__icon--star fa fa-star"
                      aria-hidden="true"
                      style={startnotsel}
                    ></i>
                  </label>
                  <input
                    className="rating__input"
                    name="rating2"
                    id="rating2-10"
                    type="radio"
                  />
                </React.Fragment>
              );
            }
          }
        }
        return startitem;
      };

      //star rating not selected
      const startnotsel = {
        color: "#ddd",
      };

      //Display Default Image
      const addDefaultSrc = (ev) => {
        ev.target.src = process.env.PUBLIC_URL + "/assets/images/noimage.jpg";
      };

      // return (
      //   <section className="holiday">
      //     <div class="tripdetails">
      //       <h2>Your Selected Hotel</h2>
      //     </div>
      //     <div className="d-flex flex-wrap hotel-details align-items-center bg-white">
      //       <div className="col-md-8 d-flex flex-wrap hotel-details-list align-items-center p-0">
      //         <div className="hotel-desc p-2">
      //           <h4>{hotelName}</h4>
      //           <div className="rating m-0 d-flex align-items-center flex-wrap">
      //             <div id="half-stars-example">
      //               <div className="rating-group">
      //                 <input
      //                   className="rating__input rating__input--none"
      //                   name="rating2"
      //                   id="rating2-0"
      //                   defaultValue="0"
      //                   type="radio"
      //                 />
      //                 <label
      //                   aria-label="0 stars"
      //                   className="rating__label"
      //                   htmlFor="rating2-0"
      //                 >
      //                   &nbsp;
      //                 </label>
      //                 {starRaring()}
      //               </div>
      //             </div>
      //             <span className="rating-points">{final_rating}</span>
      //           </div>
      //           <ul className="p-0 m-0" style={{ listStyle: "none" }}>
      //             <li>
      //               <i className="fas fa-map-marker-alt"></i>
      //               {hotelAddress}
      //             </li>
      //             <li>
      //               <i className="fas fa-user" aria-hidden="true"></i>
      //               Guests : {tolGuest}
      //             </li>
      //           </ul>
      //         </div>
      //       </div>
      //       <div className="col-md-4 p-0" id="booking-details">
      //         <div className="journey-up-down-fl">
      //           {/* <h4>Booking details</h4> */}
      //           <ul>
      //             <li>
      //               <img
      //                 src={
      //                   process.env.PUBLIC_URL + "/assets/images/check-in.png"
      //                 }
      //               />
      //               <p>
      //                 Check in<span>{disdepadate}</span>
      //               </p>
      //             </li>
      //             <li>
      //               <img
      //                 src={
      //                   process.env.PUBLIC_URL +
      //                   "/assets/images/check-close.png"
      //                 }
      //               />
      //               <p>
      //                 Check out<span>{disreturndate}</span>
      //               </p>
      //             </li>
      //           </ul>
      //         </div>
      //       </div>
      //     </div>

      //     <div className="left-right-layout">
      //       <div className="row">
      //         <div className="col-md-12 room-acc">
      //           <div className="popup-hotel">
      //             <div className="p-2 bg-white">
      //               <div className="col-md-12 hotel-gallery p-0">
      //                 <div
      //                   id="carouselExampleIndicators"
      //                   className="carousel slide"
      //                   data-ride="carousel"
      //                 >
      //                   <div className="carousel-inner">
      //                     {/*Image Slider*/}
      //                     <div className="carousel-item active">
      //                       <img
      //                         src={hotelImgs1[slidimg]}
      //                         onError={addDefaultSrc}
      //                         className="slidimgitm"
      //                       />
      //                     </div>
      //                   </div>
      //                   {hotelImgs1.length > 1 ? (
      //                     <span>
      //                       <a
      //                         className="carousel-control-prev"
      //                         onClick={() => {
      //                           prevImage();
      //                         }}
      //                         data-slide="prev"
      //                       >
      //                         <span
      //                           className="carousel-control-prev-icon"
      //                           aria-hidden="true"
      //                         ></span>
      //                         <span className="sr-only">Previous</span>
      //                       </a>
      //                       <a
      //                         className="carousel-control-next"
      //                         onClick={() => {
      //                           nextImage();
      //                         }}
      //                         data-slide="next"
      //                       >
      //                         <span
      //                           className="carousel-control-next-icon"
      //                           aria-hidden="true"
      //                         ></span>
      //                         <span className="sr-only">Next</span>
      //                       </a>
      //                     </span>
      //                   ) : (
      //                     ""
      //                   )}
      //                 </div>
      //               </div>

      //               <div className="col-md-12 p-0">
      //                 <p>{hotelDesc}</p>
      //               </div>

      //               <div className="facilities-list">
      //                 <h5>Facilities</h5>
      //                 <div className="row">
      //                   {/* Display Facilities */}
      //                   {dispfacilitis}
      //                 </div>

      //                 <div className="morefacility">
      //                   <div className="row">
      //                     {/* More Facilities */}
      //                     {morefacilitis}
      //                   </div>
      //                 </div>
      //               </div>
      //             </div>
      //           </div>
      //         </div>
      //       </div>
      //     </div>
      //   </section>
      // );

      return (
        <div key={i} className="hotel-list-section holiday city-display">
          <div className="tripdetails pb-2">
            <h2>Your Selected Hotel</h2>
          </div>
          <div className="hotel-list bg-white mb-2">
            <div className="d-flex flex-wrap">
              <div className="col-md-4 position-relative hotel-left">
                <div className="hotel-image">
                  <img
                    src={hotelImg}
                    onError={addDefaultSrc}
                    className="img-fluid h-100"
                  />
                </div>
              </div>
              <div className="col-md-8 hotel-right">
                <div className="d-flex flex-wrap hotel-details">
                  <div className="col-md-7 p-0">
                    <div className="d-flex align-items-center flex-wrap">
                      <div className="rating">
                        <div id="half-stars-example">
                          <div className="rating-group">
                            <input
                              className="rating__input rating__input--none"
                              name="rating2"
                              id="rating2-0"
                              defaultValue="0"
                              type="radio"
                            />
                            <label
                              aria-label="0 stars"
                              className="rating__label"
                              htmlFor="rating2-0"
                            >
                              &nbsp;
                            </label>

                            {/* Rating Start */}
                            {final_rating !== 0 ? starRaring() : ""}
                          </div>
                        </div>
                      </div>
                      {/* Rating In Number  */}
                      {final_rating !== 0 ? (
                        <span className="score">{final_rating}</span>
                      ) : (
                        ""
                      )}
                    </div>

                    <h5>{hotelName}</h5>
                    <p className="location">
                      <i className="fas fa-map-marker-alt"></i>
                      {hotelAddress}
                    </p>
                  </div>
                </div>

                <div className="hotel-services destFacility p-0 m-0 border-0">
                  <ul className="p-0 m-0 d-flex flex-wrap">
                    {/* Display Facilities */}
                    {dispfacilitis}

                    {/* Desktop Device More */}
                    <li className="dropdown more destmore">
                      {morefacilitis.length != 0 ? (
                        <React.Fragment>
                          {/* <button
                            className="dropdown-toggle"
                            type="button"
                            id="dropdownMenuButton1"
                            data-toggle="dropdown"
                            aria-expanded="false"
                          >
                            more+
                          </button> */}
                          <ul
                            className="dropdown-menu morefacilities"
                            aria-labelledby="dropdownMenuButton1"
                            x-placement="bottom-start facilitieslist"
                          >
                            
                            {morefacilitis}
                          </ul>
                        </React.Fragment>
                      ) : (
                        ""
                      )}
                    </li>
                  </ul>

                  {/* Mobile Device More */}
                  {morefacilitis.length != 0 ? (
                    <React.Fragment>
                      <div className="dropdown more morefacilitytrig">
                        <button
                          className="dropdown-toggle"
                          type="button"
                          id="dropdownMenuButton1"
                          data-toggle="dropdown"
                          aria-expanded="false"
                        >
                          more+
                        </button>
                        <ul
                          className="dropdown-menu morefacilities"
                          aria-labelledby="dropdownMenuButton1"
                          x-placement="bottom-start facilitieslist"
                        >
                          {/* More Facilities */}
                          {morefacilitis}
                        </ul>
                      </div>
                    </React.Fragment>
                  ) : (
                    ""
                  )}
                </div>

                {/* <div className="d-flex flex-wrap justify-content-between align-items-end">
                            <div className="flight-dest">
                              From
                              <span className="flight-price">
                                {package_price}
                              </span>
                              <p>
                                Total package price {totaldays} days /
                                {totalnight} nights’s flight and taxes included
                              </p>
                            </div>
                            <div className="check-price">
                              <a href={`${detailUrl}/${c_key}/${hid}`}>
                                SELECT THIS HOTEL
                              </a>
                            </div>
                          </div> */}
              </div>
            </div>
          </div>
        </div>
      );
    });
  }

  //Flight listing data
  let flightList = useSelector((state) => {
    if (state.allFlights.flights.length !== 0) {
      return state.allFlights.flights.flights;
    }
    return null;
  });

  if (flightList !== null) {
    totalFlights = Object.keys(flightList).length; //Total Flights

    //sorting data in assending order by total price
    flightList = flightList.sort(function (a, b) {
      return parseFloat(a.TOTAL_PRICE) - parseFloat(b.TOTAL_PRICE);
    });

    //Cheapest and highest total price
    cheapestPrice = flightList[0].TOTAL_PRICE;
    let highestPrice = flightList[totalFlights - 1].TOTAL_PRICE;
    modifyHighestPr = highestPrice - cheapestPrice;

    //Modify price, Reduce Total Price with cheapest price
    let flightListPriceChange = flightList.filter(
      (item) => (item.TOTAL_PRICE = item.TOTAL_PRICE - cheapestPrice)
    );

    //sorting data in descending order by total price
    if (flightsOrdering === "DESC") {
      flightList = flightList.sort(function (a, b) {
        return parseFloat(b.TOTAL_PRICE) - parseFloat(a.TOTAL_PRICE);
      });
    }

    //For airport and airlins list in left filter
    flightData = flightList.map((flight, i) => {
      let isadd = false;
      let isaddarl = false;
      let isaddcomb = false;
      return flight.FLI_INF.map((item, j) => {
        return item.map((itm, k) => {
          let totalflt = itm.ARL_COD.length;

          let startdate = new Date(itm.DEP_DAT_TIM[0]);
          let enddate = new Date(itm.ARR_DAT_TIM[totalflt - 1]);
          let durtimems = TimeToms(startdate, enddate);
          //For Flight duration
          if (j === 0) {
            //Departure flights
            flightDurOutArr.push(durtimems);
          } else if (j === 1) {
            //Return flights
            flightDurRetArr.push(durtimems);
          }

          for (let k = 0; k < totalflt; k++) {
            let arpCode = itm.DEP_ARP[k];
            let arpName = itm.ARR_TWN[k];

            airportsArr.push({
              arpCode: arpCode,
              arpName: arpName,
            });
            //Air Lines
            let arlCode = itm.ARL_COD[k];
            airlinesArr[arlCode] = itm.ARL_NAM[k];

            //Array for manage checkbox
            airlinesFilterChboxArr[arlCode] = true;
            airPortsFilterChboxArr[arpCode] = true;

            if (
              airPortFilt.length !== 0 &&
              airLinsFilt.length !== 0 &&
              isaddcomb === false
            ) {
              if (
                airPortFilt.indexOf(arpCode) !== -1 &&
                airLinsFilt.indexOf(arlCode) != -1
              ) {
                combineFilterArr.push(flight);
                isaddcomb = true;
              }
            } else {
              if (airPortFilt.length !== 0) {
                if (airPortFilt.indexOf(arpCode) != -1 && isadd == false) {
                  airportFilterArr.push(flight);
                  isadd = true;
                }
              }

              if (airLinsFilt.length !== 0) {
                if (airLinsFilt.indexOf(arlCode) !== -1 && isaddarl === false) {
                  airlinesFilterArr.push(flight);
                  isaddarl = true;
                }
              }
            }
          }
        });
      });
    });

    if (combineFilterArr.length !== 0) {
      //Airport And AirLines Combine Filter Data
      flightList = combineFilterArr;
    } else {
      //Airport Filter Data
      if (airportFilterArr.length !== 0) {
        flightList = airportFilterArr;
      }

      //AirLines Filter Data
      if (airlinesFilterArr.length !== 0) {
        flightList = airlinesFilterArr;
      }
    }

    //For stops filter
    if (stopFilter.length !== 0 && flightList !== null) {
      flightData = flightList.map((flight, i) => {
        let isaddstp = false;
        return flight.FLI_INF.map((item, j) => {
          return item.map((itm, k) => {
            let totalstops = itm.ARL_COD.length - 1;
            let stoptype = "";
            if (totalstops == 0) {
              stoptype = "zero";
            } else if (totalstops == 1) {
              stoptype = "one";
            } else {
              stoptype = "twomore";
            }

            //For stops filter
            if (stopFilter.indexOf(stoptype) != -1 && isaddstp == false) {
              stopsFilterArr.push(flight);
              isaddstp = true;
            }
          });
        });
      });

      //stops filter data
      if (stopsFilterArr !== 0) {
        flightList = stopsFilterArr;
      }
    }

    //For price filter
    if (maxPriceLimit !== "0.00" && flightList !== undefined) {
      flightData = flightList.map((flight, i) => {
        // let modifyPrice = flight.TOTAL_PRICE - flightList[0].TOTAL_PRICE;;
        if (
          parseFloat(flight.TOTAL_PRICE).toFixed(2) >= parseFloat(minPrice) &&
          parseFloat(flight.TOTAL_PRICE).toFixed(2) <= parseFloat(maxPrice)
        ) {
          priceFilterArr.push(flight);
        }
      });

      if (priceFilterArr.length !== 0) {
        flightList = priceFilterArr;
      }
    }

    //For Departure Outbound filter
    if ((depMaxTime !== 24 || depMinTime !== 0) && flightList !== undefined) {
      flightData = flightList.map((flight, i) => {
        let isaddouttm = false;

        return flight.FLI_INF.map((item, j) => {
          if (j == 0) {
            //Only consider Departure  time
            return item.map((itm, k) => {
              //Only consider main departure time not stops time
              let hour = parseInt(itm.DEP_TIM[0].substring(0, 2));

              //For Departure Outbound
              if (
                hour >= parseInt(depMinTime) &&
                hour <= parseInt(depMaxTime) &&
                isaddouttm == false
              ) {
                departureOutFilterArr.push(flight);
                isaddouttm = true;
              }
            });
          }
        });
      });
      flightList = departureOutFilterArr;
    }

    //For Departure Return filter
    if ((retMaxTime !== 24 || retMinTime !== 0) && flightList !== undefined) {
      flightData = flightList.map((flight, i) => {
        let isaddRettm = false;
        return flight.FLI_INF.map((item, j) => {
          if (j == 1) {
            //Only consider Departure  time
            return item.map((itm, k) => {
              //Only consider main departure time not stops time
              let hour = parseInt(itm.DEP_TIM[0].substring(0, 2));

              //For Departure return
              if (
                hour >= parseInt(retMinTime) &&
                hour <= parseInt(retMaxTime) &&
                isaddRettm == false
              ) {
                departureRetFilterArr.push(flight);
                isaddRettm = true;
              }
            });
          }
        });
      });

      flightList = departureRetFilterArr;
    }

    //For Flight Duration Outbound filter
    if (
      depFlightDur != "-Infinity" &&
      depFlightDur !== 0 &&
      flightList !== undefined
    ) {
      flightData = flightList.map((flight, i) => {
        let isadd = false;
        return flight.FLI_INF.map((item, j) => {
          if (j == 0) {
            //Only consider out Departure  time
            return item.map((itm, k) => {
              let totalflt = itm.ARL_COD.length;
              let startdate = new Date(itm.DEP_DAT_TIM[0]);
              let enddate = new Date(itm.ARR_DAT_TIM[totalflt - 1]);
              let durtimems = parseInt(TimeToms(startdate, enddate));

              //For Flight duration
              if (
                durtimems >= parseInt(depMinFlightDur) &&
                durtimems <= parseInt(depFlightDur) &&
                isadd == false
              ) {
                //Departure flights
                flightDurOutDataArr.push(flight);
                isadd = true;
              }
            });
          }
        });
      });

      flightList = flightDurOutDataArr;
    }

    //For Flight Duration Return filter
    if (
      depFlightDur != "-Infinity" &&
      depFlightDur !== 0 &&
      flightList !== undefined
    ) {
      flightData = flightList.map((flight, i) => {
        let isadd = false;
        return flight.FLI_INF.map((item, j) => {
          if (j == 1) {
            //Only consider return Departure time
            return item.map((itm, k) => {
              let totalflt = itm.ARL_COD.length;
              let startdate = new Date(itm.DEP_DAT_TIM[0]);
              let enddate = new Date(itm.ARR_DAT_TIM[totalflt - 1]);
              let durtimems = parseInt(TimeToms(startdate, enddate));

              //For Flight duration
              if (
                durtimems >= parseInt(retMinFlightDur) &&
                durtimems <= parseInt(retFlightDur) &&
                isadd == false
              ) {
                //Departure flights
                flightDurRetDataArr.push(flight);
                isadd = true;
              }
            });
          }
        });
      });

      flightList = flightDurRetDataArr;
    }

    if (flightList !== "") {
      totalFlights = Object.keys(flightList).length; //Total Flights After filter

      //Get current posts
      const indexOfLastHotel = currentPage * itemPerPage;
      const indexOfFirstHotel = indexOfLastHotel - itemPerPage;
      currentItems = flightList.slice(indexOfFirstHotel, indexOfLastHotel);
    }

    //Remove duplicat array
    let airportsArrUniq = [
      ...new Set(airportsArr.map(({ arpCode }) => arpCode)),
    ].map((e) => airportsArr.find(({ arpCode }) => arpCode == e));

    //Show Airports
    currentAirport = airportsArrUniq.slice(0, showNoOfAirport);

    //Total pages
    ttpage = Math.ceil(flightList.length / itemPerPage);

    if (ttpage < endPage) {
      setEndPage(ttpage);
    }
  }

  const msToTime = (duration) => {
    let minutes = Math.floor((duration / (1000 * 60)) % 60),
      hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

    hours = hours < 10 ? "0" + hours : hours;
    minutes = minutes < 10 ? "0" + minutes : minutes;

    return hours + "h " + minutes + "m";
  };

  //Modify price
  const modifyPriceComm = () => {
    setMaxPrice(parseFloat(modifyHighestPr).toFixed(2));
    setMaxPriceLimit(parseFloat(modifyHighestPr).toFixed(2));
    setDepMinFlightDur(Math.min(...flightDurOutArr));
    setDepMaxFlightDur(Math.max(...flightDurOutArr));
    setDepFlightDur(Math.max(...flightDurOutArr));
    setRetMinFlightDur(Math.min(...flightDurRetArr));
    setRetMaxFlightDur(Math.max(...flightDurRetArr));
    setRetFlightDur(Math.max(...flightDurRetArr));
  };

  //Set modify Highest Price
  const modifyHighestPrice = () => {
    ttpage = Math.ceil(flightDurOutArr.length / itemPerPage);
    setEndPage(ttpage);

    modifyPriceComm();

    if (flightDurOutArr.length === 0) {
      let flightList1 = JSON.parse(
        JSON.parse(localStorage.getItem("persist:root")).allFlights
      ).flights.flights;

      if (flightList1 !== null) {
        totalFlights = Object.keys(flightList1).length; //Total Flights

        //sorting data in assending order by total price
        flightList1 = flightList1.sort(function (a, b) {
          return parseFloat(a.TOTAL_PRICE) - parseFloat(b.TOTAL_PRICE);
        });

        //Cheapest and highest total price
        cheapestPrice = flightList1[0].TOTAL_PRICE;
        let highestPrice = flightList1[totalFlights - 1].TOTAL_PRICE;
        modifyHighestPr = highestPrice - cheapestPrice;

        //For airport and airlins list in left filter
        let flightData1 = flightList1.map((flight, i) => {
          return flight.FLI_INF.map((item, j) => {
            return item.map((itm, k) => {
              let totalflt = itm.ARL_COD.length;

              let startdate = new Date(itm.DEP_DAT_TIM[0]);
              let enddate = new Date(itm.ARR_DAT_TIM[totalflt - 1]);
              let durtimems = TimeToms(startdate, enddate);
              //For Flight duration
              if (j === 0) {
                //Departure flights
                flightDurOutArr.push(durtimems);
              } else if (j === 1) {
                //Return flights
                flightDurRetArr.push(durtimems);
              }

              for (let k = 0; k < totalflt; k++) {
                let arpCode = itm.DEP_ARP[k];

                //Air Lines
                let arlCode = itm.ARL_COD[k];

                //Array for manage checkbox
                airlinesFilterChboxArr[arlCode] = true;
                airPortsFilterChboxArr[arpCode] = true;
              }
            });
          });
        });

        modifyPriceComm();
        selAllAirlines(true);
        selAllAirPorts(true);
        setCurrentPage(1);
        setStartPage(1);
        setEndPage(disNumOfPage);
      }
    }
  };

  //Show more airport
  const moreAirport = () => {
    setShowNoOfAirport(showNoOfAirport + 2);
  };

  //Previous Pages
  const prevPagies = () => {
    //For manage last pages group
    let updtendpg = endPage;
    if (endPage - startPage != disNumOfPage - 1) {
      updtendpg = endPage + (disNumOfPage - 1 - (endPage - startPage));
    }

    ustartPage = startPage - disNumOfPage;
    uendPage = updtendpg - disNumOfPage;
    if (ustartPage >= 1) {
      setStartPage(ustartPage);
      setEndPage(uendPage);
    }
  };

  //Next Pages
  const nextPagies = () => {
    if (ttpage - endPage >= 1) {
      ustartPage = startPage + disNumOfPage;
      uendPage = endPage + disNumOfPage;

      setStartPage(ustartPage);
      setEndPage(uendPage);
    }
  };

  //Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  //Previous hotel list Page
  const prevPaginate = () => {
    //previous page with pages number
    if (currentPage == startPage && currentPage != 1) {
      let prevpage = currentPage - 1;
      setCurrentPage(prevpage);
      prevPagies();
    }

    if (currentPage > 1) {
      let prevpage = currentPage - 1;
      setCurrentPage(prevpage);
    }
  };

  //Next hotel list page
  const nextPaginate = (lastPage) => {
    //Next page with pages number
    if (currentPage == lastPage && currentPage != ttpage) {
      let nextpage = currentPage + 1;
      setCurrentPage(nextpage);
      nextPagies();
    }

    if (currentPage < lastPage) {
      let nextpage = currentPage + 1;
      setCurrentPage(nextpage);
    }
  };

  //Filter Data By AirPorts
  const filterByAirPort = (e) => {
    let airport = e.target.value;
    if (e.target.checked) {
      //Add value
      const updateFilt = airPortFilt.concat(airport);
      setAirPortFilt(updateFilt);
      setAllAirportsChbox((prev) => ({ ...prev, [airport]: true }));
    } else {
      //Remove value
      let updateFilt = airPortFilt.filter((item) => item !== e.target.value);
      setAirPortFilt(updateFilt);
      setAllAirportsChbox((prev) => ({ ...prev, [airport]: false }));
    }
    setCurrentPage(1);
    setStartPage(1);
    setEndPage(disNumOfPage);
  };

  //Filter Data By AirLines
  const filterByAirLines = (e) => {
    if (e.target.checked) {
      //Add value
      const updateFilt = airLinsFilt.concat(e.target.value);
      setAirLinsFilt(updateFilt);
      setAllAirlinesChbox((prev) => ({ ...prev, [e.target.value]: true }));
    } else {
      //Remove value
      let updateFilt = airLinsFilt.filter((item) => item !== e.target.value);
      setAirLinsFilt(updateFilt);
      setAllAirlinesChbox((prev) => ({ ...prev, [e.target.value]: false }));
    }
    setCurrentPage(1);
    setStartPage(1);
    setEndPage(disNumOfPage);
  };

  //Default select all airport filter checkbox
  const selAllAirPorts = (e) => {
    if (e == true) {
      let addAllports = [];
      Object.keys(airPortsFilterChboxArr).map((item) => {
        setAllAirportsChbox((prev) => ({ ...prev, [item]: true }));
        addAllports.push(item);
      });
      setAirPortFilt(addAllports);
    }
  };

  //Default select all aiplines filter checkbox
  const selAllAirlines = (e) => {
    if (e === true) {
      let addAllLins = [];
      Object.keys(airlinesFilterChboxArr).map((item) => {
        setAllAirlinesChbox((prev) => ({ ...prev, [item]: true }));
        addAllLins.push(item);
      });
      setAirLinsFilt(addAllLins);
    } else {
      if (e.target.checked) {
        let addAllLins = [];
        Object.keys(airlinesFilterChboxArr).map((item) => {
          setAllAirlinesChbox((prev) => ({ ...prev, [item]: true }));

          addAllLins.push(item);
        });
        setAirLinsFilt(addAllLins);
      } else {
        Object.keys(airlinesFilterChboxArr).map((item) => {
          setAllAirlinesChbox((prev) => ({ ...prev, [item]: false }));

          let updateFilt = airLinsFilt.filter((item) => item !== item);
          setAirLinsFilt(updateFilt);
        });
      }
    }
    setCurrentPage(1);
    setStartPage(1);
    setEndPage(disNumOfPage);
  };

  //Filter By stops
  const selStops = (e) => {
    let stopval = e.target.value;

    if (e.target.checked) {
      //Add value
      const updateFilt = stopFilter.concat(stopval);
      setStopFilter(updateFilt);
    } else {
      //Remove value
      let updateFilt = stopFilter.filter((item) => item !== e.target.value);
      setStopFilter(updateFilt);
    }
    setCurrentPage(1);
    setStartPage(1);
    setEndPage(disNumOfPage);
  };

  const changePrice = (val) => {
    let upMinPrice = val[0];
    let upMaxPrice = val[1];
    setMinPrice(upMinPrice);

    let pointval = parseFloat(modifyHighestPr).toFixed(2).toString().split(".");

    if (upMaxPrice == 0) {
      setMaxPrice(upMaxPrice);
    } else {
      if (pointval[1] != undefined) {
        // upMaxPrice = upMaxPrice + '.' + pointval[1];
        upMaxPrice = parseFloat(upMaxPrice + "." + pointval[1]);
      }
      setMaxPrice(upMaxPrice);
    }
    setCurrentPage(1);
    setStartPage(1);
    setEndPage(disNumOfPage);
  };

  //Price Filter
  const disPriceFilter = (i) => {
    return (
      <React.Fragment key={"Prce" + i}>
        <Range
          min={0}
          max={maxPriceLimit}
          value={[minPrice, maxPrice]}
          allowCross={false}
          count={1}
          onChange={(value) => changePrice(value)}
          onAfterChange={(value) => changePrice(value)}
          className="PricFilt"
        />
      </React.Fragment>
    );
  };

  //Change Departure Range Time
  const changeDepartureRangeTime = (val, type) => {
    let upMinTime = val[0];
    let upMaxTime = val[1];

    if (type === "out") {
      //Departure
      setDepMinTime(upMinTime);
      setDepMaxTime(upMaxTime);
    } else {
      //Return
      setRetMinTime(upMinTime);
      setRetMaxTime(upMaxTime);
    }

    setCurrentPage(1);
    setStartPage(1);
    setEndPage(disNumOfPage);
  };

  //Departure Range Time
  const departureRangeTime = (type) => {
    let upMinTime = "";
    let upMaxTime = "";

    if (type === "out") {
      //Departure
      upMinTime = depMinTime;
      upMaxTime = depMaxTime;
    } else {
      //Return
      upMinTime = retMinTime;
      upMaxTime = retMaxTime;
    }

    return (
      <Range
        min={0}
        max={24}
        step={3}
        dots={true}
        value={[upMinTime, upMaxTime]}
        allowCross={false}
        onChange={(value) => changeDepartureRangeTime(value, type)}
        onAfterChange={(value) => changeDepartureRangeTime(value, type)}
        className="DepRangTm"
      />
    );
  };

  //Change flight duration time
  const changeFlightDurationTime = (val, type) => {
    if (type === "out") {
      //Departure
      setDepFlightDur(val);
    } else {
      //Return
      setRetFlightDur(val);
    }

    setCurrentPage(1);
    setStartPage(1);
    setEndPage(disNumOfPage);
  };

  //Flight duration time
  const flightDurationTime = (type) => {
    let upMinTime = "";
    let upMaxTime = "";
    let upTime = "";

    if (type === "out") {
      //Departure
      upMinTime = depMinFlightDur;
      upMaxTime = depMaxFlightDur;
      upTime = depFlightDur;
    } else {
      //Return
      upMinTime = retMinFlightDur;
      upMaxTime = retMaxFlightDur;
      upTime = retFlightDur;
    }

    return (
      <Slider
        min={upMinTime}
        max={upMaxTime}
        value={upTime}
        onChange={(value) => {
          changeFlightDurationTime(value, type);
        }}
        onAfterChange={(value) => {
          changeFlightDurationTime(value, type);
        }}
        className="FliTDurTm"
      />
    );
  };

  // Stops Filter
  const disStopsFilter = (i) => {
    return (
      <React.Fragment key={"stop" + i}>
        <label className="control control-checkbox full-width">
          Non-stop flights
          <input
            type="checkbox"
            value="zero"
            onClick={(e) => {
              selStops(e);
            }}
          />
          <div className="control_indicator"></div>
        </label>
        <label className="control control-checkbox full-width">
          Flights with one stop
          <input
            type="checkbox"
            value="one"
            onClick={(e) => {
              selStops(e);
            }}
          />
          <div className="control_indicator"></div>
        </label>
        <label className="control control-checkbox full-width">
          Flights with two or more stops
          <input
            type="checkbox"
            value="twomore"
            onClick={(e) => {
              selStops(e);
            }}
          />
          <div className="control_indicator"></div>
        </label>
      </React.Fragment>
    );
  };

  //Filter by ordering
  const ordering = (e) => {
    setFlightsOrdering(e);
    setCurrentPage(1);
    setStartPage(1);
    setEndPage(disNumOfPage);
  };

  //Reset all filter
  const resetFilter = () => {
    window.location.reload();
  };

  const loader = {
    display: hotelsLoader,
  };

  //Manage Filter Hide Show
  const manageFilter = () => {
    if (filterForm === "") {
      setFilterForm("show");
    } else {
      setFilterForm("");
    }
  };

  //Manage Mobile Side bare Hide Show
  const manageSideFilter = () => {
    if (sideFilter === "none") {
      setSideFilter("block");
    } else {
      setSideFilter("none");
    }
  };

  return (
    <React.Fragment>
      <div className="flights page-3">
        <Header showMenu={showMenu} tabTitle="Wannafly | Flights" />
        <main>
          <div className="loader-parent" style={loader}>
            <img
              src={process.env.PUBLIC_URL + "/assets/images/loading.gif"}
              className="loaderset"
            />
          </div>
          <div className="location-section full-list-advisor">
            <div className="container-fluid p-0">
              <div className="row m-0">
                <div className="col-lg-3 filter-section p-0">
                  <div className="modify-search">
                    <a onClick={manageFilter}>
                      <button className="btn btn-primary">
                        <i className="fas fa-edit"></i>Modify search
                      </button>
                    </a>
                  </div>

                  {/* Modify Search */}
                  {UserInfo.device_type === "computer" ? (
                    <FlightListModifySearch filterForm={filterForm} />
                  ) : (
                    ""
                  )}

                  <div className="filters">
                    <h5>Filter</h5>
                    <div className="filters-wrap">
                      <div className="filter-wrapper">
                        <h3>Stops</h3>
                        <div className="row">
                          <div className="col">
                            <div className="card card-body">
                              <div className="control-group">
                                {/* Stops Filter */}
                                {disStopsFilter(1)}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="filter-wrapper">
                        <h3>Price</h3>
                        <div className="row">
                          <div className="col">
                            {/* Price Filter */}
                            <p className="pricefilt">
                              US${minPrice} - US${maxPrice}
                            </p>
                            {disPriceFilter(1)}
                          </div>
                        </div>
                      </div>

                      <div className="filter-wrapper">
                        <h3>Departure Time</h3>
                        <div className="row">
                          <div className="col">
                            <div className="card card-body">
                              {/* Departure Time Out Filter */}
                              <label className="amount">Outbound</label>
                              <p className="amount">
                                {depMinTime}:00 - {depMaxTime}:00
                              </p>
                              {departureRangeTime("out")}
                            </div>

                            <div className="card card-body">
                              {/* Departure Time Return Filter */}
                              <label className="amount">Return</label>
                              <p className="amount">
                                {retMinTime}:00 - {retMaxTime}:00
                              </p>
                              {departureRangeTime("in")}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="filter-wrapper">
                        <h3>Total flights Duration</h3>
                        <div className="row">
                          <div className="col">
                            <div className="card card-body">
                              {/* Flights Duration Out Filter */}
                              <label className="amount">Outbound</label>
                              <p className="amount">
                                {msToTime(depMinFlightDur)} -{" "}
                                {msToTime(depFlightDur)}
                              </p>
                              {flightDurationTime("out")}
                            </div>
                            <div className="card card-body">
                              {/* Flights Duration Return Filter */}
                              <label className="amount">Return</label>
                              <p className="amount">
                                {msToTime(retMinFlightDur)} -{" "}
                                {msToTime(retFlightDur)}
                              </p>
                              {flightDurationTime("in")}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="filter-wrapper">
                        <h3>Layout Airport</h3>
                        <div className="row">
                          <div className="col">
                            <div className="card card-body">
                              <div className="control-group more-options">
                                {/* Airport Filter */}
                                {currentAirport != undefined &&
                                currentAirport != "" ? (
                                  <FlightAirPorts
                                    allAirportsChbox={allAirportsChbox}
                                    airportsArr={currentAirport}
                                    filterByAirPort={filterByAirPort}
                                  />
                                ) : (
                                  ""
                                )}
                              </div>
                              <div className="view-more text-right">
                                <a
                                  className="loadmore"
                                  id="loadMoreAirport"
                                  onClick={() => {
                                    moreAirport();
                                  }}
                                >
                                  <i
                                    className="far fa-plus-square"
                                    aria-hidden="true"
                                  ></i>
                                  more
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="filter-wrapper">
                        <h3>All Airlines</h3>
                        <div className="row">
                          <div className="col">
                            <div
                              className="multi-collapse collapse show"
                              id="multiCollapseExample3"
                            >
                              <div className="card card-body">
                                <div className="control-group">
                                  <label className="control control-checkbox full-width">
                                    All
                                    <input
                                      type="checkbox"
                                      defaultChecked={true}
                                      onClick={(e) => {
                                        selAllAirlines(e);
                                      }}
                                    />
                                    <div className="control_indicator"></div>
                                  </label>

                                  {/* AirLines Filter */}
                                  {airlinesArr != undefined ? (
                                    <FlightAirlines
                                      allAirlinesChbox={allAirlinesChbox}
                                      airlinesArrData={airlinesArr}
                                      filterByAirLines={filterByAirLines}
                                    />
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="reset text-center">
                        <button
                          className="btn btn-primary"
                          onClick={resetFilter}
                        >
                          <i className="fas fa-undo"></i>Reset all{" "}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-9 hotels-list">
                  <div className="hotels-wrapper">
                    {hotalDeta}

                    <div className="tripdetails">
                      <h2>Flights</h2>
                    </div>

                    <div className="d-flex flex-wrap justify-content-between align-items-center hotel-details form-info">
                      <div className="col-md-3 pl-0 direct-info">
                        <div className="hotel-desc d-flex">
                          <i
                            className="fas fa-calendar-alt"
                            aria-hidden="true"
                          ></i>
                          <div className="journey-date">
                            <h5 className="dates">{disdepadate}</h5>
                            <div className="returing">
                              <span>{depacity}</span>
                              <i className="fas fa-plane"></i>
                              <span>{destination}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-3 from-to-info">
                        <div className="hotel-desc d-flex">
                          <i
                            className="fas fa-calendar-alt"
                            aria-hidden="true"
                          ></i>
                          <div className="journey-date">
                            <h5 className="dates">{disreturndate}</h5>
                            <div className="returing">
                              <span>{destination}</span>
                              <i className="fas fa-plane"></i>
                              <span>{depacity}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-3 Passengers">
                        <ul className="p-0 m-0">
                          <li>
                            <span>
                              <i className="fas fa-user" aria-hidden="true"></i>
                              Passengers
                            </span>
                            : {passengers}
                          </li>
                          <li>
                            <span>
                              <i className="fas fa-star"></i>Cabin Class
                            </span>
                            : Economy
                          </li>
                        </ul>
                      </div>
                      <div className="col-md-3 price-dropdown text-right pr-0">
                        <a href="#">
                          Change Search<i className="fas fa-arrow-down"></i>
                        </a>
                      </div>
                    </div>

                    <div className="d-flex flex-wrap justify-content-between align-items-center results">
                      <div className="results-found">
                        {totalFlights} flights found
                      </div>
                      <div className="d-flex align-items-center refine-filter-section">
                        <button
                          data-toggle="modal"
                          onClick={manageSideFilter}
                          data-target="#modal_aside_right"
                          className="filter-toggle"
                          type="button"
                        >
                          Refine Filters{" "}
                          <i
                            className="fa fa-chevron-right"
                            aria-hidden="true"
                          ></i>
                        </button>
                        <div className="dropdown sorting">
                          {flightsOrdering == "ASC" ? (
                            <button
                              onClick={() => ordering("DESC")}
                              className="btn dropdown-toggle"
                              type="button"
                              id="dropdownMenuButton"
                            >
                              Cheapest
                              <i className="fas fa-sort-amount-down"></i>
                            </button>
                          ) : (
                            <button
                              onClick={() => ordering("ASC")}
                              className="btn dropdown-toggle"
                              type="button"
                              id="dropupMenuButton"
                            >
                              Cheapest
                              <i className="fas fa-sort-amount-up"></i>
                            </button>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="hotel-list-section holiday city-display">
                      {/* Flight Listing */}
                      {flightList != undefined && flightList != "" ? (
                        <FlightItem
                          cheapestPrice={cheapestPrice}
                          c_key={c_key}
                          hid={hid}
                          room_key={room_key}
                          flightList={currentItems}
                        />
                      ) : (
                        ""
                      )}

                      {/* Pagination */}
                      {flightList != undefined && flightList != "" ? (
                        <ItemPagination
                          ttpage={ttpage}
                          disNumOfPage={disNumOfPage}
                          startPage={startPage}
                          endPage={ttpage}
                          currentPage={currentPage}
                          paginate={paginate}
                          prevPaginate={prevPaginate}
                          nextPaginate={nextPaginate}
                          prevPagies={prevPagies}
                          nextPagies={nextPagies}
                        />
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
        <Footer />

        <div
          id="modal_aside_right"
          className="modal fixed-right fade"
          style={{ display: sideFilter }}
          tabIndex="-1"
          role="dialog"
        >
          <div className="modal-dialog modal-dialog-aside" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="full-width filters-outer">
                  <div className="filters bg-white full-list-advisor">
                    <div className="modify-search">
                      <button
                        onClick={manageFilter}
                        className="btn btn-primary"
                      >
                        <i className="fas fa-edit"></i>Modify search
                      </button>
                    </div>

                    {/* Modify Search */}
                    {UserInfo.device_type === "mobile" ? (
                      <FlightListModifySearch filterForm={filterForm} />
                    ) : (
                      ""
                    )}

                    <h5>Filter</h5>
                    <div className="filters-wrap">
                      <div className="filter-wrapper">
                        <h3>Stops</h3>
                        <div className="row">
                          <div className="col">
                            <div className="card card-body">
                              <div className="control-group">
                                {/* Stops Filter */}
                                {disStopsFilter(2)}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="filter-wrapper">
                        <h3>Price</h3>
                        <div className="row">
                          <div className="col">
                            <div className="card card-body">
                              {/* Price Filter */}
                              <p className="pricefilt">
                                US${minPrice} - US${maxPrice}
                              </p>
                              {disPriceFilter(2)}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="filter-wrapper">
                        <h3>Departure Time</h3>
                        <div className="row">
                          <div className="col">
                            <div className="card card-body">
                              {/* Departure Time Out Filter */}
                              <label className="amount">Outbound</label>
                              <p className="amount">
                                {depMinTime}:00 - {depMaxTime}:00
                              </p>
                              {departureRangeTime("out")}
                            </div>

                            <div className="card card-body">
                              {/* Departure Time Return Filter */}
                              <label className="amount">Return</label>
                              <p className="amount">
                                {retMinTime}:00 - {retMaxTime}:00
                              </p>
                              {departureRangeTime("in")}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="filter-wrapper">
                        <h3>Total flights Duration</h3>
                        <div className="row">
                          <div className="col">
                            <div className="card card-body">
                              {/* Flights Duration Out Filter */}
                              <label className="amount">Outbound</label>
                              <p className="amount">
                                {msToTime(depMinFlightDur)} -{" "}
                                {msToTime(depFlightDur)}
                              </p>
                              {flightDurationTime("out")}
                            </div>
                            <div className="card card-body">
                              {/* Flights Duration Return Filter */}
                              <label className="amount">Return</label>
                              <p className="amount">
                                {msToTime(retMinFlightDur)} -{" "}
                                {msToTime(retFlightDur)}
                              </p>
                              {flightDurationTime("in")}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="filter-wrapper">
                        <h3>Layout Airport</h3>
                        <div className="row">
                          <div className="col">
                            <div className="card card-body">
                              <div className="control-group more-options popup-options">
                                {/* Airport Filter */}
                                {currentAirport != undefined &&
                                currentAirport != "" ? (
                                  <FlightAirPorts
                                    allAirportsChbox={allAirportsChbox}
                                    airportsArr={currentAirport}
                                    filterByAirPort={filterByAirPort}
                                  />
                                ) : (
                                  ""
                                )}
                              </div>
                              <div className="view-more text-right">
                                <a
                                  className="loadmore"
                                  id="loadMoreAirport"
                                  onClick={() => {
                                    moreAirport();
                                  }}
                                >
                                  <i
                                    className="far fa-plus-square"
                                    aria-hidden="true"
                                  ></i>
                                  more
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="filter-wrapper">
                        <h3>All Airlines</h3>
                        <div className="row">
                          <div className="col">
                            <div
                              className="multi-collapse collapse show"
                              id="multiCollapseExample3"
                            >
                              <div className="card card-body">
                                <div className="control-group">
                                  {/* AirLines Filter */}
                                  {airlinesArr != undefined ? (
                                    <FlightAirlines
                                      allAirlinesChbox={allAirlinesChbox}
                                      airlinesArrData={airlinesArr}
                                      filterByAirLines={filterByAirLines}
                                    />
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="reset text-center">
                        <button
                          className="btn btn-primary"
                          onClick={resetFilter}
                        >
                          <i className="fas fa-undo"></i>Reset all{" "}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
