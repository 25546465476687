/**
  Passengers Summary Page Hotel Details.
  Html and data display by API,
  Display hotel details
**/

import React, {useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { getHotelDetails } from '../redux/actions/dataActions';
import moment from 'moment';
import { PassengersSummSingleRoom } from './PassengersSummSingleRoom';

export const PassengersSummaryHotelDetail = (props) =>{
    const showMenu = false;

    const passSumm = useSelector((state) => state);
    const dispatch = useDispatch();

    let ddate = moment(props.depDate).format('MM/DD/YYYY');
    let rdate = moment(props.retDate).format('MM/DD/YYYY');

   //Get Hotel List
   const fetchHotel = async () => {
        await axios
        .get(`https://package.militaryfares.com/?method=info&lang=en&curr=USD&affiliate=wannafly&master=wannafly&flight[from][0]=${props.depFrom}&flight[from][1]=${props.retFrom}&flight[to][0]=${props.depTo}&flight[to][1]=${props.retTo}&flight[outdate][0]=${ddate}&flight[outdate][1]=${rdate}&flight[adult]=${props.adult}&flight[senior]=0&flight[youth]=0&flight[child]=${props.child}&flight[infant]=${props.infant}&flight[cabin]=Y&flight[trip]=round&hotel[cache_key]=${props.cache_key}&hotel[code]=${props.code}`)
        .then(response => { 
            if( typeof response != 'undefined' &&  response != null && response.status === 200){   
                        
                if (response.data.status != 'ERR') {
                    dispatch(getHotelDetails(response.data));
                }else{
                    dispatch(getHotelDetails());
                }                     
            } 
        })
        .catch((err) => {
            dispatch(getHotelDetails(''));
            console.log("Err", err);
        }); 
    };

    useEffect(() => {
    fetchHotel();
    },[]);

    //Hotel Details
    let hotelDetsils = useSelector((state) => {
        if(state.HotelDetail.hotelDetls !== undefined && state.HotelDetail.hotelDetls !== ''){
            return state.HotelDetail.hotelDetls.hotels; 
        }
        return null;
    });
    
    let hotelData = '';
    if(hotelDetsils !== null){
        hotelData = hotelDetsils.map((item) =>{
            let HotelName = item.name;
            let hotelAddress = item.location.address +', '+ item.location.city +', '+ item.location.country;
            let hotelNight = item.hotel_nights;
            let hotelFrom = moment(item.hotel_from).format('MMM D, YYYY');
            let hotelTo = moment(item.hotel_to).format('MMM DD, YYYY');
            let hotelThumb = item.thumb;
            let final_rating = item.stars;
            let floor_rating = Math.floor(item.stars);
            let ceil_rating = Math.ceil(item.stars);
            let half_rating = final_rating - floor_rating;
            let not_sel_star = 5 - ceil_rating;
            let guest = '';
            guest += props.adult !== 0 ? props.adult + ' Adult, ' : '';
            guest += props.child !== 0 ? props.child + ' Child, ' : '';
            guest += props.infant !== 0 ? props.infant + ' Infant, ' : '';
            guest = guest.replace(/,\s*$/, "");
            guest = guest.replace(',', "");


            //Display start rating hotel wise
            const starRaring = () => {
                let startitem = []
                {
                    for(let k=0;k<floor_rating;k++){
                        startitem.push(
                        <React.Fragment key={k + "fillsr"}>
                            <label aria-label="0.5 stars" className="rating__label rating__label--half" htmlFor="rating2-05">
                                <i className="rating__icon rating__icon--star fa fa-star-half" aria-hidden="true"></i>
                            </label>
                            <input className="rating__input" name="rating2" id="rating2-05" type="radio" />
                            <label aria-label="1 star" className="rating__label" htmlFor="rating2-10">
                                <i className="rating__icon rating__icon--star fa fa-star" aria-hidden="true"></i>
                            </label>
                            <input className="rating__input" name="rating2" id="rating2-10" type="radio" />
                        </React.Fragment>
                        )   
                    }
                    if(half_rating != 0){
                        startitem.push(
                            <React.Fragment key={"hlfrat"}>
                        <label aria-label="0.5 stars" className="rating__label rating__label--half" htmlFor="rating2-05">
                                <i className="rating__icon rating__icon--star fa fa-star-half" aria-hidden="true"></i>
                            </label>
                            <input className="rating__input" name="rating2" id="rating2-05" type="radio" />
                            <label aria-label="1 star" className="rating__label" htmlFor="rating2-10">
                                <i className="rating__icon rating__icon--star fa fa-star" aria-hidden="true" style={startnotsel}></i>
                            </label>
                            <input className="rating__input" name="rating2" id="rating2-10" type="radio" />
                            </React.Fragment>
                        )
                    }

                    if(not_sel_star != 0){
                        for(let k=0;k<not_sel_star;k++){
                            startitem.push(
                                <React.Fragment key={k + "ntselsr"}>
                                <label aria-label="0.5 stars" className="rating__label rating__label--half" htmlFor="rating2-05">
                                    <i className="rating__icon rating__icon--star fa fa-star-half" aria-hidden="true" style={startnotsel}></i>
                                </label>
                                <input className="rating__input" name="rating2" id="rating2-05" type="radio" />
                                <label aria-label="1 star" className="rating__label" htmlFor="rating2-10">
                                    <i className="rating__icon rating__icon--star fa fa-star" aria-hidden="true" style={startnotsel}></i>
                                    </label>
                                <input className="rating__input" name="rating2" id="rating2-10" type="radio" />
                                </React.Fragment>
                            )
                        }
                    }
                }
                return startitem;
                
            }

            //star rating not selected
            const startnotsel = {
                color:'#ddd'
            }

            return(
                <div key='hotldt' className="hotel-detail bg-white">
                    <div className="airline-heading bg-white">
                        <div className="d-flex flex-wrap align-items-center justify-content-between spacing-20">
                            <h2><i className="fa fa-building" aria-hidden="true"></i>Hotel Details</h2>
                        </div>
                        <div className="spacing hotel-description">
                            <div className="d-flex flex-wrap">
                                <div className="hotel-image">
                                    <a><img src={hotelThumb} className="img-fluid" /></a>
                                </div>
                                <div className="hotel-right">
                                    <div className="rating d-flex align-items-center flex-wrap">
                                        <div id="half-stars-example">
                                            <div className="rating-group">
                                                <input className="rating__input rating__input--none"  name="rating2" id="rating2-0" value="0" type="radio" />
                                                <label aria-label="0 stars" className="rating__label" htmlFor="rating2-0">&nbsp;</label>
                                                {starRaring()}
                                            </div>
                                        </div>
                                        <span className="rating-points">{final_rating}</span>
                                    </div>
                                    <h5><a>{HotelName}</a></h5>
                                    <p className="location">{hotelAddress}</p>
                                </div>
                            </div>

                            <div className="d-flex flex-wrap">
                                <ul>
                                    <li><i className="fa fa-calendar" aria-hidden="true"></i>Nights: <span> {hotelNight} | {hotelFrom} - {hotelTo}</span></li>
                                    <PassengersSummSingleRoom depFrom={props.depFrom} depTo={props.depTo} retFrom={props.retFrom} retTo={props.retTo} depDate={props.depDate} retDate={props.retDate} adult={props.adult} child={props.child} infant={props.infant} cache_key={props.cache_key} code={props.code} p_key={props.p_key}/>
                                    <li><i className="fa fa-user" aria-hidden="true"></i>Guests: <span>{guest}</span></li>
                                </ul>
                            </div>

                            
                        </div>
                    </div>
                </div>
            )
        })
    }

    return(
        <React.Fragment>
            {hotelData}
        </React.Fragment>
    )
}