/**
  Flights in Fligth List Page.
  Html and data display by API,
  Can select Departure and return flight
**/

import React, { useState } from "react";
import $ from "jquery";
import Modal from "react-modal"; //Can Open model

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};
export const FlightItem = ({
  cheapestPrice,
  c_key,
  hid,
  room_key,
  flightList,
}) => {
  let depFlightDurTime = [];
  let retFlightDurTime = [];
  let noOfFlight = {};

  const [modalIsOpen, setIsOpen] = useState(false);
  const [selectFlight, setSelectFlight] = useState([]);

  //Manage flight details
  const flightDetail = (i, j, k) => {
    $("#display_details_menu_" + i + "_" + j + "_" + k).toggle("1000");
    $("#click_details_menu_" + i + "_" + j + "_" + k + " i").toggleClass(
      "far fa-plus-square far fa-minus-square"
    );
  };

  //Get time from date
  function msToTime(date1, date2) {
    let duration = Math.abs(date2 - date1);
    let milliseconds = parseInt((duration % 1000) / 100),
      seconds = Math.floor((duration / 1000) % 60),
      minutes = Math.floor((duration / (1000 * 60)) % 60),
      hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

    depFlightDurTime.push(duration);
    hours = hours < 10 ? "0" + hours : hours;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    seconds = seconds < 10 ? "0" + seconds : seconds;

    return hours + ":" + minutes + ":" + seconds + "." + milliseconds;
  }

  //Open Flight Select Popup
  const openModal = (i, url) => {
    let outflt = $(".selFligt_" + i + "_0").length;
    let retflt = $(".selFligt_" + i + "_1").length;
    let outflag = false;
    let retflag = false;

    for (let k = 0; k < outflt; k++) {
      if ($(".selFligt_" + i + "_0").is(":checked")) {
        outflag = true;
      }
    }

    for (let k = 0; k < retflt; k++) {
      if ($(".selFligt_" + i + "_1").is(":checked")) {
        retflag = true;
      }
    }

    //Manage validation and redirect
    if (outflag === false || retflag === false) {
      setIsOpen(true);
    } else {
      window.location = url;
    }
  };

  //close Flight Select Popup
  const closeModal = () => {
    setIsOpen(false);
  };

  //Manage Flight select
  const selFlight = (e) => {
    let curId = e.target.id;
    let curName = e.target.name;

    for (let k = 0; k < noOfFlight[curName]; k++) {
      let upid = curName + "_" + k;

      if (upid === curId) {
        if (selectFlight[upid]) {
          setSelectFlight((prev) => ({ ...prev, [upid]: false }));
        } else {
          setSelectFlight((prev) => ({ ...prev, [upid]: true }));
        }
      } else {
        setSelectFlight((prev) => ({ ...prev, [upid]: false }));
      }
    }
  };

  //Flight Listing
  const flightData = flightList.map((flight, i) => {
    let apiKey = flight.api.replace(/\//g, "~");
    let cacheKey = flight.cache_key.replace(/\//g, "~");
    // let modifyPrice = flight.TOTAL_PRICE - cheapestPrice;
    // let totalPrice = flight.currency +' $'+ modifyPrice.toFixed(2);
    let totalPrice = flight.currency + " $" + flight.TOTAL_PRICE.toFixed(2);

    let totalPassengers =
      parseInt(flight.search.adult) +
      parseInt(flight.search.senior) +
      parseInt(flight.search.youth) +
      parseInt(flight.search.child) +
      parseInt(flight.search.infant);
    // console.log(totalPassengers);

    let pricePerPerson =
      flight.TOTAL_PRICE > 0
        ? `per person $${(flight.TOTAL_PRICE / totalPassengers).toFixed(2)}`
        : "";

    let detailLink = flight.detailslink[0].split("&");
    let detailLinkval = detailLink[3].replace("book=", "");
    let passSummUrl = `/pass-summary/${c_key}/${hid}/${room_key}/${detailLinkval}/${apiKey}/${cacheKey}`;

    return (
      <div key={i} className="hotel-list bg-white">
        <div className="d-flex flex-wrap ">
          <div className="col-md-9 position-relative flight-description">
            <div className="card card-body">
              <div className="control-group">
                {flight.FLI_INF.map((item, j) => {
                  let count = 0;
                  return item.map((itm, k) => {
                    count++;
                    let startdate = "";
                    let enddate = "";

                    let numofStops = itm.DEP_DAT_TIM.length - 1;

                    startdate = new Date(itm.DEP_DAT_TIM[0]);
                    // // enddate = new Date(itm.ARR_DAT_TIM[1]);
                    enddate = new Date(itm.ARR_DAT_TIM[numofStops]);
                    let TotalTimeTake = msToTime(startdate, enddate);
                    let DisTime = TotalTimeTake.toString().split(":");
                    let DisHourMin = DisTime[0] + "h" + " " + DisTime[1] + "m";

                    let startFlightLogo = itm.ARL_COD[0].toLowerCase() + ".gif";
                    let endFlightLogo =
                      itm.ARL_COD[numofStops].toLowerCase() + ".gif";

                    let DisDepDate = itm.DEP_DAT[0];
                    let DisDepTime = itm.DEP_TIM[0];
                    let DisDepDateTime =
                      DisDepDate.substr(2) +
                      " " +
                      DisDepDate.substr(0, 2) +
                      " " +
                      DisDepTime.substr(0, 2) +
                      ":" +
                      DisDepTime.substr(2);

                    let DisArrDate = itm.ARR_DAT[numofStops];
                    let DisArrTime = itm.ARR_TIM[numofStops];
                    let DisArrDateTime =
                      DisArrDate.substr(2) +
                      " " +
                      DisArrDate.substr(0, 2) +
                      " " +
                      DisArrTime.substr(0, 2) +
                      ":" +
                      DisArrTime.substr(2);

                    let DisStops = numofStops;
                    let DisFromFlt = itm.DEP_ARP[0];
                    let DisToFlt = itm.ARR_ARP[numofStops];

                    //Flight Stop Waiting time
                    const flightStopwz = Array.from(
                      Array(itm.DEP_DAT_TIM.length).keys()
                    ).map((item) => {
                      startdate = new Date(itm.DEP_DAT_TIM[item]);
                      enddate = new Date(itm.ARR_DAT_TIM[item]);
                      let TimeTake1 = msToTime(startdate, enddate);
                      let DetTime1 = TimeTake1.toString().split(":");
                      let DetHourMin1 =
                        DetTime1[0] + "h" + " " + DetTime1[1] + "m";

                      // startdate = new Date(itm.DEP_DAT_TIM[1]);
                      startdate = new Date(itm.DEP_DAT_TIM[item]);
                      enddate = new Date(itm.ARR_DAT_TIM[item - 1]);
                      let waitTime = msToTime(startdate, enddate);
                      let DetTimeMid = waitTime.toString().split(":");
                      let DetHourMinMid =
                        DetTimeMid[0] + "h" + " " + DetTimeMid[1] + "m";

                      let DetDepDate1 = itm.DEP_DAT[item];
                      let DetDepTime1 = itm.DEP_TIM[item];
                      let DisDepDateTime1 =
                        DetDepDate1.substr(2) +
                        " " +
                        DetDepDate1.substr(0, 2) +
                        ", " +
                        DetDepTime1.substr(0, 2) +
                        ":" +
                        DetDepTime1.substr(2);

                      let DetArrDate1 = itm.ARR_DAT[item];
                      let DetArrTime1 = itm.ARR_TIM[item];
                      let DisArrDateTime1 =
                        DetArrTime1.substr(0, 2) + ":" + DetArrTime1.substr(2);

                      let DetFromFlt1 = itm.DEP_ARP[item];
                      let DetToFlt1 = itm.ARR_ARP[item];

                      let DetDepTwn1 = itm.DEP_TWN[item];
                      let DetArrTwn1 = itm.ARR_TWN[item];

                      let DetArlName1 = itm.ARL_NAM[item];
                      let DetArlCodeNum1 =
                        itm.ARL_COD[item] + "-" + itm.FLI_NUM[item];

                      let Baggage1 = itm.Baggage[item].included;

                      let Baggage_cabin1 = itm.Baggage_new[item].cabin;
                      let Baggage_hand1 = itm.Baggage_new[item].hand;
                      let Baggage_new1 = "";

                      if (Baggage_cabin1 == "YES") {
                        Baggage_new1 += "cabin bag included";
                      } else {
                        Baggage_new1 += "cabin bag not included";
                      }

                      if (Baggage_hand1 == "YES") {
                        Baggage_new1 += " + small hand bag";
                      } else {
                        Baggage_new1 += " + small hand not bag";
                      }

                      let Boeing1 = itm.EQP_TYP[item];
                      let miles1 = itm.LEG_MIL[item];

                      let waitArr = [];
                      if (item > 0) {
                        waitArr.push(
                          <div
                            key={j + "_" + k + "_" + item + "wait"}
                            className="row"
                          >
                            <div className="layover_block">
                              <h6>Layover Airport: {DetFromFlt1}</h6>
                              <p>
                                <span>
                                  <i className="fas fa-clock"></i>
                                </span>
                                {DetHourMinMid}
                              </p>
                            </div>
                          </div>
                        );
                      }

                      return (
                        <React.Fragment key={j + "_" + k + "_" + item}>
                          {/* Flight Waiting time */}
                          {waitArr}
                          <div className="row">
                            <div className="col-sm-3">
                              <p className="left_main_text">{DetArlName1}</p>
                              <p className="left_sub_text">{DetArlCodeNum1}</p>
                            </div>
                            <div className="col-sm-9">
                              <div className="d-flex flex-row">
                                <p className="left_date">{DisDepDateTime1}</p>
                                <p className="left_date ml-auto">
                                  {DisArrDateTime1}
                                </p>
                              </div>
                              <div className="bcn_mad_text">
                                <span>{DetFromFlt1}</span>
                                <i className="fas fa-plane"></i>
                                <span>{DetToFlt1}</span>
                              </div>
                              <div className="d-flex flex-row">
                                <p className="detail_city">{DetDepTwn1}</p>
                                <p className="detail_city ml-auto">
                                  {DetArrTwn1}
                                </p>
                              </div>
                              <div className="all_details">
                                <p>
                                  <span>
                                    <i className="fas fa-clock"></i>
                                  </span>
                                  {DetHourMin1}
                                </p>
                                <p>
                                  <span>
                                    <i className="fas fa-suitcase"></i>
                                  </span>
                                  Baggage: {Baggage1}
                                </p>
                                <p>
                                  <span>
                                    <i className="fas fa-suitcase-rolling"></i>
                                  </span>
                                  {Baggage_new1}
                                </p>
                                <p>
                                  <span>
                                    <i className="fas fa-plane"></i>
                                  </span>
                                  {Boeing1}
                                </p>
                                <p>
                                  <span>
                                    <i className="fas fa-map-marker-alt"></i>
                                    <i className="fas fa-arrows-alt-h px-1"></i>
                                    <i className="fas fa-map-marker-alt"></i>
                                  </span>
                                  miles: {miles1}
                                </p>
                              </div>
                            </div>
                          </div>
                        </React.Fragment>
                      );
                    });
                    noOfFlight["selFligt_" + i + "_" + j] = count;
                    return (
                      <div
                        key={j + "_" + k + "_" + item + "flt"}
                        className="going-flight"
                      >
                        <div className="d-flex flex-wrap">
                          <ul>
                            <li>
                              <img
                                className="airlogos"
                                src={
                                  process.env.PUBLIC_URL +
                                  "/assets/images/airlogos/" +
                                  startFlightLogo
                                }
                              />
                            </li>
                            <li>
                              <img
                                className="airlogos"
                                src={
                                  process.env.PUBLIC_URL +
                                  "/assets/images/airlogos/" +
                                  endFlightLogo
                                }
                              />
                            </li>
                          </ul>
                        </div>
                        <label className="control control-checkbox full-width d-flex flex-wrap">
                          <div className="returing">
                            <span>
                              {DisFromFlt}
                              <span className="date">
                                {DisDepDateTime}
                                <b className="stops">
                                  <i className="fas fa-hand-paper"></i>
                                  {DisStops}
                                </b>
                              </span>
                            </span>
                            <i className="fas fa-plane"></i>
                            <span>
                              {DisToFlt}
                              <span className="date">
                                {DisArrDateTime}
                                <b className="time">
                                  <i className="fas fa-clock"></i>
                                  {DisHourMin}{" "}
                                </b>
                              </span>
                            </span>
                          </div>
                          <input
                            type="checkbox"
                            name={"selFligt_" + i + "_" + j}
                            id={"selFligt_" + i + "_" + j + "_" + k}
                            checked={
                              selectFlight["selFligt_" + i + "_" + j + "_" + k]
                            }
                            onClick={selFlight}
                            className={"selFligt_" + i + "_" + j}
                          />
                          <div className="control_indicator"></div>
                          {/* <div className="rating-points">+1</div> */}
                          <div
                            className="details_btn_main"
                            id={"click_details_menu_" + i + "_" + j + "_" + k}
                            onClick={() => {
                              flightDetail(i, j, k);
                            }}
                          >
                            <button className="btn details_btn">
                              Details<i className="far fa-plus-square"></i>
                            </button>
                          </div>
                        </label>
                        <div
                          className="details_menu"
                          id={"display_details_menu_" + i + "_" + j + "_" + k}
                        >
                          {flightStopwz}
                        </div>
                      </div>
                    );
                  });
                })}
              </div>
            </div>
          </div>
          <div className="col-md-3 flight-pricing pt-4 flex-column d-flex">
            {/* <span className="fare">Package additional flight price</span> */}
            <span className="fare">Surcharge</span>
            <span className="flight-price changeflight-price m-0">
              {totalPrice}
            </span>
            <span className="fare mb-2">{pricePerPerson}</span>
            <div className="check-price">
              <a
                onClick={() => {
                  openModal(i, passSummUrl);
                }}
              >
                SELECT FLIGHT
              </a>
            </div>
          </div>
          <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            style={customStyles}
          >
            <h2>Please select departure and return flight.</h2>
            <div className="passenger_modal_btn">
              <button onClick={closeModal}>OK</button>
            </div>
          </Modal>
        </div>
      </div>
    );
  });
  return <React.Fragment>{flightData}</React.Fragment>;
};
