/**
  Passengers In Home and flight list page.
  Can select passengers room and category wise Adults, Children, Infants, Youth.
**/

import React, {useState} from 'react'

export const HomePassengers = ({roomsWisePess,removeRoom,decreasebtnPass,increasebtnPass,childAgeManage,selChiledAge}) => {
         
    //Child Age Number
    const childAges = (roomid,i,selage,r) => {
        let agecnt = 0;
        let ageloop = 0;
        return (
            Array.from(Array(4).keys()).map((item,l) => {
                ageloop = 5
                if(item === 3){
                    ageloop = 3; 
                }
                return (
                <div key={`agepr_${roomid}_${i}_${l}`} id={`${roomid}_${i}_${l}`} className="d-flex flex-direction-row">
                {
                    
                    Array.from(Array(ageloop).keys()).map((item) => {
                        let age = agecnt;
                        let ageactive = 'age_num_btn';
                        if(selage == age){
                            ageactive = 'orange_age_child';
                        }
                        return(
                            <div key={`age_${roomid}_${i}_${agecnt}`} id={`age_${roomid}_${i}_${agecnt}`} onClick={(e) => selChiledAge(roomid,i,age,r)} className="age_child">
                                <button className={`btn ${ageactive}`}>{agecnt++}</button>
                            </div>
                        )
                        
                    }) 
                }
                </div>
                )
            })
        )
    }

    //Chile Age Listing
    const chiledAgeList = (roomid) => {
        return (
            Object.keys(childAgeManage[roomid]).map((item,r) => {
                // if(item === 'ages'){
                    return (
                        childAgeManage[roomid][item].map((itm,i) => {
                            let selage = itm.age;
                            return (
                                <li key={`agelt_${r}_${i}`}>
                                    <div className="child_ages">
                                        <button className="btn child_age_title"  href={`#manageChildAge_${roomid}_${r}_${i}`} data-toggle="collapse" >Child's age: <span>{selage}</span></button>
                                        <div className="ageNumber collapse" id={`manageChildAge_${roomid}_${r}_${i}`}>
                                            {childAges(roomid,i,selage,r)}
                                        </div>
                                    </div>
                                </li>
                                )
                        })
                    )
                // }
                
            })
        )
    }
    
    const roomlist = roomsWisePess.map((room, i) => {
        
        let roomid = i; 
        let roomadults = room.adults;
        let roomchildren = room.children;
        let roominfants = room.infants;
        let roomyouth = room.youth;

        //Disply remove icon
        const removeRoomIcon = (val) => {
            if(roomsWisePess.length > 1){
                return (
                    <div className="close" onClick={() => removeRoom(val)}> 
                        <svg   
                            aria-hidden="true" 
                            focusable="false" data-prefix="fas"
                            data-icon="times-circle"
                            className="svg-inline--fa fa-times-circle fa-w-16 float-right cursor-pointer font28 margin-t7 margin-lr-10"
                            role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                            <path fill="currentColor"
                            d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z">
                            </path>
                        </svg>
                    </div>
                );
            }else{
                return '';
            }
        }
 
        
        return (
            <React.Fragment key={'room'+roomid}>
                <div className="accordion" id={"accordionExample"+roomid}>
                    <div className="card">
                        <div className="card-header" id="heading1">
                            <h2 className="mb-0">
                                <button className="btn btn-link btn-block text-left" type="button" data-toggle="collapse" data-target={"#body" + roomid} aria-expanded="true" aria-controls={"body" + roomid}> Room {roomid + 1} </button>
                                
                                {removeRoomIcon(roomid)}
                                
                            </h2>
                        </div>

                        <div id={"body" + roomid} className="collapse show" aria-labelledby="heading1" data-parent={"#accordionExample"+roomid}>
                            <div className="card-body">
                                <ul className="list-persons-count">
                                    <li>
                                        <span>Adults:</span>
                                        <div className="counter-add-item ">
                                            <a className={"decrease-btn adults_"+roomid} id="dadlt" onClick = {decreasebtnPass}>-</a>
                                            <input type="text" name={"adults"+roomid} id={"adults"+roomid} value={roomadults} readOnly className="count" />
                                            <a className={"increase-btn adults_"+roomid} id="iadlt" onClick = {increasebtnPass}>+</a>
                                        </div>
                                    </li>
                                    <li>
                                        <span>Children:</span>
                                        <div className="counter-add-item">
                                            <a className={"decrease-btn children_"+roomid} id="dchrn" onClick = {decreasebtnPass}>-</a>
                                            <input type="text" name={"children"+roomid} id={"children"+roomid} value={roomchildren} readOnly className="count" />
                                            <a className={"increase-btn children_"+roomid} id="ichrn" onClick = {increasebtnPass}>+</a>
                                        </div>
                                    </li>
                                    {/* <li>
                                        <span>Infants:</span>
                                        <div className="counter-add-item">
                                            <a className={"decrease-btn infants_"+roomid} id="dints" onClick = {decreasebtnPass}>-</a>
                                            <input type="text" name={"infants"+roomid} id={"infants"+roomid} value={roominfants} readOnly className="count" />
                                            <a className={"increase-btn infants_"+roomid} id="iints" onClick = {increasebtnPass}>+</a>
                                        </div>
                                    </li>
                                    <li>
                                        <span>Youth:</span>
                                        <div className="counter-add-item">
                                            <a className={"decrease-btn youth_"+roomid} id="dyoth" onClick = {decreasebtnPass}>-</a>
                                            <input type="text" name={"youth"+roomid} id={"youth"+roomid} value={roomyouth} readOnly className="count" />
                                            <a className={"increase-btn youth_"+roomid} id="iyoth" onClick = {increasebtnPass}>+</a>
                                        </div>
                                    </li> */}
                                    {chiledAgeList(roomid)}
                                    {/* <li>
                                        <div className="child_ages">
                                          <button className="btn child_age_title" href="#manageChildAge" data-toggle="collapse" >Child's age: <span>1</span></button>
                                            <div className="ageNumber collapse" id="manageChildAge">
                                                
                                                     
                                            </div>
                                        </div>

                                    </li> */}
                                     
                            
                                </ul> 
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    });

    return (
        roomlist
    )
}
