/**
  Hotel Details Page.
  Data display by API, 
  User can see cancellation details by clicking on "Cancellation Policy & Important Information" 
  User can select room by click on "Book Now" on perticular room
**/

import React, {useEffect, useState} from 'react'
import Header from '../components/Header';
import Footer from '../components/Footer';
import { commanConst } from '../redux/constants/commanConst';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { getHotelDetails } from '../redux/actions/dataActions';
import { HotelRooms } from './HotelRooms';
import { useParams } from 'react-router-dom';
import moment from 'moment';

const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
};

export const HotelDetails = () => {
    const showMenu = false;
    
    const {a, budget, depacityName, depacity, destinationName, destination,destLat,destLon, depadate, returndate, passengers, totalAdult, totalChild, totalInfant, totalYouth, c_key, hid} = useParams();
    
    const hotel = useSelector((state) => state);
    const dispatch = useDispatch();
    const tolGuest = parseInt(totalAdult) + parseInt(totalChild) + parseInt(totalInfant) + parseInt(totalYouth);

    let ddate = moment(depadate,'MM-DD-YYYY').format('MM/DD/YYYY');
    let rdate = moment(returndate,'MM-DD-YYYY').format('MM/DD/YYYY');

    //Display Dates
    let disdepadate = moment(depadate,'MM-DD-YYYY').format('ddd, DD MMM YYYY');
    let disreturndate = moment(returndate,'MM-DD-YYYY').format('ddd, DD MMM YYYY');

    let flightUrl = `/flightlist/${a}/${budget}/${depacityName}/${depacity}/${destinationName}/${destination}/${destLat}/${destLon}/${depadate}/${returndate}/${passengers}/${totalAdult}/${totalChild}/${totalInfant}/${totalYouth}/${c_key}/${hid}`;
    let hotelList = `/hotellist/${a}/${budget}/${depacityName}/${depacity}/${destinationName}/${destination}/${destLat}/${destLon}/${depadate}/${returndate}/${passengers}/${totalAdult}/${totalChild}/${totalInfant}/${totalYouth}`;
    
    const [hotelsLoader, setHotelsLoader] = useState('block');
    
    //Get Hotel List
    const fetchHotel = async () => {
        await axios
        .get(`https://package.militaryfares.com/?method=info&lang=en&curr=USD&affiliate=${a}&master=${a}&flight[from][0]=${depacity}&flight[from][1]=${destination}&flight[to][0]=${destination}&flight[to][1]=${depacity}&flight[outdate][0]=${ddate}&flight[outdate][1]=${rdate}&flight[adult]=${totalAdult}&flight[senior]=0&flight[youth]=${totalYouth}&flight[child]=${totalChild}&flight[infant]=${totalInfant}&flight[cabin]=Y&flight[trip]=round&hotel[cache_key]=${c_key}&hotel[code]=${hid}`)
        .then(response => { 
            if( typeof response != 'undefined' &&  response != null && response.status === 200){   
                       
                if (response.data.status != 'ERR') {
                    dispatch(getHotelDetails(response.data));
                }else{
                    dispatch(getHotelDetails(''));
                }   
                setTimeout(() => {
                    setHotelsLoader("none");                
                },1000);  
            } 
        })
        .catch((err) => {
            dispatch(getHotelDetails(''));
            console.log("Err", err);
            setTimeout(() => {
                setHotelsLoader("none");                
            },1000);
        }); 
    };

    useEffect(() => {
        dispatch(getHotelDetails(''));
        setHotelsLoader("block");
        fetchHotel();
        console.log(`https://package.militaryfares.com/?method=info&lang=en&curr=USD&affiliate=${a}&master=${a}&flight[from][0]=${depacity}&flight[from][1]=${destination}&flight[to][0]=${destination}&flight[to][1]=${depacity}&flight[outdate][0]=${ddate}&flight[outdate][1]=${rdate}&flight[adult]=${totalAdult}&flight[senior]=0&flight[youth]=${totalYouth}&flight[child]=${totalChild}&flight[infant]=${totalInfant}&flight[cabin]=Y&flight[trip]=round&hotel[cache_key]=${c_key}&hotel[code]=${hid}`);
    },[]);

    //Hotel Details
    const hotelDetsils = useSelector((state) => {
        if(state.HotelDetail.hotelDetls !== undefined && state.HotelDetail.hotelDetls !== ''){
            return state.HotelDetail.hotelDetls.hotels;
        }
        return null
    });
    
    const [slidimg,setSlidimg] = useState(0);

    let hotalDeta = [];
    
    
    let totalSlid = 0;
    if(hotelDetsils !== null && hotelDetsils !== undefined){
        if(hotelDetsils[0].details !== undefined && hotelDetsils[0].details !== '' && hotelDetsils[0].details !== null){
            if(hotelDetsils[0].details.length !== 0){
                totalSlid = hotelDetsils[0].details.imgs.length;                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                             
            }
        }
    }    
    useEffect(() => {
        sliderimg('');
    }, [slidimg]);

    let timer1 = null;
    const sliderimg = (e) => {
        if(e == 'stop'){
            clearTimeout(timer1);
        }else{
            timer1 = setTimeout(() => {
                let slidcount = slidimg + 1;
                if(slidcount < totalSlid){
                    setSlidimg(slidcount);
                }else{
                    setSlidimg(0);
                }
            }, 3000);
        }
    }

    const nextImage = (e) => {
        sliderimg('stop');
        let slidcount = slidimg + 1;
        if(slidcount < totalSlid){
            setSlidimg(slidcount);
        }else{
            setSlidimg(0);
        }
    }

    const prevImage = () => {
        sliderimg('stop');
        let slidcount = slidimg - 1;
        if(slidcount < 0){
            setSlidimg(totalSlid-1);
        }else{
            setSlidimg(slidcount);
        }

    }

    const loader = {
        display: hotelsLoader
    }
    
    if(hotelDetsils !== null && hotelDetsils !== undefined){

        hotalDeta = hotelDetsils.map((hotel,i) => {
            
            let disfacil = [];    
            let morefacil = [];  
            let service = hotel.service;   
            let hotelName = hotel.name;   
            let hotelDesc = hotel.desc;
            let hotelAddress = hotel.location.address +', '+ hotel.location.city +', '+ hotel.location.country;
            let hotelImg = hotel.thumb;

            let hotelImgs = '';
            let hotelImgs1 = [];
             
            //Multiple Images
              
            if(hotel.details !== undefined && hotel.details !== '' && hotel.details !== null){
                if(hotel.details.length !== 0){
                    hotelImgs = hotel.details.imgs.map((item,i) => {
                        hotelImgs1.push(item.url);
                    })
                }else{
                    hotelImgs1.push(process.env.PUBLIC_URL +"/assets/images/noimage.jpg");
                }
                
            }else{
                
                
                if(hotel.thumb !== null){
                    hotelImgs1.push(hotel.thumb);
                }else{
                    hotelImgs1.push(process.env.PUBLIC_URL +"/assets/images/noimage.jpg");
                }
            }
             
            let final_rating = hotel.stars;
            let floor_rating = Math.floor(hotel.stars);
            let ceil_rating = Math.ceil(hotel.stars);
            let half_rating = final_rating - floor_rating;
            let not_sel_star = 5 - ceil_rating; 
            let facilities = hotel.facilities;
             
            //Map url
            const mapurl = `https://www.google.com/maps/embed/v1/place?key=${commanConst.GOOGLE_KEY}&q=${hotelAddress}`;

        
            if(facilities != null){
            //differentiate facilities display and more list
                const renderList = facilities.map((item) => { 
                    let modiname = item.replace(/[^a-zA-Z ]/g, "").replace(/ /g, "").toLowerCase(); //remove space and special charectes
                    if(commanConst.DISPLAYFACILITIES.indexOf(modiname) == -1){
                        morefacil.push(item) // More facilities
                    }else{
                        disfacil.push(item) //Display facilities
                    }
                });    
            }
            
            //remove duplicate value from array
            let disfacilits = [];
            disfacilits = disfacil.filter(function(item, pos, self) {
                return self.indexOf(item) == pos;
            })
            
            //remove duplicate value from array
            let morefacilits = [];
            morefacilits = morefacil.filter(function(item, pos, self) {
                return self.indexOf(item) == pos;
            })
                

            //Display facilities list
            let dispfacilitis = '';
            if(disfacilits.length != 0){
                dispfacilitis = disfacilits.map((item,i) => {
                    let modiname = item.replace(/[^a-zA-Z ]/g, "").replace(/ /g, "").toLowerCase(); //remove space and special charectes
                    let disfacility = commanConst.DISPLAYFACILITIESICON.facility[modiname];
                    return   (
                        <div key={i+'falty'} className="col-md-2">
                            <div className="facility-slide">
                                <i className={disfacility} aria-hidden="true"></i>
                                <span>{item}</span>
                            </div>
                        </div>
                    )
            });          
            }

            //More facilities list
            let morefacilitis = '';
            if(morefacilits.length != 0){
                morefacilitis = morefacilits.map((item,i) => {
                    return (
                        <div key={i+"mrfacty"} className="col-md-6">
                            <div className="facility-more">
                                <i className="fa fa-check" aria-hidden="true"></i>
                                <span>{item}</span>
                            </div>
                        </div>
                    )
                    
                });          
            }

            //Display start rating hotel wise
            const starRaring = () => {
                let startitem = []
                {
                    for(let k=0;k<floor_rating;k++){
                        startitem.push(
                        <React.Fragment key={k + "fillsr"}>
                            <label aria-label="0.5 stars" className="rating__label rating__label--half" htmlFor="rating2-05">
                                <i className="rating__icon rating__icon--star fa fa-star-half" aria-hidden="true"></i>
                            </label>
                            <input className="rating__input" name="rating2" id="rating2-05" type="radio" />
                            <label aria-label="1 star" className="rating__label" htmlFor="rating2-10">
                                <i className="rating__icon rating__icon--star fa fa-star" aria-hidden="true"></i>
                            </label>
                            <input className="rating__input" name="rating2" id="rating2-10" type="radio" />
                        </React.Fragment>
                        )   
                    }
                    if(half_rating != 0){
                        startitem.push(
                            <React.Fragment key={"hlfrat"}>
                        <label aria-label="0.5 stars" className="rating__label rating__label--half" htmlFor="rating2-05">
                                <i className="rating__icon rating__icon--star fa fa-star-half" aria-hidden="true"></i>
                            </label>
                            <input className="rating__input" name="rating2" id="rating2-05" type="radio" />
                            <label aria-label="1 star" className="rating__label" htmlFor="rating2-10">
                                <i className="rating__icon rating__icon--star fa fa-star" aria-hidden="true" style={startnotsel}></i>
                            </label>
                            <input className="rating__input" name="rating2" id="rating2-10" type="radio" />
                            </React.Fragment>
                        )
                    }

                    if(not_sel_star != 0){
                        for(let k=0;k<not_sel_star;k++){
                            startitem.push(
                                <React.Fragment key={k + "ntselsr"}>
                                <label aria-label="0.5 stars" className="rating__label rating__label--half" htmlFor="rating2-05">
                                    <i className="rating__icon rating__icon--star fa fa-star-half" aria-hidden="true" style={startnotsel}></i>
                                </label>
                                <input className="rating__input" name="rating2" id="rating2-05" type="radio" />
                                <label aria-label="1 star" className="rating__label" htmlFor="rating2-10">
                                    <i className="rating__icon rating__icon--star fa fa-star" aria-hidden="true" style={startnotsel}></i>
                                </label>
                                <input className="rating__input" name="rating2" id="rating2-10" type="radio" />
                                </React.Fragment>
                            )
                        }
                    }
                }
                return startitem;
            }
            
            //star rating not selected
             const startnotsel = {
                color:'#ddd'
            }

            //Display Default Image
            const addDefaultSrc = (ev) => {
                ev.target.src = process.env.PUBLIC_URL +"/assets/images/noimage.jpg";
            }
            
            return (
                <main key={i}>
                    
                    <section className="holiday single-detail">
                        <div className="container">
                            <div className="booking-scroller text-center"><a href="#booking-details">Booking details <i className="fas fa-chevron-right" aria-hidden="true"></i></a></div>
                            <div className="d-flex flex-wrap hotel-details  align-items-center">
                                <div className="col-md-8 d-flex flex-wrap hotel-details-list align-items-center p-0">
                                    <div className="hotel-desc">
                                        <h5><a href="#">{hotelName}</a></h5>
                                            <div className="rating d-flex align-items-center flex-wrap">
                                                <div id="half-stars-example">
                                                    <div className="rating-group">
                                                        <input className="rating__input rating__input--none"  name="rating2" id="rating2-0" defaultValue="0" type="radio" />
                                                        <label aria-label="0 stars" className="rating__label" htmlFor="rating2-0">&nbsp;</label>
                                                        {starRaring()}
                                                    </div>
                                                </div>
                                                <span className="rating-points">{final_rating}</span>
                                            </div>
                                        <ul className="p-0 m-0 d-flex flex-wrap align-items-center">
                                            <li><i className="fas fa-map-marker-alt"></i>{hotelAddress}</li>
                                            <li><span><i className="fas fa-user" aria-hidden="true"></i>Guests</span>: {tolGuest}</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-md-4 search text-right">
                                    <a href={`${hotelList}`} ><i className="fas fa-arrow-left"></i>View all Hotels</a>
                                </div>
                            </div>

                            <div className="left-right-layout">
                                <div className="row">
                                    <div className="col-md-8 room-acc">
                                        <div className="popup-hotel">
                                            <div className="p-2 bg-white">
                                                <div className="col-md-12 hotel-gallery p-0">
                                                 
                                                    <div id="carouselExampleIndicators" className="carousel slide" data-ride="carousel">
                                                        <div className="carousel-inner">
                                                            { /*Image Slider*/ }
                                                            <div className="carousel-item active">
                                                                <img src={hotelImgs1[slidimg]} onError={addDefaultSrc} className="slidimgitm" />
                                                            </div>

                                                        </div>
                                                        {hotelImgs1.length > 1
                                                            ?
                                                            <span>
                                                                <a className="carousel-control-prev" onClick={() => {prevImage()}}   data-slide="prev">
                                                                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                                                    <span className="sr-only">Previous</span>
                                                                </a>
                                                                <a className="carousel-control-next" onClick={() => {nextImage()}}  data-slide="next">
                                                                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                                                    <span className="sr-only">Next</span>
                                                                </a>
                                                            </span>
                                                            
                                                            : ''
                                                        }
                                                        
                                                    </div>
                                                </div>
                                            
                                                <div className="col-md-12 p-0">
                                                    <p>{hotelDesc}</p>
                                                </div>

                                                <div className="facilities-list">
                                                    <h5>Facilities</h5>
                                                    <div className="row">
                                                        {/* Display Facilities */}
                                                        {dispfacilitis}
                                                    </div>

                                                    <div className="morefacility">
                                                        <div className="row">
                                                            {/* More Facilities */}
                                                            {morefacilitis}
                                                                                                                                                                        
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-12 select-room bg-light-blue p-0 mt-5">
                                                <h4>Select Your Room</h4>
                                                
                                                <HotelRooms flightUrl={flightUrl} service={service} roomList={hotel.packs}/>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 selected-room" id="booking-details">
                                        <div className="journey-up-down bg-white">
                                            <h2>Booking details</h2>
                                            <ul>
                                                <li><img src={process.env.PUBLIC_URL +"/assets/images/check-in.png"} /><h4>Check in<span>{disdepadate}</span></h4></li> 
                                                <li><img src={process.env.PUBLIC_URL +"/assets/images/check-close.png"} /><h4>Check out<span>{disreturndate}</span></h4></li> 
                                            </ul>
                                        </div>
                                        <div className="map">
                                            <iframe src={mapurl} className="mapfram" allowFullScreen="" loading="lazy" width="600" height="450"></iframe>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </main>
            )
        });
    }


    return (
        <React.Fragment>
            <div className="loader-parent" style={loader}>
                        <img src={process.env.PUBLIC_URL + '/assets/images/loading.gif'} className="loaderset"/>
                    </div> 
            <Header showMenu={showMenu} tabTitle="Wannafly | HotelDetails"/>
            {hotalDeta}
            <Footer footerclassName="single-detail" />
        </React.Fragment>
    )
}
