/**
 * Pagination
  All listing data manage by pagination  
**/

import React, {useState} from 'react'

export const ItemPagination = ({ttpage, disNumOfPage, startPage,endPage, currentPage, paginate, prevPaginate, nextPaginate, prevPagies, nextPagies}) => {

    const pageNumbers = [];
    // console.log(Math.ceil(totalHotels / itemPerPage));
    for(let i=startPage; i <= endPage; i++){
        pageNumbers.push(i);
    }
    
    //Last page number
    const lastPage = pageNumbers[pageNumbers.length-1];
    const displen = pageNumbers.length;

    //Scroll To Top
    const scrollToTop = () => {
        window.scrollTo({
          top: 0,
          behavior: "smooth"
        });
    }
     
    

    return (
        <div className="text-md-center bottom-pagination pagination-item text-sm-right">
            <nav aria-label="Page navigation example">
                <ul className="pagination hotlpagi justify-content-start">
                {
                    currentPage !== 1 &&
                    <React.Fragment>
                         {
                            disNumOfPage < currentPage &&
                            <li className="page-item btnclr">
                                <a onClick={() => {prevPagies(); scrollToTop();}} className="page-link" aria-label="Previous">
                                    <i className="fas fa-angle-double-left"></i>
                                </a>
                            </li>
                        }
                        <li className="page-item btnclr">
                            <a onClick={() => {prevPaginate(); scrollToTop();}} className="page-link" aria-label="Previous">
                                <i className="fas fa-chevron-left"></i>
                            </a>
                        </li>
                    </React.Fragment>
                    
                }

                    {pageNumbers.map(number => {
                        if(number == currentPage){
                            return <li key={number} onClick={() => paginate(number)} className="page-item activehotel"><a className="page-link">{number}</a></li>
                        }else{
                            return <li key={number} onClick={() => paginate(number)} className="page-item"><a className="page-link">{number}</a></li>
                        }    
                    })}
              
                    {
                        ttpage !== currentPage &&
                        <React.Fragment>
                            <li className="page-item btnclr">
                                <a   onClick={() => {nextPaginate(lastPage); scrollToTop(); }} className="page-link" aria-label="Next">
                                    <i className="fas fa-chevron-right"></i>
                                </a>
                            </li>
                            {
                                disNumOfPage < ttpage && disNumOfPage === displen &&
                                <li className="page-item btnclr">
                                    <a onClick={() => {nextPagies(); scrollToTop();}} className="page-link" aria-label="Next">
                                        <i className="fas fa-angle-double-right"></i>
                                    </a>
                                </li>    
                            }
                            
                        </React.Fragment>
                    }
                    
                </ul>
            </nav>
        </div>
    )
}
