import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import Footer from "../components/Footer";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};
// Flight info
const FlightInfo = ({ flight }) => {
  return (
    <div>
      <div className="flight-top">
        <p>
          {" "}
          <i className="fa fa-plane"></i> Flight Number:{" "}
          <span className="data">{flight.printable_flight_number}</span>
        </p>
        <p>
          {" "}
          <i className="fa fa-suitcase"></i> Baggage:{" "}
          <span className="data">{flight.baggage_info.included.pc}</span>
        </p>
        <p>
          <i class="fa fa-map-o"></i>{" "}
          Leg miles: <span className="data">{flight.mileage}</span>
        </p>
      </div>
      <div className="flight-info">
        <div>
          <p>
            Departure Date: <span className="data">{flight.dep_date}</span>
          </p>
          <p>
            Departure Time: <span className="data">{flight.dep_time}</span>
          </p>
          <p>
            Departure Airport:{" "}
            <span className="data">{flight.dep_airport}</span>{" "}
            <span className="data">({flight.dep_airport_code})</span>
          </p>
        </div>
        <div>
          <p>
            Arrival Date: <span className="data">{flight.arr_date}</span>
          </p>
          <p>
            Arrival Time: <span className="data">{flight.arr_time}</span>
          </p>
          <p>
            Arrival Airport: <span className="data">{flight.arr_airport}</span>{" "}
            <span className="data">({flight.arr_airport_code})</span>
          </p>
        </div>
      </div>
    </div>
  );
};

// Hotel info
const HotelInfo = ({ hotel }) => {
  if (!hotel) {
    return null;
  }

  return (
    <div>
      <h5>Your Hotel Details:</h5>
      <div className="hotel-info">
        <div className="d-flex flex-column flex-md-row p-2">
          <img
            className="m-auto rounded rounded-2"
            src={hotel.thumb}
            width={200}
          />
          <div className="d-flex flex-column p-2">
            <p className="data">{hotel.name}</p>
            <p>
              <i className="fa fa-map-marker"></i> {hotel.hotel_address}
            </p>
          </div>
        </div>
        <div className="d-flex flex-column flex-sm-row justify-content-between p-2">
          <div>
            <p>
              <i className="fa fa-calendar-check-o"></i> Check-In Date:{" "}
              <span className="data">{hotel.hotel_date_from}</span>
            </p>
            <p>
              <i className="fa fa-calendar-times-o"></i>
              Check-Out Date:{" "}
              <span className="data">{hotel.hotel_date_to}</span>
            </p>
          </div>
          <div className="p-0">
            <p>
              <i class="fa fa-moon-o"></i> Nights:{" "}
              <span className="data">{hotel[0].hotel_nights}</span>
            </p>
            <p>
              <i class="fa fa-bed"></i>{" "}
              Room: <span className="data">{hotel[0].hotel_room}</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

const ThankYou = () => {
  const [orderData, setOrderData] = useState({});
  const [pageLoader, setPageLoader] = useState("block");

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  // Extract query parameters
  const tname = queryParams.get("tname");
  const orderid = queryParams.get("orderid");
  const lang = queryParams.get("lang");

  const test = () => {
    // Make an API call to fetch order data based on the order ID
    axios
      .get(
        `https://affiliateapi.militaryfares.com/front/order-data.php?tname=${tname}&orderid=${orderid}`
      )

      .then((response) => {
        setOrderData(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    test();
    setPageLoader("block");
    console.log(
      `https://affiliateapi.militaryfares.com/front/order-data.php?tname=${tname}&orderid=${orderid}`
    );
  }, []);

  return (
    <React.Fragment>
      <div className="thankyou">
        <div className="thankyou-section">
          <div className="thankyou-wrapper">
            <div className="sucess-message">
              <i className="fa fa-check" aria-hidden="true"></i>
            </div>
            <div className="top-content">
              <h2>Thank You!</h2>
              <h5>Your Order Details:</h5>
              <p>
                Order ID: <span className="data">{orderData.order}</span>
              </p>
              <p>
                <i class="fa fa-user"></i> Customer Name:{" "}
                <span className="data">{orderData.customer_name}</span>
              </p>
              <p>
                <i class="fa fa-envelope-o" aria-hidden="true"></i>{" "}
                Customer Email:{" "}
                <span className="data">{orderData.customer_email}</span>
              </p>
              {/* Include other order details here */}
              {/* Flights */}
              {orderData.flights && orderData.flights.length > 0 && (
                <>
                  <h5>Your Flight Details:</h5>
                  {orderData.flights.map((flight, index) => (
                    <>
                      <FlightInfo key={index} flight={flight} />
                      <hr className="divider" />
                    </>
                  ))}
                </>
              )}
              {/* Hotels */}
              <HotelInfo
                hotel={orderData.hotel_info ? orderData.hotel_info : null}
              />
            </div>
            <div className="bottom-content">
              <span>For any inquiries, please contact us at:</span>
              <div className="info">
                <i className="fa fa-envelope"></i>
                <span>Email: support@wannaflynow.com</span>
              </div>
            </div>
          </div>
        </div>
        <Footer className="thankyou" />
      </div>
    </React.Fragment>
  );
};

export default ThankYou;
