/**
  Departure City dropdown In Home and flight list page.
  Departure City data display by API.
**/

import React,{ useCallback } from 'react';
import { useSelector } from 'react-redux'

const HomeCities = ({ onCitySelect }) => {
    const cities = useSelector((state) => {
        if(state.allcities !== undefined){
            return state.allcities.cities;
        }
        return null
    });

    const selectCity = useCallback(e => {
        onCitySelect(e.target.name,e.target.value)
      }, [onCitySelect]);
     
    let count = 0;
    const renderList = cities.map((city) => {
        count = count + 1;
        const city_value = city.value;
        const city_name = city.name;

        return (
                <li key={count}>
                    <button className="cityitem" onClick={selectCity} name={city_name} value={city_value}>{city_name}</button>
                </li>
            );
    })

    return <React.Fragment>{renderList}</React.Fragment>;
}

export default HomeCities;