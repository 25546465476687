import React from "react";
import { Helmet } from "react-helmet"; // Can change tab name

const Header = (props) => {

    return (
        <React.Fragment>
        <Helmet>
            <title>{props.tabTitle}</title>
        </Helmet>
        <header className="navbar-dark navbar navbar-expand-lg  w-100">
            <div className="container">
                <div className="row w-100 align-items-center justify-space-between mx-auto">
                    <div className="col-sm-3 logo">
                        <a href="/"><img src={process.env.PUBLIC_URL + '/assets/images/logo.png'} alt="logo" /></a>                  
                    </div>
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <i className="fa fa-bars" aria-hidden="true"></i>
                    </button>  
                    <div className="col-sm-6 text-center navigation">
                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul className="navbar-nav">
                                {props.showmenu == true ?
                                    <li className="nav-item">
                                        <a className="nav-link" href="#">Flights</a>
                                    </li>: ''
                                  }
                                  {props.showmenu == true ?
                                    <li className="nav-item">
                                        <a className="nav-link" href="#">Hotels</a>
                                    </li> : ''
                                  }
                                    {/* <li className="nav-item">
                                        <a className="nav-link" href="#">About</a>
                                    </li> */}
                            </ul>
                        
                        </div>
                    </div>
                    <div className="col-sm-3 d-flex align-items-center  justify-content-end right-header">
                        <div className="language-switcher">
                            <a className="language-link" data-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample">
                                <span>US $ ENG</span> <img src={process.env.PUBLIC_URL + "/assets/images/us.png"} alt="Image Alternative text" title="Image Title" />
                            </a>
                            {/* <div className="collapse" id="collapseExample">
                                <div className="card card-body">
                                    <ul className="list">
                                        <li>
                                            <a title="German" href="#"><span className="right">GER</span>
                                                <img src="assets/images/de.png" alt="Image Alternative text" title="Image Title" />
                                            </a>
                                        </li>
                                        <li>
                                            <a title="Japanise" href="#"><span className="right">JAP</span>
                                                <img src="assets/images/jp.png" alt="Image Alternative text" title="Image Title" />
                                            </a>
                                        </li>
                                        <li>
                                            <a title="Italian" href="#"><span className="right">ITA</span>
                                                <img src="assets/images/it.png" alt="Image Alternative text" title="Image Title" />
                                            </a>
                                        </li>
                                        <li>
                                            <a title="French" href="#"><span className="right">FRE</span>
                                                <img src="assets/images/fr.png" alt="Image Alternative text" title="Image Title" />
                                            </a>
                                        </li>
                                        <li>
                                            <a title="Russian" href="#"><span className="right">RUS</span>
                                                <img src="assets/images/ru.png" alt="Image Alternative text" title="Image Title" />
                                            </a>
                                        </li>
                                        <li>
                                            <a title="Korean" href="#"><span className="right">KOR</span>
                                                <img src="assets/images/kr.png" alt="Image Alternative text" title="Image Title" />
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>                         */}
                        </div>                    
                    </div>                     
                </div>
            </div>
        </header>
                  </React.Fragment>   
    );
};

export default Header;