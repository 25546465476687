import React from 'react';

const Footer = (props) => {
    return (
        <div className={props.footerClass}>
            <footer className="footer">
                <div className="container d-flex flex-column justify-content-between height-100">
                    <div className="row">
                        {/*<div className="col-md-4 newsletter">*/}
                        <div className="col-md-4 offset-2 newsletter">
                            <h3>Our <span>Newsletter</span></h3>
                            <p>Never miss an offer subscribe to our newsletter</p>
                            <form id="email-form" name="email-form"  className="subscribe-form-flex">
                                <div className="subscribe-form-input-wrapper position-relative">
                                    <input type="email" className="subscribe-form-input" placeholder="you@email.com" id="Subscriber-Email-2" required="" />
                                    <div className="submit-button position-absolute">
                                    <input type="submit" value="" className="submit" />
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="col-md-4 call">
                            <h3>Need <span>Help</span></h3>
                            <p>Call us on:</p>
                            <a href="tel:+1 202 470 6334 ">+1 202 470 6334 </a>
                        </div>
                        {/*<div className="col-md-4 social">
                            <h3>Social <span>Media</span></h3>
                            <div className="social-links">
                                <ul>
                                    <li><a href="#"><i className="fa fa-twitter"></i></a></li>
                                    <li><a href="#"><i className="fa fa-facebook"></i></a></li>
                                    <li><a href="#"><i className="fa fa-pinterest"></i></a></li>
                                    <li><a href="#"><i className="fa fa-google-plus" aria-hidden="true"></i></a></li>
                                    <li><a href="#"><i className="fa fa-rss" aria-hidden="true"></i></a></li>
                                </ul>
                            </div>
                        </div>*/}
                    </div>
                </div>
            </footer>
        </div>
    );
};

export default Footer;