/**
  Passengers Summary Page room details.
  Html and data display by API,
  Display room details
**/

import React, {useEffect, useState} from 'react'
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { getHotelDetails } from '../redux/actions/dataActions';
import moment from 'moment';

const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
};

export const PassengersSummSingleRoom = (props) => {
    const showMenu = false;
    
    const hotel = useSelector((state) => state);
    const dispatch = useDispatch();

    let ddate = moment(props.depDate).format('MM/DD/YYYY');
    let rdate = moment(props.retDate).format('MM/DD/YYYY');

     
    //Get Hotel List
    const fetchHotel = async () => {
        await axios
        .get(`https://package.militaryfares.com/?method=info&lang=en&curr=USD&affiliate=wannafly&master=wannafly&flight[from][0]=${props.depFrom}&flight[from][1]=${props.retFrom}&flight[to][0]=${props.depTo}&flight[to][1]=${props.retTo}&flight[outdate][0]=${ddate}&flight[outdate][1]=${rdate}&flight[adult]=${props.adult}&flight[senior]=0&flight[youth]=0&flight[child]=${props.child}&flight[infant]=${props.infant}&flight[cabin]=Y&flight[trip]=round&hotel[cache_key]=${props.cache_key}&hotel[code]=${props.code}`)
        .then(response => { 
            if( typeof response != 'undefined' &&  response != null && response.status === 200){   
                       
                if (response.data.status != 'ERR') {
                    dispatch(getHotelDetails(response.data));
                }else{
                    dispatch(getHotelDetails());
                }                     
            } 
        })
        .catch((err) => {
            dispatch(getHotelDetails(''));
            console.log("Err", err);
        }); 
    };

    useEffect(() => {
        fetchHotel();
    },[]);


    //Hotel Details
    const hotelDetsils = useSelector((state) => {
        if(state.HotelDetail.hotelDetls !== undefined){
            return state.HotelDetail.hotelDetls.hotels;
        }   
        return null;
    });
     
    let hotelData = '';
    if(hotelDetsils != null){
        hotelData = hotelDetsils.map((item) => {
            let roomList = item.packs;
             
            return (
                Object.keys(roomList).map((item,i) => {
        
                    if(isFinite(String(item))){ //rooms in package and object (ex: packs-> object (ex: {0},{1}) -> roomlist)
                        return (
                        
                            Object.keys(roomList[item]).map((itm,m) => {
                                if(itm === props.p_key){
                                    let dataArr = roomList[item][itm];
                                    let roomName = '';
                                    
                                    if(dataArr.room != undefined){
                                        roomName =  dataArr.room.name;
                                    }
                                    
                                    //Rooms data
                                    return (
                                        <React.Fragment key={'hlpr'+i+m}>
                                           <li><i className="fa fa-bed" aria-hidden="true"></i>Rooms: <span>1 {roomName}</span></li>
                                        </React.Fragment>
                                    )
                                }
                                                    
                                 
                            })
                        )
                    }else{ //rooms in package (ex: packs-> roomlist)
                        
                        if(item === props.p_key){
                            let dataArr = roomList[item];
                            let roomName = '';
                        
                            let roomdetls = dataArr.rooms
                            if(roomdetls !== undefined){
                                roomName =  roomdetls[0].name;
                            }
                            if(roomName !== ''){
                                //Rooms data
                                return (
                                    <React.Fragment key={'hlpr'+i}>
                                        <li><i className="fa fa-bed" aria-hidden="true"></i>Rooms: <span>1 {roomName}</span></li>
                                    </React.Fragment>
                                )
                            }
                            
                        }
            
                    }
                })
            )
        }) 
    }
     


    return (
        <React.Fragment>
             {hotelData}
        </React.Fragment>
    )
}
