/**
  Hotel item display in hotel List page  
**/

import React from "react";
import { commanConst } from "../redux/constants/commanConst";

export const HotelItem = ({
  detailUrl,
  totalHotels,
  hotelsitems,
  hotelsSorting,
  hotelsOrdering,
  sortingData,
  ordering,
}) => {
  let total_hotels = "0";
  let hotelDataList = "";
  if (hotelsitems != undefined) {
    hotelDataList = hotelsitems.map((hotel, i) => {
      if (parseInt(hotel.stars) >= 0) {
        let disfacil = [];
        let morefacil = [];

        let hotel_name = hotel.name;
        let address =
          hotel.location.address +
          ", " +
          hotel.location.city +
          ", " +
          hotel.location.country;
        let package_price = hotel.currency + " $" + hotel.total_package_price;
        let thumb_img = "";
        if (hotel.thumb !== null) {
          thumb_img = hotel.thumb;
        } else {
          thumb_img = process.env.PUBLIC_URL + "/assets/images/noimage.jpg";
        }

        let final_rating = hotel.stars;
        let floor_rating = Math.floor(hotel.stars);
        let ceil_rating = Math.ceil(hotel.stars);
        let half_rating = final_rating - floor_rating;
        let not_sel_star = 5 - ceil_rating;
        let facilities = hotel.facilities;
        let c_key = hotel.c_key;
        let hid = hotel.hid;
        let totalnight = hotel.package_nights;
        let totaldays = hotel.package_days;

        if (facilities != null) {
          //differentiate facilities display and more list
          const renderList = facilities.map((item) => {
            let modiname = item
              .replace(/[^a-zA-Z ]/g, "")
              .replace(/ /g, "")
              .toLowerCase(); //remove space and special charectes
            if (commanConst.DISPLAYFACILITIES.indexOf(modiname) == -1) {
              morefacil.push(item); // More facilities
            } else {
              disfacil.push(item); //Display facilities
            }
          });
        }

        //remove duplicate value from array
        var disfacilits = [];
        disfacilits = disfacil.filter(function (item, pos, self) {
          return self.indexOf(item) == pos;
        });

        //remove duplicate value from array
        var morefacilits = [];
        morefacilits = morefacil.filter(function (item, pos, self) {
          return self.indexOf(item) == pos;
        });

        //Display facilities list
        let dispfacilitis = "";
        if (disfacilits.length != 0) {
          dispfacilitis = disfacilits.map((item, i) => {
            let modiname = item
              .replace(/[^a-zA-Z ]/g, "")
              .replace(/ /g, "")
              .toLowerCase(); //remove space and special charectes
            let disfacility =
              commanConst.DISPLAYFACILITIESICON.facility[modiname];
            return (
              <li key={i + "falty"}>
                <i className={disfacility} aria-hidden="true"></i>
                {item}
              </li>
            );
          });
        }

        //More facilities list
        let morefacilitis = "";
        if (morefacilits.length != 0) {
          morefacilitis = morefacilits.map((item, i) => {
            return <li key={i + "mrfacty"}>{item}</li>;
          });
        }

        //star rating not selected
        const startnotsel = {
          color: "#ddd",
        };

        //Display start rating hotel wise
        const starRaring = () => {
          let startitem = [];
          {
            for (let k = 0; k < floor_rating; k++) {
              startitem.push(
                <React.Fragment key={k + "fillsr"}>
                  <label
                    aria-label="0.5 stars"
                    className="rating__label rating__label--half"
                    htmlFor="rating2-05"
                  >
                    <i
                      className="rating__icon rating__icon--star fa fa-star-half"
                      aria-hidden="true"
                    ></i>
                  </label>
                  <input
                    className="rating__input"
                    name="rating2"
                    id="rating2-05"
                    type="radio"
                  />
                  <label
                    aria-label="1 star"
                    className="rating__label"
                    htmlFor="rating2-10"
                  >
                    <i
                      className="rating__icon rating__icon--star fa fa-star"
                      aria-hidden="true"
                    ></i>
                  </label>
                  <input
                    className="rating__input"
                    name="rating2"
                    id="rating2-10"
                    type="radio"
                  />
                </React.Fragment>
              );
            }
            if (half_rating != 0) {
              startitem.push(
                <React.Fragment key={"hlfrat"}>
                  <label
                    aria-label="0.5 stars"
                    className="rating__label rating__label--half"
                    htmlFor="rating2-05"
                  >
                    <i
                      className="rating__icon rating__icon--star fa fa-star-half"
                      aria-hidden="true"
                    ></i>
                  </label>
                  <input
                    className="rating__input"
                    name="rating2"
                    id="rating2-05"
                    type="radio"
                  />
                  <label
                    aria-label="1 star"
                    className="rating__label"
                    htmlFor="rating2-10"
                  >
                    <i
                      className="rating__icon rating__icon--star fa fa-star"
                      aria-hidden="true"
                      style={startnotsel}
                    ></i>
                  </label>
                  <input
                    className="rating__input"
                    name="rating2"
                    id="rating2-10"
                    type="radio"
                  />
                </React.Fragment>
              );
            }

            if (not_sel_star != 0) {
              for (let k = 0; k < not_sel_star; k++) {
                startitem.push(
                  <React.Fragment key={k + "ntselsr"}>
                    <label
                      aria-label="0.5 stars"
                      className="rating__label rating__label--half"
                      htmlFor="rating2-05"
                    >
                      <i
                        className="rating__icon rating__icon--star fa fa-star-half"
                        aria-hidden="true"
                        style={startnotsel}
                      ></i>
                    </label>
                    <input
                      className="rating__input"
                      name="rating2"
                      id="rating2-05"
                      type="radio"
                    />
                    <label
                      aria-label="1 star"
                      className="rating__label"
                      htmlFor="rating2-10"
                    >
                      <i
                        className="rating__icon rating__icon--star fa fa-star"
                        aria-hidden="true"
                        style={startnotsel}
                      ></i>
                    </label>
                    <input
                      className="rating__input"
                      name="rating2"
                      id="rating2-10"
                      type="radio"
                    />
                  </React.Fragment>
                );
              }
            }
          }
          return startitem;
        };

        //Display Default Image
        const addDefaultSrc = (ev) => {
          ev.target.src = process.env.PUBLIC_URL + "/assets/images/noimage.jpg";
        };

        return (
          <div key={i} className="hotel-list-section holiday city-display">
            <div className="hotel-list bg-white">
              <div className="d-flex flex-wrap">
                <div className="col-md-4 position-relative hotel-left">
                  <div className="hotel-image">
                    <a href={`${detailUrl}/${c_key}/${hid}`}>
                      <img
                        src={thumb_img}
                        onError={addDefaultSrc}
                        className="img-fluid"
                      />
                    </a>
                  </div>
                  {/* <div className="inclusive">Best Deal</div> */}
                </div>
                <div className="col-md-8 hotel-right">
                  <div className="d-flex flex-wrap hotel-details">
                    <div className="col-md-7 p-0">
                      <div className="d-flex align-items-center flex-wrap">
                        <div className="rating">
                          <div id="half-stars-example">
                            <div className="rating-group">
                              <input
                                className="rating__input rating__input--none"
                                name="rating2"
                                id="rating2-0"
                                defaultValue="0"
                                type="radio"
                              />
                              <label
                                aria-label="0 stars"
                                className="rating__label"
                                htmlFor="rating2-0"
                              >
                                &nbsp;
                              </label>

                              {/* Rating Start */}
                              {final_rating !== 0 ? starRaring() : ""}
                            </div>
                          </div>
                        </div>
                        {/* Rating In Number  */}
                        {final_rating !== 0 ? (
                          <span className="score">{final_rating}</span>
                        ) : (
                          ""
                        )}
                      </div>

                      <h5>
                        <a href={`${detailUrl}/${c_key}/${hid}`}>
                          {hotel_name}
                        </a>
                      </h5>
                      <p className="location">
                        <i className="fas fa-map-marker-alt"></i>
                        {address}
                      </p>
                    </div>
                  </div>

                  <div className="hotel-services destFacility">
                    <ul className="p-0 m-0 d-flex flex-wrap">
                      {/* Display Facilities */}
                      {dispfacilitis}

                      {/* Desktop Device More */}
                      <li className="dropdown more destmore">
                        {morefacilitis.length != 0 ? (
                          <React.Fragment>
                            <button
                              className="dropdown-toggle"
                              type="button"
                              id="dropdownMenuButton1"
                              data-toggle="dropdown"
                              aria-expanded="false"
                            >
                              more+
                            </button>
                            <ul
                              className="dropdown-menu morefacilities"
                              aria-labelledby="dropdownMenuButton1"
                              x-placement="bottom-start facilitieslist"
                            >
                              {/* More Facilities  */}
                              {morefacilitis}
                            </ul>
                          </React.Fragment>
                        ) : (
                          ""
                        )}
                      </li>
                    </ul>

                    {/* Mobile Device More */}
                    {morefacilitis.length != 0 ? (
                      <React.Fragment>
                        <div className="dropdown more morefacilitytrig">
                          <button
                            className="dropdown-toggle"
                            type="button"
                            id="dropdownMenuButton1"
                            data-toggle="dropdown"
                            aria-expanded="false"
                          >
                            more+
                          </button>
                          <ul
                            className="dropdown-menu morefacilities"
                            aria-labelledby="dropdownMenuButton1"
                            x-placement="bottom-start facilitieslist"
                          >
                            {/* More Facilities */}
                            {morefacilitis}
                          </ul>
                        </div>
                      </React.Fragment>
                    ) : (
                      ""
                    )}
                  </div>

                  <div className="d-flex flex-wrap justify-content-between align-items-end">
                    <div className="flight-dest">
                      From
                      <span className="flight-price">{package_price}</span>
                      <p>
                        Total package price {totaldays} days /{totalnight}{" "}
                        nights’s flight and taxes included
                      </p>
                    </div>
                    <div className="check-price">
                      <a href={`${detailUrl}/${c_key}/${hid}`}>
                        SELECT THIS HOTEL
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      }
    });
  }
  return (
    <React.Fragment>
      <div className="d-flex flex-wrap justify-content-between align-items-center results">
        <div className="results-found">
          <h5>
            <i className="fas fa-hotel" aria-hidden="true"></i>Accommodation
          </h5>
          Choose from our <strong>{totalHotels}</strong> available hotels for
          your stay.
        </div>
        <div className="d-flex align-items-center refine-filter-section">
          <button
            data-toggle="modal"
            data-target="#modal_aside_right"
            className="filter-toggle"
            type="button"
          >
            Refine Filters{" "}
            <i className="fa fa-chevron-right" aria-hidden="true"></i>
          </button>
          <div className="dropdown price-dropdown">
            <button
              className="btn dropdown-toggle"
              type="button"
              id="dropdownMenuButton"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              {hotelsSorting}
            </button>
            <div
              className="dropdown-menu filtlist"
              aria-labelledby="dropdownMenuButton"
            >
              <li onClick={() => sortingData("Rating")}>Rating</li>
              <li onClick={() => sortingData("Price")}>Price</li>
              <li onClick={() => sortingData("Name")}>Name</li>
            </div>
          </div>
          <div className="dropdown sorting">
            {hotelsOrdering == "ASC" ? (
              <button
                onClick={() => ordering("DESC")}
                className="btn dropdown-toggle"
                type="button"
                id="dropdownMenuButton"
              >
                <i className="fas fa-sort-amount-down"></i>
              </button>
            ) : (
              <button
                onClick={() => ordering("ASC")}
                className="btn dropdown-toggle"
                type="button"
                id="dropupMenuButton"
              >
                <i className="fas fa-sort-amount-up"></i>
              </button>
            )}
          </div>
        </div>
      </div>
      {hotelDataList}
    </React.Fragment>
  );
};
