/**
  Rooms display in hotel Details page  
**/

import React, {useState} from 'react';
import { HotelRoomsData } from './HotelRoomsData';
import { MULTI_ROOM_SUPPLIER } from '../redux/constants/commanConst';

const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
};

export const HotelRooms = ({flightUrl,service, roomList}) => {

    let numberOfRoom = 0;
    const [modalCancellation, setModalCancellation] = useState(false);
    const [selectedRoom, setSelectedRoom] = useState([])
    const totalRoom = JSON.parse(localStorage.getItem("roomsWisePess")).length;
     
    const openModalCancellation = () => {
        setModalCancellation(true);
    }

    const closeModalCancellation = () => {
        setModalCancellation(false);
    }

    const bookRoom = (inx) => {
        let MultiSel = selectedRoom.concat(inx); 
        setSelectedRoom(MultiSel);
    }
    
    Object.keys(roomList).map((item,i) => {
        
        if(isFinite(String(item))){ //rooms in package and object (ex: packs-> object (ex: {0},{1}) -> roomlist)
            return (
                Object.keys(roomList[item]).map((itm,m) => {
                    let dataArr = roomList[item][itm];
                    if(dataArr.room != undefined){
                        numberOfRoom++;
                    }
                })
            )
        }else{ //rooms in package (ex: packs-> roomlist)
            let dataArr = roomList[item];
            if(dataArr.rooms != undefined){
                numberOfRoom++;
            }
             
        }
    })
     
    if((MULTI_ROOM_SUPPLIER.findIndex(item => service.toLowerCase() === item.toLowerCase())) !== -1){
        //Redirect to flight list after multiple room select
        if(selectedRoom.length === totalRoom || selectedRoom.length === numberOfRoom){
            window.location.href = flightUrl+"/"+selectedRoom[0]; 
        }
         
    }else{
        if(selectedRoom.length === 1){
            window.location.href = flightUrl+"/"+selectedRoom[0]; 
        }
    }
    
     
    //Rooms Data
    const roomResult = Object.keys(roomList).map((item,i) => {
        
        if(isFinite(String(item))){ //rooms in package and object (ex: packs-> object (ex: {0},{1}) -> roomlist)
             
            return (
            
                Object.keys(roomList[item]).map((itm,m) => {
                    
                     
                    let dataArr = roomList[item][itm];
                    let totalPrice = dataArr.currency +' $'+ dataArr.total_price;
                    
                    let totalPass = 0;
                    if(dataArr.summary_passenger != undefined){
                        totalPass = parseInt(dataArr.summary_passenger.adult) + parseInt(dataArr.summary_passenger.child) + parseInt(dataArr.summary_passenger.infant);
                    }
                    // hotelTotalPass += totalPass;
                    let roomName = '';
                    let meal = '';
                    let roomImg = '';
                    if(dataArr.room != undefined){
                        roomName =  dataArr.room.name;
                        meal =  dataArr.room.meal.name;
    
                        
                        if(dataArr.room.thumb != undefined){
                            roomImg = dataArr.room.thumb;
                        }else{
                            //roomImg = process.env.PUBLIC_URL +"/assets/images/noimage.jpg"
                        }
                    }
                     
                    let cancellationPolicy = '';
                    if(dataArr.ld != undefined){
                        cancellationPolicy = Object.keys(dataArr.ld['cnx_fee']).map((canp,j) => {
                            let canFrom = '';
                            let canTo = '';
                            if(dataArr.ld['cnx_fee'][canp].from != undefined){
                               canFrom = dataArr.ld['cnx_fee'][canp].from +' ('+ dataArr.ld['cnx_fee'][canp].from_time +')';
                            }
                            if(dataArr.ld['cnx_fee'][canp].to != undefined){
                               canTo = ' to ' + dataArr.ld['cnx_fee'][canp].to +' ('+ dataArr.ld['cnx_fee'][canp].to_time +')';
                            }
                            let canVal = dataArr.ld['cnx_fee'][canp].value;
       
                            return (                         
                               <div key={i+'_'+j} className='py-2'>
                                   <div>from <strong>{canFrom + canTo}</strong> *</div>
                                   <div>
                                       <span className="text-danger">The hotel will charge you {canVal} of amount paid.</span>
                                   </div>
                               </div>                         
                            )      
                       });
                    }
                     
                    
                    //Rooms data
                    return (
                        <React.Fragment key={'hlpr'+i+m}>
                            <HotelRoomsData sks={i+m} roomImg={roomImg} roomName={roomName} cancellationPolicy={cancellationPolicy} totalPass={totalPass} meal={meal} totalPrice={totalPrice} flightUrl={flightUrl} roomkey={itm} selectedRoom={selectedRoom} bookRoom={bookRoom}/>
                        </React.Fragment>
                    )                    
                     
                })
            )
        }else{ //rooms in package (ex: packs-> roomlist)
            
            let dataArr = roomList[item];
            let totalPrice = dataArr.currency +' $'+ dataArr.total_price;
            
            let totalPass = 0;
            if(dataArr.summary_passenger != undefined){
                totalPass = dataArr.summary_passenger.adult + dataArr.summary_passenger.child + dataArr.summary_passenger.infant;
            }
            // hotelTotalPass += totalPass;
            let roomName = '';
            let meal = '';
            let roomImg = '';
            let roomdetls = dataArr.rooms
            if(roomdetls != undefined){
                roomName =  roomdetls[0].name;
                meal =  roomdetls[0].meal.name;
        
                if(roomdetls.thumb != undefined){
                    roomImg = roomdetls.thumb;
                }else{
                    //Remove default picture when is not room image
                    //roomImg = process.env.PUBLIC_URL +"/assets/images/noimage.jpg";
                }
                 
            }

            let cancellationPolicy = '';

            
            //Rooms data
            return (
                 
                <React.Fragment key={'hlpr'+i}>
                    {roomdetls != undefined
                    ? <HotelRoomsData sks={i} roomImg={roomImg} roomName={roomName} cancellationPolicy={cancellationPolicy} totalPass={totalPass} meal={meal} totalPrice={totalPrice} flightUrl={flightUrl} roomkey={item} selectedRoom={selectedRoom} bookRoom={bookRoom}/>
                    : ''
                    }
                </React.Fragment>
                
            )
        }
    })
    // totalGuest(hotelTotalPass);
    return (<React.Fragment>{roomResult}</React.Fragment>)
}
