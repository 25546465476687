/**
  Hotel List Page.
  Html and data display by API,
  User can filter data by left section Search, Star rating and search redius
  Also can filter data by right top sorting Hotel name, price and rating
  Can see all facilities by click More+ link
  User can see all hotel by pagination, every page display 10 hotels  

**/

import React, { useEffect, useState } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { getHotels } from "../redux/actions/dataActions";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { useParams } from "react-router-dom";
import moment from "moment";
import { HotelItem } from "./HotelItem";
import { ItemPagination } from "./ItemPagination";
import { commanConst } from "../redux/constants/commanConst";

const HotelList = () => {
  const {
    a,
    budget,
    depacityName,
    depacity,
    destinationName,
    destination,
    destLat,
    destLon,
    depadate,
    returndate,
    passengers,
    totalAdult,
    totalChild,
    totalInfant,
    totalYouth,
  } = useParams();

  const hotels = useSelector((state) => state);
  const dispatch = useDispatch();
  const showMenu = false;

  //default Page number
  let disNumOfPage = 5;
  let ustartPage = "";
  let uendPage = "";

  let ddate = moment(depadate, "MM-DD-YYYY").format("MM/DD/YYYY");
  let rdate = moment(returndate, "MM-DD-YYYY").format("MM/DD/YYYY");

  //Display Dates
  let disdepadate = moment(depadate, "MM-DD-YYYY").format("ddd, MMM DD");
  let disreturndate = moment(returndate, "MM-DD-YYYY").format("ddd, MMM DD");

  let detailUrl = `/hotel-details/${a}/${budget}/${depacityName}/${depacity}/${destinationName}/${destination}/${destLat}/${destLon}/${depadate}/${returndate}/${passengers}/${totalAdult}/${totalChild}/${totalInfant}/${totalYouth}`;

  //save to sate
  const [hotelRedius, setHotelRedius] = useState("");
  //  const [hotelRating, setHotelRating] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemPerPage] = useState(10);
  const [hotelsSorting, setHotelsSorting] = useState("Price");
  const [hotelsOrdering, setHotelsOrdering] = useState("ASC");
  const [hotelsLoader, setHotelsLoader] = useState("block");
  const [hotelsSearch, setHotelsSearch] = useState("");
  const [startPage, setStartPage] = useState(1);
  const [endPage, setEndPage] = useState(5);
  const [starRatings, setStarRatings] = useState({
    hotelStarfv: "",
    hotelStarfr: "",
    hotelStarth: "",
    hotelStartw: "",
    hotelStaron: "",
    hotelStarNa: "",
    hotelStarfvCh: false,
    hotelStarfrCh: false,
    hotelStarthCh: false,
    hotelStartwCh: false,
    hotelStaronCh: false,
    hotelStarNaCh: false,
  });
  const {
    hotelStarfv,
    hotelStarfr,
    hotelStarth,
    hotelStartw,
    hotelStaron,
    hotelStarNa,
    hotelStarfvCh,
    hotelStarfrCh,
    hotelStarthCh,
    hotelStartwCh,
    hotelStaronCh,
    hotelStarNaCh,
  } = starRatings;
  const [hotelRadius, setHotelRadius] = useState({
    radiusHalf: false,
    radiusOne: false,
    radiusTwo: false,
    radiusFive: false,
  });
  const { radiusHalf, radiusOne, radiusTwo, radiusFive } = hotelRadius;

  //rooms data
  let roomData = JSON.parse(localStorage.getItem("roomsWisePess"));
  let chiledAgeData = JSON.parse(localStorage.getItem("childAgeManage"));
  let totalRooms = roomData.length;

  let adultlist = "";
  let childlist = "";
  let childAge = "";

  //room wise passengers
  Object.entries(roomData).map((item, num) => {
    adultlist += `&hotel[adult][${num}]=${item[1].adults}`;
    childlist += `&hotel[child][${num}]=${item[1].children}`;
  });

  //Room wise chiled age
  chiledAgeData.map((item, r) => {
    item.ages.map((itm, i) => {
      childAge += `&hotel[child_age][${r}][${i}]=${itm.age}`;
    });
  });

  //Get Hotel List
  const fetchHotels = async () => {
    await axios
      .get(
        `https://package.militaryfares.com/?method=search&lang=en&curr=USD&affiliate=${a}&master=${a}&flight[from][0]=${depacity}&flight[from][1]=${destination}&flight[to][0]=${destination}&flight[to][1]=${depacity}&flight[outdate][0]=${ddate}&flight[outdate][1]=${rdate}&flight[adult]=${totalAdult}&flight[senior]=0&flight[youth]=${totalYouth}&flight[child]=${totalChild}&flight[infant]=${totalInfant}&flight[cabin]=Y&flight[trip]=round&hotel[latitude]=${destLat}&hotel[longitude]=${destLon}&hotel[outdate][0]=${ddate}&hotel[outdate][1]=${rdate}&hotel[rooms]=${totalRooms}${adultlist}${childlist}${childAge}&hotel[radius]=${hotelRedius}&hotel[stars]=&hotel[nationality]=US&hotel[ids]=&hotel[db]=&price=${budget}`
      )
      .then((response) => {
        if (
          typeof response !== "undefined" &&
          response !== null &&
          response.status === 200
        ) {
          if (response.data.status != "ERR") {
            dispatch(getHotels(response.data));
          } else {
            dispatch(getHotels(""));
          }
          setHotelsLoader("none");
        }
      })
      .catch((err) => {
        dispatch(getHotels(""));
        setHotelsLoader("none");

        console.log("Err", err);
      });
  };

  useEffect(() => {
    dispatch(getHotels(""));
    setHotelsLoader("block");
    fetchHotels();
    console.log(
      `https://package.militaryfares.com/?method=search&lang=en&curr=USD&affiliate=${a}&master=${a}&flight[from][0]=${depacity}&flight[from][1]=${destination}&flight[to][0]=${destination}&flight[to][1]=${depacity}&flight[outdate][0]=${ddate}&flight[outdate][1]=${rdate}&flight[adult]=${totalAdult}&flight[senior]=0&flight[youth]=${totalYouth}&flight[child]=${totalChild}&flight[infant]=${totalInfant}&flight[cabin]=Y&flight[trip]=round&hotel[latitude]=${destLat}&hotel[longitude]=${destLon}&hotel[outdate][0]=${ddate}&hotel[outdate][1]=${rdate}&hotel[rooms]=${totalRooms}${adultlist}${childlist}${childAge}&hotel[radius]=${hotelRedius}&hotel[stars]=&hotel[nationality]=US&hotel[ids]=&hotel[db]=&price=${budget}`
    );
  }, []);

  //Map url
  const mapurl =
    "https://www.google.com/maps/embed/v1/place?key=" +
    commanConst.GOOGLE_KEY +
    "&q=" +
    destinationName +
    "," +
    destination;

  //Default hotel data
  let hotelsitemsdata = useSelector((state) => {
    if (state.allhotels.hotels !== undefined) {
      return state.allhotels.hotels.hotels;
    }
    return null;
  });

  //search hotel name
  if (hotelsSearch != "") {
    hotelsitemsdata = hotelsitemsdata.filter((hotel) =>
      hotel.name.toLowerCase().includes(hotelsSearch.toLowerCase())
    );
  }

  let hotelsitems = [];
  let hotelRatingArr = [];

  //Rating filtering data
  if (
    hotelsitemsdata != null &&
    (hotelStarfv !== "" ||
      hotelStarfr !== "" ||
      hotelStarth !== "" ||
      hotelStartw !== "" ||
      hotelStaron !== "" ||
      hotelStarNa !== "")
  ) {
    // For rating Ex: 5,4,3 etc..
    hotelsitemsdata.map((hotel) => {
      let fstars = parseInt(hotel.stars);
      let fivestar = parseInt(hotelStarfv);
      let fourstar = parseInt(hotelStarfr);
      let threestar = parseInt(hotelStarth);
      let twostar = parseInt(hotelStartw);
      let onestar = parseInt(hotelStaron);
      let zerostart = parseInt(hotelStarNa);

      if (
        fstars === fivestar ||
        fstars === fourstar ||
        fstars === threestar ||
        fstars === twostar ||
        fstars === onestar ||
        fstars === zerostart
      ) {
        hotelRatingArr.push(hotel);
      }
    });

    hotelsitemsdata = hotelRatingArr;
  }

  //Redius filtering data
  if (hotelRedius !== "" && hotelsitemsdata !== undefined) {
    // For rating Ex: 0.5,1,2 etc..
    hotelsitemsdata.map((hotel) => {
      let distance = hotel.location.distance;
      if (distance <= hotelRedius.split(" ").join("")) {
        hotelsitems.push(hotel);
      }
    });

    hotelsitemsdata = hotelsitems;
  }

  let totalHotels = 0;
  let currentHotels = "";
  let ttpage = "";
  if (hotelsitemsdata != undefined) {
    totalHotels = hotelsitemsdata.length;

    if (hotelsSorting === "Rating") {
      //Sorting base on rating
      hotelsitemsdata.sort(function (a, b) {
        if (hotelsOrdering === "ASC") {
          return parseFloat(a.stars) - parseFloat(b.stars);
        } else {
          return parseFloat(b.stars) - parseFloat(a.stars);
        }
      });
    } else if (hotelsSorting === "Price") {
      //sorting base on price
      hotelsitemsdata.sort(function (a, b) {
        if (hotelsOrdering === "ASC") {
          return (
            parseFloat(a.total_package_price) -
            parseFloat(b.total_package_price)
          );
        } else {
          return (
            parseFloat(b.total_package_price) -
            parseFloat(a.total_package_price)
          );
        }
      });
    } else {
      //sorting base on name
      hotelsitemsdata.sort(function (a, b) {
        if (hotelsOrdering === "ASC") {
          return a.name.localeCompare(b.name);
        } else {
          return b.name.localeCompare(a.name);
        }
      });
    }

    //Get current posts
    const indexOfLastHotel = currentPage * itemPerPage;
    const indexOfFirstHotel = indexOfLastHotel - itemPerPage;
    currentHotels = hotelsitemsdata.slice(indexOfFirstHotel, indexOfLastHotel);

    //Total pages
    ttpage = Math.ceil(hotelsitemsdata.length / itemPerPage);

    if (ttpage < endPage) {
      setEndPage(ttpage);
    }
  }

  //Previous Pages
  const prevPagies = () => {
    //For manage last pages group
    let updtendpg = endPage;
    if (endPage - startPage != disNumOfPage - 1) {
      updtendpg = endPage + (disNumOfPage - 1 - (endPage - startPage));
    }

    ustartPage = startPage - disNumOfPage;
    uendPage = updtendpg - disNumOfPage;
    if (ustartPage >= 1) {
      setStartPage(ustartPage);
      setEndPage(uendPage);
      setCurrentPage(ustartPage);
    }
  };

  //Next Pages
  const nextPagies = () => {
    if (ttpage - endPage >= 1) {
      ustartPage = startPage + disNumOfPage;
      uendPage = endPage + disNumOfPage;

      setStartPage(ustartPage);
      setEndPage(uendPage);
      setCurrentPage(ustartPage);
    }
  };

  //Hotel Search
  const HotelSearchData = (e) => {
    setHotelsSearch(e.target.value);
    setCurrentPage(1);
    setStartPage(1);
    setEndPage(disNumOfPage);
  };

  //Filter date Base on start
  const HotelRatingData = (e) => {
    let chedValue = e.target.value;
    let chedName = e.target.name;
    let chedNameCh = e.target.name + "Ch";
    let isChecked = e.target.checked;
    setCurrentPage(1);
    setStartPage(1);
    setEndPage(disNumOfPage);

    if (isChecked) {
      setStarRatings((prev) => ({ ...prev, [chedName]: chedValue }));
      setStarRatings((prev) => ({ ...prev, [chedNameCh]: true }));
    } else {
      setStarRatings((prev) => ({ ...prev, [chedName]: "" }));
      setStarRatings((prev) => ({ ...prev, [chedNameCh]: false }));
    }
  };

  //Filter data Base on radius
  const HotelRediusData = (e) => {
    setHotelRadius((prev) => ({ ...prev, ["radiusHalf"]: false }));
    setHotelRadius((prev) => ({ ...prev, ["radiusOne"]: false }));
    setHotelRadius((prev) => ({ ...prev, ["radiusTwo"]: false }));
    setHotelRadius((prev) => ({ ...prev, ["radiusFive"]: false }));
    if (e.target.value === "0.5") {
      if (e.target.checked) {
        setHotelRadius((prev) => ({ ...prev, ["radiusHalf"]: true }));
      } else {
        setHotelRadius((prev) => ({ ...prev, ["radiusHalf"]: false }));
      }
    }
    if (e.target.value === "1") {
      if (e.target.checked) {
        setHotelRadius((prev) => ({ ...prev, ["radiusOne"]: true }));
      } else {
        setHotelRadius((prev) => ({ ...prev, ["radiusOne"]: false }));
      }
    }
    if (e.target.value === "2") {
      if (e.target.checked) {
        setHotelRadius((prev) => ({ ...prev, ["radiusTwo"]: true }));
      } else {
        setHotelRadius((prev) => ({ ...prev, ["radiusTwo"]: false }));
      }
    }
    if (e.target.value === "5") {
      if (e.target.checked) {
        setHotelRadius((prev) => ({ ...prev, ["radiusFive"]: true }));
      } else {
        setHotelRadius((prev) => ({ ...prev, ["radiusFive"]: false }));
      }
    }
    if (e.target.checked) {
      setHotelRedius(e.target.value);
    } else {
      setHotelRedius("");
    }

    setCurrentPage(1);
    setStartPage(1);
    setEndPage(disNumOfPage);
  };

  //Filter by ordering
  const ordering = (e) => {
    setHotelsOrdering(e);
    setCurrentPage(1);
    setStartPage(1);
    setEndPage(disNumOfPage);
  };

  //sorting Data using price,rating and name
  const sortingData = (e) => {
    setHotelsSorting(e);
    setCurrentPage(1);
    setStartPage(1);
    setEndPage(disNumOfPage);
  };

  //Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  //Previous hotel list Page
  const prevPaginate = () => {
    //previous page with pages number
    if (currentPage == startPage && currentPage != 1) {
      let prevpage = currentPage - 1;
      setCurrentPage(prevpage);
      prevPagies();
    }

    if (currentPage > 1) {
      let prevpage = currentPage - 1;
      setCurrentPage(prevpage);
    }
  };

  //Next hotel list page
  const nextPaginate = (lastPage) => {
    //Next page with pages number
    if (currentPage == lastPage && currentPage != ttpage) {
      let nextpage = currentPage + 1;
      setCurrentPage(nextpage);
      nextPagies();
    }

    if (currentPage < lastPage) {
      let nextpage = currentPage + 1;
      setCurrentPage(nextpage);
    }
  };

  //Reset all filter
  const resetFilter = () => {
    window.location.reload();
  };

  //Filter By Search
  const SerachFilter = (i) => {
    return (
      <React.Fragment key={"srch" + i}>
        <form className="d-flex">
          <input
            onChange={(val) => HotelSearchData(val)}
            className="form-control"
            type="search"
            placeholder="Search here"
            aria-label="Search"
          />
        </form>
      </React.Fragment>
    );
  };

  //Rating Filter
  const ratingFilter = (i) => {
    return (
      <React.Fragment key={"ratng" + i}>
        <label className="control control-checkbox full-width ratinglbl">
          <i className="fas fa-star"></i>
          <i className="fas fa-star"></i>
          <i className="fas fa-star"></i>
          <i className="fas fa-star"></i>
          <i className="fas fa-star"></i>
          {/* <input type="radio" defaultValue="5" name="rating" onChange={HotelRatingData}/> */}
          <input
            type="checkbox"
            defaultValue="5"
            name="hotelStarfv"
            onChange={HotelRatingData}
            checked={hotelStarfvCh}
          />
          <div className="control_indicator"></div>
        </label>

        <label className="control control-checkbox full-width ratinglbl">
          <i className="fas fa-star"></i>
          <i className="fas fa-star"></i>
          <i className="fas fa-star"></i>
          <i className="fas fa-star"></i>
          <i className="far fa-star"></i>
          {/* <input  type="radio" defaultValue="4" name="rating" onChange={HotelRatingData} /> */}
          <input
            type="checkbox"
            defaultValue="4"
            name="hotelStarfr"
            onChange={HotelRatingData}
            checked={hotelStarfrCh}
          />
          <div className="control_indicator"></div>
        </label>

        <label className="control control-checkbox full-width ratinglbl">
          <i className="fas fa-star"></i>
          <i className="fas fa-star"></i>
          <i className="fas fa-star"></i>
          <i className="far fa-star"></i>
          <i className="far fa-star"></i>
          {/* <input type="radio" defaultValue="3" name="rating" onChange={HotelRatingData} /> */}
          <input
            type="checkbox"
            defaultValue="3"
            name="hotelStarth"
            onChange={HotelRatingData}
            checked={hotelStarthCh}
          />
          <div className="control_indicator"></div>
        </label>

        <label className="control control-checkbox full-width ratinglbl">
          <i className="fas fa-star"></i>
          <i className="fas fa-star"></i>
          <i className="far fa-star"></i>
          <i className="far fa-star"></i>
          <i className="far fa-star"></i>
          {/* <input type="radio" defaultValue="2" name="rating" onChange={HotelRatingData}  /> */}
          <input
            type="checkbox"
            defaultValue="2"
            name="hotelStartw"
            onChange={HotelRatingData}
            checked={hotelStartwCh}
          />
          <div className="control_indicator"></div>
        </label>

        <label className="control control-checkbox">
          <i className="fas fa-star"></i>
          <i className="far fa-star"></i>
          <i className="far fa-star"></i>
          <i className="far fa-star"></i>
          <i className="far fa-star"></i>
          {/* <input type="radio" defaultValue="1" name="rating" onChange={HotelRatingData} /> */}
          <input
            type="checkbox"
            defaultValue="1"
            name="hotelStaron"
            onChange={HotelRatingData}
            checked={hotelStaronCh}
          />
          <div className="control_indicator"></div>
        </label>

        <label className="control control-checkbox">
          <span>N/A</span>
          {/* <input type="radio" defaultValue="" name="rating" onChange={HotelRatingData} checked={true}/> */}
          <input
            type="checkbox"
            defaultValue="0"
            name="hotelStarNa"
            onChange={HotelRatingData}
            checked={hotelStarNaCh}
          />
          <div className="control_indicator"></div>
        </label>
      </React.Fragment>
    );
  };

  const loader = {
    display: hotelsLoader,
  };
  return (
    <React.Fragment>
      <Header showMenu={showMenu} tabTitle="Wannafly | Hotels" />

      <main>
        <div className="loader-parent" style={loader}>
          <img
            src={process.env.PUBLIC_URL + "/assets/images/loading.gif"}
            className="loaderset"
          />
        </div>
        <div className="location-section full-list-advisor">
          <div className="container-fluid p-0">
            <div className="row m-0">
              <div className="col-lg-3 filter-section p-0">
                <div className="map">
                  <iframe
                    src={mapurl}
                    className="mapfram"
                    allowFullScreen=""
                    loading="lazy"
                    width="600"
                    height="450"
                  ></iframe>
                </div>

                <div className="filters">
                  <h5>Filter</h5>
                  <div className="filters-wrap">
                    <div className="filter-wrapper">
                      <h3>Hotel Name</h3>
                      <div className="row">
                        <div className="col">
                          <div className="search-bar">
                            {/* Search Filter */}
                            {SerachFilter(1)}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="filter-wrapper">
                      <h3>Search Radius</h3>
                      <div className="row">
                        <div className="col">
                          <div className="card card-body">
                            <div className="control-group">
                              {/* Radius Filter */}
                              <label className="control control-checkbox full-width radiuslbl">
                                0.5 km
                                <input
                                  type="checkbox"
                                  name="radius"
                                  checked={radiusHalf}
                                  onChange={HotelRediusData}
                                  defaultValue="0.5"
                                />
                                <div className="control_indicator"></div>
                              </label>
                              <label className="control control-checkbox full-width radiuslbl">
                                1 km
                                <input
                                  type="checkbox"
                                  name="radius"
                                  checked={radiusOne}
                                  onChange={HotelRediusData}
                                  defaultValue="1"
                                />
                                <div className="control_indicator"></div>
                              </label>
                              <label className="control control-checkbox full-width radiuslbl">
                                2 km
                                <input
                                  type="checkbox"
                                  name="radius"
                                  checked={radiusTwo}
                                  onChange={HotelRediusData}
                                  defaultValue="2"
                                />
                                <div className="control_indicator"></div>
                              </label>
                              <label className="control control-checkbox full-width radiuslbl">
                                5 km
                                <input
                                  type="checkbox"
                                  name="radius"
                                  checked={radiusFive}
                                  onChange={HotelRediusData}
                                  defaultValue="5"
                                />
                                <div className="control_indicator"></div>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="filter-wrapper">
                      <h3>Star Rating</h3>
                      <div className="row">
                        <div className="col">
                          <div className="card card-body">
                            <div className="control-group">
                              {/* Rating Filter  */}
                              {ratingFilter(1)}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="reset text-center">
                      <button className="btn btn-primary" onClick={resetFilter}>
                        <i className="fas fa-undo"></i>Reset all{" "}
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-9 hotels-list">
                <div className="hotels-wrapper">
                  <div className="tripdetails">
                    <h2>Your Trip</h2>

                    <i class="fas fa-plane" aria-hidden="true"></i>
                    <span>
                      {depacityName} ({depacity}){" "}
                    </span>
                    <i className="fas fa-long-arrow-alt-right fs-4"></i>
                    <span>
                      {destinationName} ({destination})
                    </span>
                  </div>

                  <div className="d-flex flex-wrap justify-content-between align-items-center hotel-details form-info">
                    <div className="col-md-3 px-0 direct-info">
                      <div className="hotel-desc">
                        <h5>Departure</h5>
                        <p className="dates">
                          <i
                            className="fas fa-calendar-alt"
                            aria-hidden="true"
                          ></i>
                          {disdepadate}
                        </p>
                      </div>
                    </div>
                    <div className="col-md-3 px-0 from-to-info">
                      <div className="hotel-desc">
                        <h5>Return</h5>
                        <p className="dates">
                          <i
                            className="fas fa-calendar-alt"
                            aria-hidden="true"
                          ></i>
                          {disreturndate}
                        </p>
                      </div>
                    </div>
                    <div className="col-md-3 px-0 Passengers">
                      <div className="hotel-desc">
                        <h5>Passengers</h5>
                        <p className="dates">
                          <i className="fas fa-user" aria-hidden="true"></i>
                          {passengers}
                        </p>
                      </div>
                    </div>
                    <div className="col-md-3 px-0 Passengers">
                      <div className="hotel-desc">
                        <h5>Rooms</h5>
                        <p className="dates">
                          <i className="fas fa-hotel" aria-hidden="true"></i>
                          {totalRooms}
                        </p>
                      </div>
                    </div>
                    <div className="col-10 px-0 pt-4 option-description">
                      <h5>
                        Once you've selected accommodation, we'll present flight
                        option for you.
                      </h5>
                    </div>
                    <div className="col-2 px-0 pt-4 price-dropdown text-right pr-0">
                      <a href="/">
                        <i className="fas fa-arrow-left"></i>Go back
                      </a>
                    </div>
                  </div>

                  {/* Item List */}
                  {hotelsitemsdata != undefined ? (
                    <HotelItem
                      detailUrl={detailUrl}
                      totalHotels={totalHotels}
                      hotelsitems={currentHotels}
                      hotelsSorting={hotelsSorting}
                      hotelsOrdering={hotelsOrdering}
                      sortingData={sortingData}
                      ordering={ordering}
                    />
                  ) : (
                    <div className="d-flex flex-wrap justify-content-between align-items-center results">
                      <div className="results-found">0 hotels found</div>
                    </div>
                  )}
                </div>
                {hotelsitemsdata != undefined && hotelsitemsdata != "" ? (
                  <ItemPagination
                    ttpage={ttpage}
                    disNumOfPage={disNumOfPage}
                    startPage={startPage}
                    endPage={endPage}
                    currentPage={currentPage}
                    paginate={paginate}
                    prevPaginate={prevPaginate}
                    nextPaginate={nextPaginate}
                    prevPagies={prevPagies}
                    nextPagies={nextPagies}
                  />
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer footerClass="page-3" />

      <div
        id="modal_aside_right"
        className="modal fixed-right fade"
        tabIndex="-1"
        role="dialog"
      >
        <div className="modal-dialog modal-dialog-aside" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="full-width filters-outer">
                <div className="filters bg-white full-list-advisor">
                  <div className="filters">
                    <h5>Filter</h5>
                    <div className="filters-wrap">
                      <div className="filter-wrapper">
                        <h3>Hotel Name</h3>
                        <div className="row">
                          <div className="col">
                            <div className="search-bar">
                              {/* Search Filter */}
                              {SerachFilter(2)}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="filter-wrapper">
                        <h3>Star Rating</h3>
                        <div className="row">
                          <div className="col">
                            <div className="card card-body">
                              <div className="control-group">
                                {/* Rating Filter  */}
                                {ratingFilter(2)}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="filter-wrapper">
                        <h3>Search Radius</h3>
                        <div className="row">
                          <div className="col">
                            <div className="card card-body">
                              <div className="control-group">
                                {/* Radius Filter */}
                                <label className="control control-checkbox full-width radiuslbl">
                                  0.5 km
                                  <input
                                    type="radio"
                                    name="radiusm"
                                    onChange={HotelRediusData}
                                    defaultValue="0.5"
                                  />
                                  <div className="control_indicator"></div>
                                </label>
                                <label className="control control-checkbox full-width radiuslbl">
                                  1 km
                                  <input
                                    type="radio"
                                    name="radiusm"
                                    onChange={HotelRediusData}
                                    defaultValue="1"
                                  />
                                  <div className="control_indicator"></div>
                                </label>
                                <label className="control control-checkbox full-width radiuslbl">
                                  2 km
                                  <input
                                    type="radio"
                                    name="radiusm"
                                    onChange={HotelRediusData}
                                    defaultValue="2"
                                  />
                                  <div className="control_indicator"></div>
                                </label>
                                <label className="control control-checkbox full-width radiuslbl">
                                  5 km
                                  <input
                                    type="radio"
                                    name="radiusm"
                                    onChange={HotelRediusData}
                                    defaultValue="5"
                                  />
                                  <div className="control_indicator"></div>
                                </label>
                                <label className="control control-checkbox full-width radiuslbl">
                                  N/A
                                  <input
                                    type="radio"
                                    name="radiusm"
                                    onChange={HotelRediusData}
                                    defaultValue=""
                                    defaultChecked={true}
                                  />
                                  <div className="control_indicator"></div>
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="reset text-center">
                        <button
                          className="btn btn-primary"
                          onClick={resetFilter}
                        >
                          <i className="fas fa-undo"></i>Reset all{" "}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default HotelList;
