/**
  Flight Airlines List in Flight List page.
  Html and data display by API,
**/

import React from 'react';

export const FlightAirlines = ({allAirlinesChbox,airlinesArrData,filterByAirLines}) => {
    
    const airlins = Object.keys(airlinesArrData).map((item,i) => {
       let airlinName = airlinesArrData[item];  
       let airlinimg = item.toLowerCase() + '.gif'; 
       let airlinCode = item; 
       
       return (
        <label key={'al'+i} className="control control-checkbox full-width">
            <img className="airlogos"  src={process.env.PUBLIC_URL + "/assets/images/airlogos/" + airlinimg} />{airlinName}
            <input type="checkbox" checked={allAirlinesChbox[item]}  value={airlinCode} onChange={(e) => {filterByAirLines(e)}} />
            <div className="control_indicator"></div>
        </label>
       ) 
    })

    return (
        <React.Fragment>
            {airlins}
             
        </React.Fragment>
    )
}