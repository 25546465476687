import { combineReducers } from 'redux';
import { departureCities, destinations, hotelList, hotelListMap, hotelDetails, flightList, passSummaryData } from './dataReducer';
 
const reducers = combineReducers({
    allcities: departureCities,
    alldestins: destinations,
    allhotels: hotelList,
    allhotelsMap: hotelListMap,
    HotelDetail: hotelDetails,
    allFlights: flightList,
    passSummaryDt: passSummaryData,
})

export default reducers;