/**
  Destination dropdown In Home and flight list page.
  Destination City data display by API.
**/

import React, { useCallback } from 'react';
import { useSelector } from 'react-redux'

const HomeDestination = ({ onDestinsSelect }) => {
    const destins = useSelector((state) => {
        if(state.alldestins !== undefined){
            return state.alldestins.destins;
        }
        return null;   
    });

    const selectdestins = useCallback(e => {
        onDestinsSelect(e.target.name,e.target.value)
    }, [onDestinsSelect]);

    const renderList = destins.map((destin,i) => {
        const destin_value = destin.value +"_"+destin.cc;
        const destin_name = destin.name;
        
        return (             
                <li key={i}>
                    <button className="destinitem" onClick={selectdestins} name={destin_name} value={destin_value}>{destin_name}</button>
                </li>
            );
    })

    return <React.Fragment>{renderList}</React.Fragment>;
}

export default HomeDestination;