import { ActionTypes } from "../constants/actionTypes";

const initialStateCity = {
    cities : []
}
const initialStateDestin = {
    destins : []
}
const initialStateHotel = {
    hotels : []
}
const initialStateHotelMap = {
    hotelsMap : []
}
const initialStateHotelDeta = {
    hotelDetls : []
}
const initialStateFlights = {
    flights : []
}
const initialStatePassSummary = {
    passSummary : []
}

export const departureCities = (state = initialStateCity, {type, payload}) => {
    switch (type) {
        case ActionTypes.GET_DEPARTURECITY:
            return {...state, cities: payload}
        default:
            return state
    }
}

export const destinations = (state = initialStateDestin, {type, payload}) => {
    switch (type) {
        case ActionTypes.GET_DESTINATION:
            return {...state, destins: payload}
        default:
            return state
    }
}

export const hotelList = (state = initialStateHotel, {type, payload}) => {
    switch (type) {
        case ActionTypes.GET_HOTELS:
            return {...state, hotels: payload}
        default:
            return state
    }
}

export const hotelListMap = (state = initialStateHotelMap, {type, payload}) => {
    switch (type) {
        case ActionTypes.GET_HOTELSMAP:
            return {...state, hotelsMap: payload}
        default:
            return state
    }
}

export const hotelDetails = (state = initialStateHotelDeta, {type, payload}) => {
    switch (type) {
        case ActionTypes.GET_HOTELDETAILS:
            return {...state, hotelDetls: payload}
        default:
            return state
    }
}

export const flightList = (state = initialStateFlights, {type, payload}) => {
    switch (type) {
        case ActionTypes.GET_FLIGHTS:
            return {...state, flights: payload}
        default:
            return state
    }
}

export const passSummaryData = (state = initialStatePassSummary, {type, payload}) => {
    switch (type) {
        case ActionTypes.GET_PASSSUMMARY:
            return {...state, passSummary: payload}
        default:
            return state
    }
}


 