/**
  Flight Airports List in Flight List page.
  Html and data display by API,
**/

import React from 'react';

export const FlightAirPorts = ({allAirportsChbox,airportsArr,filterByAirPort}) => {
    
    const airlins = airportsArr.map((item,i) => {
       let airportName = item.arpCode + ' - ' + item.arpName;
       let airportCode = item.arpCode;  
       return (
        <label key={'ap'+i} className="control control-checkbox">
            {airportName}
            <input type="checkbox" checked={allAirportsChbox[airportCode]} value={airportCode} onChange={(e) => {filterByAirPort(e)}} />
            <div className="control_indicator"></div>
        </label>
       ) 
    })
    return (
        <React.Fragment>
            {airlins}
        </React.Fragment>
    )
}