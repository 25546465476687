/**
  Passengers Summary Page.
  Html and data display by API,
  user can fill details and proceed for payment
**/

import React, {useState, useEffect, useMemo} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { getPassSummary } from '../redux/actions/dataActions';
import moment from 'moment';
import { PassengersSummaryHotelDetail } from './PassengersSummaryHotelDetail';
import Select from 'react-select';
import countryList from 'react-select-country-list';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import cloneDeep from "lodash/cloneDeep"
import { CLIENT_INFO, PHONECODE } from '../redux/constants/commanConst';
import publicIp from 'public-ip';
import { commanConst } from '../redux/constants/commanConst';

export const PassengersSummary = () =>{
    const showMenu = false;

    const passSumm = useSelector((state) => state);
    const dispatch = useDispatch();
    const {c_key, hid, room_key, tval ,api_key, fc_key} = useParams();
    const [pageLoader, setPageLoader] = useState('block');
    const [gender, setGender] = useState([]);
    const [firstName, setFirstName] = useState([]);
    const [lastName, setLastName] = useState([]);
    const [day, setDay] = useState([]);
    const [month, setMonth] = useState([]);
    const [year, setYear] = useState([]);
    const [nationality, setNationality] = useState([]);
    const [passNo, setPassNo] = useState([]);
    const [passportExpDate, setPassportExpDate] = useState([]);
    const [freqFlyProg, setFreqFlyProg] = useState([]);
    const [freqFlyNumb, setFreqFlyNumb] = useState([]);
    const [specialRequest, setSpecialRequest] = useState('');
    const [email, setEmail] = useState([]);
    const [repeatEmail, setRepeatEmail] = useState('');
    const [countryCode, setCountryCode] = useState('');
    const [mobileNumber, setMobileNumber] = useState('');
    const [inputValidate, setInputValidate] = useState([]);
    const [select, setSelect] = React.useState(true)

    let ageType = {};
    let UserInfo = cloneDeep(CLIENT_INFO)
    let isMobile = /iPhone|iPad|iPod|Android|BlackBerry|Windows Phone|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront/i.test(
      navigator.userAgent
    )
    if (isMobile) {
        UserInfo.device_type = "mobile"
    } else {
        UserInfo.device_type = "computer"
    }

    UserInfo.user_agent = navigator.userAgent;

    //Get ip
    (async () => {
        UserInfo.ip = await publicIp.v4();
    })();


    //Get passengers summary details
    const passSummData = async () => {
        await axios
          .get(`https://package.militaryfares.com/?method=details&lang=en&curr=USD&affiliate=wannafly&master=wannafly&flight[api]=${api_key.replace(/~/g,'/')}&flight[book]=${tval}&flight[key]=${fc_key.replace(/~/g,'/')}&flight[life]=3600&flight[log_id]=6e772b1d-c3cb-4f3b-80d8-b4f0eaa6b22f&flight[trip]=round&hotel[cache_key]=${c_key}&hotel[code]=${hid}&hotel[room_key]=${room_key}`)
          .then(response => {

              if( typeof response != 'undefined' &&  response != null && response.status === 200){
                  if (response.data.status != 'ERR') {
                      dispatch(getPassSummary(response.data));
                  }else{
                      dispatch(getPassSummary(''));
                  }
                  setPageLoader("none");
              }
          })
          .catch((err) => {
              dispatch(getPassSummary(''));
              console.log("Err", err);
              setPageLoader("none");
          });
    };

    useEffect(() => {
        passSummData();
        setPageLoader("block");
        console.log(`https://package.militaryfares.com/?method=details&lang=en&curr=USD&affiliate=wannafly&master=wannafly&flight[api]=${api_key.replace(/~/g,'/')}&flight[book]=${tval}&flight[key]=${fc_key.replace(/~/g,'/')}&flight[life]=3600&flight[log_id]=6e772b1d-c3cb-4f3b-80d8-b4f0eaa6b22f&flight[trip]=round&hotel[cache_key]=${c_key}&hotel[code]=${hid}&hotel[room_key]=${room_key}`);
    },[])


    //Nationality List
    const nationalityOptions = useMemo(() => countryList().getData(), []);


    //Nationality change
    const changeHandler = (e,inx) => {
        setNationality((prev) => ({...prev, [inx]: e.value}));
    }

    //Change passport exp Date
    const passportExpDateChange = (e,inx) => {
        setPassportExpDate((prev) => ({...prev, [inx]: e}));
    }

    const constactData = (e) => {
        // var index = e.nativeEvent.target.selectedIndex;
        // e.nativeEvent.target[index].text = e.target.value
        setSelect(true);


        let inputval = e.target.value;
        let inputname = e.target.name;
        if(inputname === 'email'){
            setEmail(inputval);
        }else if(inputname === 'repeatEmail'){
            setRepeatEmail(inputval);
        }else if(inputname === 'countryCode'){
            setCountryCode(inputval);
        }else if(inputname === 'telNo'){
            setMobileNumber(inputval);
        }else if(inputname === 'specialRequest'){
            setSpecialRequest(inputval);
        }
    }

    //Fill data
    const dataChange = (e) => {

        let targArr = (e.target.id).split('_')
        let inxid = targArr[1];
        let inputnm = targArr[0];
        let val = e.target.value;

        if(inputnm === 'male' || inputnm === 'female'){
            setGender((prev) => ({...prev, [inxid]: val}));
        }else if(inputnm === 'fnm'){
            setFirstName((prev) => ({...prev, [inxid]: val}));
        }else if(inputnm === 'fmlynm'){
            setLastName((prev) => ({...prev, [inxid]: val}));
        }else if(inputnm === 'day'){
            setDay((prev) => ({...prev, [inxid]: val}));
        }else if(inputnm === 'mth'){
            setMonth((prev) => ({...prev, [inxid]: val}));
        }else if(inputnm === 'yr'){
            setYear((prev) => ({...prev, [inxid]: val}));
        }else if(inputnm === 'pano'){
            setPassNo((prev) => ({...prev, [inxid]: val}));
        }else if(inputnm === 'freqFlyProg'){
            setFreqFlyProg((prev) => ({...prev, [inxid]: val}));
        }else if(inputnm === 'freqFlyNumb'){
            setFreqFlyNumb((prev) => ({...prev, [inxid]: val}));
        }
    }


    // Form Data
    const passFormData = (icn,val,inx) => {

        let lowcaseval = val.toLowerCase();
        ageType[inx] = lowcaseval;
        let genderColor = inputValidate['gender_'+inx];
        let firstNameColor = inputValidate['firstName_'+inx];
        let lastNameColor = inputValidate['lastName_'+inx];
        let dobColor = inputValidate['dob_'+inx];
        let nationalityColor = inputValidate['nationality_'+inx];
        let passNoColor = inputValidate['passNo_'+inx];
        let pedColor = inputValidate['ped_'+inx];
        let FreqFlyProgColor = inputValidate['freqFlyProg_'+inx];
        let FreqFlyNumbColor = inputValidate['freqFlyNumb_'+inx];

        return(
          <React.Fragment key={'icn_'+inx}>
              <div className="row">
                  <div className="col-md-12 col-sm-12">
                      <label className="iconsize"><i className={`fas fa-${icn}`}></i> {val}</label>
                      <div className="form-group">
                          <div className="form-check-inline control-group">
                              <label className="control control-radio" style={{color:genderColor}}>
                                  Male
                                  <input type="radio" value="male" onClick={(e) => dataChange(e)} name={'gender'+'_'+inx} id={'male'+'_'+inx} />
                                  <div className="control_indicator"></div>
                              </label>
                              <label className="control control-radio" style={{color:genderColor}}>
                                  Female
                                  <input type="radio" value="female" onClick={(e) => dataChange(e)} name={'gender'+'_'+inx} id={'female'+'_'+inx}/>
                                  <div className="control_indicator"></div>
                              </label>
                          </div>
                      </div>
                  </div>
              </div>
              <div className="row">
                  <div className="col-md-6 col-sm-6">
                      <div className="form-group">
                          <label style={{color:firstNameColor}}>First Name(s)<span>as in your ID</span></label>
                          <input type="text" onChange={(e) => dataChange(e)} className="form-control" name={'fnm_'+inx} id={'fnm_'+inx} />
                      </div>
                  </div>
                  <div className="col-md-6 col-sm-6">
                      <div className="form-group">
                          <label style={{color:lastNameColor}}>Family Name(s)<span>as in your ID</span></label>
                          <input type="text" onChange={(e) => dataChange(e)} className="form-control" name={'fmlynm_'+inx} id={'fmlynm_'+inx} />
                      </div>
                  </div>
              </div>
              <div className="row align-items-end">
                  <div className="col-md-4 col-sm-4">
                      <div className="form-group">
                          <label style={{color:dobColor}}>Date of Birth*</label>
                          <div className="input_field select_option">
                              <select onChange={(e) => dataChange(e)} className="form-control" name={'day_'+inx} id={'day_'+inx}>
                                  <option value=''>--</option>
                                  {dayList()}
                              </select>
                              <div className="select_arrow"></div>
                          </div>
                      </div>
                  </div>
                  <div className="col-md-4 col-sm-4">
                      <div className="form-group">
                          <div className="input_field select_option">
                              <select onChange={(e) => dataChange(e)} className="form-control" name={'mth_'+inx} id={'mth_'+inx}>
                                  <option value=''>--</option>
                                  {monthList()}
                              </select>
                              <div className="select_arrow"></div>
                          </div>
                      </div>
                  </div>

                  <div className="col-md-4 col-sm-4">
                      <div className="form-group">
                          <div className="input_field select_option">
                              <select onChange={(e) => dataChange(e)} className="form-control" name={'yr_'+inx} id={'yr_'+inx}>
                                  <option value=''>--</option>
                                  {yearList(val)}
                              </select>
                              <div className="select_arrow"></div>
                          </div>
                      </div>
                  </div>
              </div>
              <div className="row align-items-start">
                  <div className="col-md-4 col-sm-4">
                      <div className="form-group">
                          <label style={{color:nationalityColor}}>Nationality*</label>
                          <div className="input_field select_option selNationality">

                              <Select name={'natin_'+inx} id={'natin_'+inx} options={nationalityOptions}  onChange={(e) => changeHandler(e,inx)} />

                          </div>
                      </div>
                  </div>
                  <div className="col-md-4 col-sm-4">
                      <div className="form-group">
                          <label style={{color:passNoColor}}>Passport Number*</label>
                          <input type="text" onChange={(e) => dataChange(e)} className="form-control" name={'pano_'+inx} id={'pano_'+inx} />
                      </div>
                  </div>

                  <div className="col-md-4 col-sm-4">
                      <div className="form-group expiry-field">
                          <label style={{color:pedColor}}>Passport Expiry Date*</label>
                          <div className="position-relative expdatepicker">

                              <DatePicker
                                selected={passportExpDate[inx]}
                                onChange={date=>passportExpDateChange(date,inx)}
                                placeholderText='dd/mm/yyyy'
                                dateFormat='dd/MM/yyyy'
                                minDate={new Date()}
                              />
                          </div>
                      </div>
                  </div>
              </div>


              <div className="row">
                  <div className="col-md-6 col-sm-6">
                      <div className="form-group">
                          <label style={{color:FreqFlyProgColor}}>Frequent Flyer Program</label>
                          <input type="text" onChange={(e) => dataChange(e)} className="form-control" name={'freqFlyProg_'+inx} id={'freqFlyProg_'+inx} />
                      </div>
                  </div>
                  <div className="col-md-6 col-sm-6">
                      <div className="form-group">
                          <label style={{color:FreqFlyNumbColor}}>Frequent Flyer Number</label>
                          <input type="text" onChange={(e) => dataChange(e)} className="form-control" name={'freqFlyNumb_'+inx} id={'freqFlyNumb_'+inx} />
                      </div>
                  </div>
              </div>
          </React.Fragment>
        )
    }

    //Month array
    const monthName = ['Jan','Feb','Mar', 'Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];

    const yearList = (type) => {
        var max = new Date().getFullYear();
        let min = '';
        let years = [];

        if(type === 'Adult'){
            max = max - 11;
            min = 1897;
        }else if(type === 'Child'){
            min = max - 12;
        }else if(type === 'Infant'){
            min = max - 2;
        }

        for (var i = max; i >= min; i--) {
            years.push(<option key={`yr_${i}`} value={i}>{i}</option>)
        }
        return years
    }

    const monthList = (type) => {
        let month = [];
        for (var i = 0; i < 12; i++) {
            month.push(<option key={`mth_${i}`} value={i+1}>{monthName[i]}</option>)
        }
        return month;
    }

    const dayList = (type) => {
        let days = [];
        for (var i = 1; i <= 31; i++) {
            days.push(<option key={`dy_${i}`} value={i}>{i}</option>)
        }
        return days;
    }


    //passenger summary data
    let passSummHotel = useSelector((state) => {
        if((state.passSummaryDt.passSummary).length !== 0){
            return state.passSummaryDt.passSummary.hotels;
        }
        return null;
    });

    // let passSummHotel = useSelector((state) => state.passSummaryDt.passSummary.hotels);
    let passSummFlight = useSelector((state) => {
        if((state.passSummaryDt.passSummary).length !== 0){
            return state.passSummaryDt.passSummary.flights;
        }
        return null;
    });



    let totalpassenger,totalpassengerpc,booking_uuid, airlineName, disTown, arrTown, flightDateTime, flightDateTimeArr, flightimg, adult, child, infant, depFrom, depTo, retFrom, retTo, depDate, retDate, cache_key, code, p_key, beforeDiscountPrice, discountPrice, totalPackagePrice = '';
    let adultData,childData,infantData = '';
    let cont = 0;

    if(passSummHotel !== null){
        const summFlightData = passSummHotel.map((item) => {
            cache_key = item.c_key;
            code = item.hid;
            p_key = item.p_key;
            adult = item.summary_passenger['adult'];
            child = item.summary_passenger['child'];
            infant = item.summary_passenger['infant'];
            totalpassenger = item.summary_passenger['adult'] + item.summary_passenger['child'] + item.summary_passenger['infant'];
            totalpassengerpc = item.summary_price['adult'] + item.summary_price['child'] + item.summary_price['infant'];
            beforeDiscountPrice = (item.total_package_price / (1 - 21/100)).toFixed(2); //Price Before Discount
            discountPrice = (beforeDiscountPrice * 21 / 100).toFixed(2); //Price Before Discount
            totalPackagePrice = item.total_package_price;

            // Adult Form
            if(adult !== 0){
                adultData = Array.from(Array(adult).keys()).map((item) => {
                    cont++;
                    return(passFormData('male','Adult',cont));
                })
            }

            // Child Form
            if(child !== 0){
                childData = Array.from(Array(child).keys()).map((item) => {
                    cont++;
                    return(passFormData('child','Child',cont));
                })
            }

            // Infant Form
            if(infant !== 0){
                infantData = Array.from(Array(infant).keys()).map((item) => {
                    cont++;
                    return(passFormData('baby','Infant',cont));
                })
            }

        })
    }

    if(passSummFlight !== null){
        const summFlightData = passSummFlight.map((item) => {
            booking_uuid = item.booking_uuid;
            airlineName = item.flight_info[0].airline_name[0];
            disTown = item.flight_info[0].dep_town[0];
            arrTown = item.flight_info[0].arr_town[0];
            flightDateTime = item.dep_flight_date_time[0][0];
            flightDateTimeArr = item.arr_flight_date_time[0][0];
            flightimg = item.airline_code[0][0].toLowerCase() + '.gif';
            depFrom = item.dep_airport[0][0];
            depTo = item.arr_airport[0][0];
            retFrom = item.dep_airport[1][0];
            retTo = item.arr_airport[1][0];
            depDate = item.dep_flight_date_time[0][0];
            retDate = item.dep_flight_date_time[1][0];
        })
    }


    const submitFormData = (val) => {

        let submitUrl = '';
        let finalObj = {};
        let dob = {};
        let ped = {};
        let canType = {}
        let canType2 = {}
        let meal = {}
        let seat = {}
        let flyerProg = {}
        let flyerNum = {}
        let brbType = {}
        let brbPrice = {}
        let suppType = {}

        let roomdata2 = {};
        let adultcnt = 0;
        let adultcntarr = {};
        let childcnt = 0;
        let childcntarr = {};
        let roomFinalArr = {};
        let isvalid = [];
        for(let k=1;k<=val;k++){
            let roomdata = {};

            dob[k] = month[k]+"||"+day[k]+"||"+year[k];
            ped[k] = (moment(passportExpDate[k]).format('DD||MM||YYYY'));
            canType[k] = '';
            canType2[k] = ''
            meal[k] = '0'
            seat[k] = '0'
            flyerProg[k] = ''
            flyerNum[k] = ''
            brbType[k] = 'NONE'
            brbPrice[k] = '0'
            suppType[k] = '91'


            roomdata['gender'] = gender[k];
            roomdata['firstname'] = firstName[k];
            roomdata['lastname'] = lastName[k];
            roomdata['nationality'] = nationality[k];
            roomdata['dateofbirth'] = dob[k];


            if(ageType[k] === 'adult'){
                adultcnt++;
                adultcntarr[adultcnt] = roomdata;
                roomdata2['ADT'] = adultcntarr;
            }else if(ageType[k] === 'child'){
                childcnt++;
                childcntarr[childcnt] = roomdata;
                roomdata2['CHD'] = childcntarr;
            }


            //Validation
            setInputValidate((prev) => ({...prev,['gender_'+k]:'#505050'}));
            if(gender[k] === undefined){
                setInputValidate((prev) => ({...prev,['gender_'+k]:'#ec5849'}));
                isvalid.push('1');
            }

            setInputValidate((prev) => ({...prev,['firstName_'+k]:'#505050'}));
            if(firstName[k] === undefined || firstName[k] === ''){
                setInputValidate((prev) => ({...prev,['firstName_'+k]:'#ec5849'}));
                isvalid.push('1');
            }

            setInputValidate((prev) => ({...prev,['lastName_'+k]:'#505050'}));
            if(lastName[k] === undefined || lastName[k] === ''){
                setInputValidate((prev) => ({...prev,['lastName_'+k]:'#ec5849'}));
                isvalid.push('1');
            }

            setInputValidate((prev) => ({...prev,['dob_'+k]:'#505050'}));
            if(month[k] === undefined || day[k] === undefined || year[k] === undefined){
                setInputValidate((prev) => ({...prev,['dob_'+k]:'#ec5849'}));
                isvalid.push('1');
            }else{
                let dob = year[k]+'-'+month[k]+'-'+day[k];
                if(!moment(dob, 'YYYY-MM-DD').isValid()){
                    setInputValidate((prev) => ({...prev,['dob_'+k]:'#ec5849'}));
                    isvalid.push('1');
                }
            }

            setInputValidate((prev) => ({...prev,['nationality_'+k]:'#505050'}));
            if(nationality[k] === undefined || nationality[k] === ''){
                setInputValidate((prev) => ({...prev,['nationality_'+k]:'#ec5849'}));
                isvalid.push('1');
            }

            setInputValidate((prev) => ({...prev,['passNo_'+k]:'#505050'}));
            if(passNo[k] === undefined || passNo[k] === ''){
                setInputValidate((prev) => ({...prev,['passNo_'+k]:'#ec5849'}));
                isvalid.push('1');
            }else{
                let letterNumber = /^[0-9a-zA-Z]+$/;
                if(!(passNo[k].match(letterNumber))){
                    setInputValidate((prev) => ({...prev,['passNo_'+k]:'#ec5849'}));
                    isvalid.push('1');
                }
            }

            setInputValidate((prev) => ({...prev,['ped_'+k]:'#505050'}));
            if(ped[k] === 'Invalid date'){
                setInputValidate((prev) => ({...prev,['ped_'+k]:'#ec5849'}));
                isvalid.push('1');
            }

            setInputValidate((prev) => ({...prev,[' else {_'+k]:'#505050'}));
            if(freqFlyProg[k] === undefined || freqFlyProg[k] === ''){
            } else {
                setInputValidate((prev) => ({...prev,['freqFlyProg_'+k]:'#ec5849'}));
                isvalid.push('1');
            }

            setInputValidate((prev) => ({...prev,['freqFlyNumb_'+k]:'#505050'}));
            if(freqFlyNumb[k] === undefined || freqFlyNumb[k] === ''){
            } else {
                setInputValidate((prev) => ({...prev,['freqFlyNumb_'+k]:'#ec5849'}));
                isvalid.push('1');
            }

        }

        let emailformat = new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g);
        let mobilformat = /^\d*(?:\.\d{1,2})?$/;

        setInputValidate((prev) => ({...prev,['email']:'#505050'}));
        if(email === ''){
            setInputValidate((prev) => ({...prev,['email']:'#ec5849'}));
            isvalid.push('1');
        }else{
            if(emailformat.test(email) === false){
                setInputValidate((prev) => ({...prev,['email']:'#ec5849'}));
                isvalid.push('1');
            }
        }

        setInputValidate((prev) => ({...prev,['repeatEmail']:'#505050'}));
        if(repeatEmail === ''){
            setInputValidate((prev) => ({...prev,['repeatEmail']:'#ec5849'}));
            isvalid.push('1');
        }


        if(repeatEmail !== email){
            setInputValidate((prev) => ({...prev,['email']:'#ec5849'}));
            setInputValidate((prev) => ({...prev,['repeatEmail']:'#ec5849'}));
            isvalid.push('1');
        }


        setInputValidate((prev) => ({...prev,['countryCode']:'#505050'}));
        if(countryCode === ''){
            setInputValidate((prev) => ({...prev,['countryCode']:'#ec5849'}));
            isvalid.push('1');
        }

        setInputValidate((prev) => ({...prev,['mobNum']:'#505050'}));
        if(mobileNumber === ''){
            setInputValidate((prev) => ({...prev,['mobNum']:'#ec5849'}));
            isvalid.push('1');
        }else{
            if(mobileNumber.length !== 10){
                setInputValidate((prev) => ({...prev,['mobNum']:'#ec5849'}));
                isvalid.push('1');
            }else if(mobilformat.test(mobileNumber) === false){
                setInputValidate((prev) => ({...prev,['mobNum']:'#ec5849'}));
                isvalid.push('1');
            }
        }

        setInputValidate((prev) => ({...prev,['specialRequest']:'#505050'}));
        if(specialRequest === ''){
        } else {
            setInputValidate((prev) => ({...prev,['specialRequest']:'#ec5849'}));
            isvalid.push('1');
        }


        // Flight passengers
        finalObj['firstname'] = firstName;
        finalObj['lastname'] = lastName;
        finalObj['dateofbirth'] = dob;
        finalObj['gender'] = gender;
        finalObj['nationality'] = nationality;
        finalObj['passport_number'] = passNo;
        finalObj['passport_date'] = ped;
        finalObj['cancel_types'] = canType;
        finalObj['cancel_types2'] = canType2;
        finalObj['meal'] = meal;
        finalObj['seat'] = seat;
        finalObj['flyer_program'] = flyerProg;
        finalObj['flyer_number'] = flyerNum;
        finalObj['brb_type'] = brbType;
        finalObj['brb_price'] = brbPrice;
        finalObj['support_types'] = suppType;
        finalObj['emissions'] = 0;

        let roomlist = JSON.parse(localStorage.getItem('roomsWisePess'));

        let adltcnt = 0;
        let chldcnt = 0;
        roomlist.map((item,i) => {
            let rmno = i + 1;
            let roomFinalArrtmpad = {};
            let roomFinalArrtmpcd = {};
            let roomFinalArrtmp1 = {};
            if(item.adults !== 0){

                for(let k=1;k<=item.adults;k++){
                    adltcnt++;
                    roomFinalArrtmpad[k] = roomdata2.ADT[adltcnt];
                    roomFinalArrtmp1['ADT'] = roomFinalArrtmpad;
                    // roomFinalArr[rmno] = roomFinalArrtmp1;
                }

            }
            if(item.children !== 0){
                for(let k=1;k<=item.children;k++){
                    chldcnt++;
                    roomFinalArrtmpcd[k] = roomdata2.CHD[chldcnt];
                    roomFinalArrtmp1['CHD'] = roomFinalArrtmpcd;

                }
            }

            //Hotel Rooms
            roomFinalArr[rmno] = roomFinalArrtmp1;
        })

        //Redirect to payment page
        const goToPaymentPage = async (urlData) => {
            try {
                const response = await axios.get(
                  `https://package.militaryfares.com/?method=store_reservation&lang=en&curr=USD&affiliate=wannafly&master=wannafly&${urlData}`
                );

                if (response.status === 200 && response.data.status !== 'ERR' && response.data.flights.url) {
                    console.log(response.data);
                    //debugger
                    window.location.href = response.data.flights.url;
                } else {
                    console.error('Invalid response data:', response.data);
                    // Handle the case where the response does not contain a valid payment URL or status.
                }
            } catch (error) {
                console.error('API Request Error:', error);
                // Handle network or other errors here.
            }
        };

        let urlData = ((encodeURIComponent(`flight[booking_uuid]=${booking_uuid}&flight[email]=${email}&flight[area_code]=${countryCode}&flight[phone]=${mobileNumber}&flight[client_ip]=${UserInfo.ip}&flight[client_info]={"device_type":${UserInfo.device_type},"agent":${UserInfo.user_agent},"cookie_forterToken":"","cookie_priceline":""}&flight[client_request]=https://affiliateapi.sky-tours.com/&flight[passengers]=${JSON.stringify(finalObj)}&hotel[cache_key]=${cache_key}&hotel[code]=${code}&hotel[room_key]=${p_key}&hotel[client_ip]=${UserInfo.ip}&hotel[email]=${email}&hotel[area_code]=${countryCode}&hotel[phone]=${mobileNumber}&hotel[room]=${JSON.stringify(roomFinalArr)}&flyer_program=${JSON.stringify(freqFlyProg)}&flyer_number=${JSON.stringify(freqFlyNumb)}&hotel_remark=${specialRequest}`).replace(/%7C%7C/g,'%5C%2F')).replace(/%26/g,'&')).replace(/%3D/g,'=');
        goToPaymentPage(urlData);

    }

    useEffect(() => {
        //set blank exp date
        for(let k=1;k<=cont;k++){
            setPassportExpDate((prev) => ({...prev,[k]:''}));
        }
    },[])

    let emailColor = inputValidate['email'];
    let repeatEmailColor = inputValidate['repeatEmail'];
    let countryCodeColor = inputValidate['countryCode'];
    let mobNumColor = inputValidate['mobNum'];
    let specialRequestColor = inputValidate['specialRequest'];

    //Country Phone Code
    let countyCode = Object.keys(PHONECODE).map((item,i) => {
        return (<option key={i} value={PHONECODE[item].code} title={PHONECODE[item].name}>
            {
                select && countryCode === PHONECODE[item].code
                  ? PHONECODE[item].code
                  : PHONECODE[item].name +': '+PHONECODE[item].code
            }
        </option>)
    })

    const loader = {
        display: pageLoader
    }

    return(
      <React.Fragment>
          <Header showMenu={showMenu} tabTitle="Wannafly | Passengers Summary"/>
          <main>
              <div className="loader-parent" style={loader}>
                  <img src={process.env.PUBLIC_URL + '/assets/images/loading.gif'} className="loaderset"/>
              </div>
              <div className="passenger-section">
                  <div className="container">
                      <div className="row">
                          <div className="col-sm-12 passenger-heading">
                              <h2>Passengers Details</h2>
                          </div>
                      </div>

                      <div className="row">
                          <div className="col-sm-8 contact-details">

                              <div className="hurry-message white-bg">
                                  <p><img src={process.env.PUBLIC_URL + "/assets/images/sucess-icon.png" } />Hurray! You are saving ${discountPrice} in this deal!</p>
                              </div>
                              <div className="contact-detail white-bg">
                                  <div className="d-flex flex-wrap align-items-center justify-content-between forms-heading">
                                      <h2>Contact Details</h2>
                                      <span>All form fields are required*</span>
                                  </div>
                                  <form>
                                      <div className="row">
                                          <div className="col-md-6 col-sm-6">
                                              <div className="form-group">
                                                  <label style={{color:emailColor}}>Email*</label>
                                                  <input type="email" name="email" onChange={constactData}  className="form-control" />
                                              </div>
                                          </div>
                                          <div className="col-md-6 col-sm-6">
                                              <div className="form-group">
                                                  <label style={{color:repeatEmailColor}}>Repeat Email*</label>
                                                  <input type="email" name="repeatEmail" onChange={constactData} className="form-control" />
                                              </div>
                                          </div>
                                      </div>
                                      <div className="row">
                                          <div className="col-md-6 col-sm-6">
                                              <div className="form-group">
                                                  <label style={{color:countryCodeColor}}>Country Code*</label>
                                                  {/* <input name="countryCode" onChange={constactData} className="form-control" type="text" /> */}
                                                  <div className="input_field select_option">
                                                      <select name="countryCode" onChange={constactData} onBlur={() => {setSelect(true)}} onFocus={() => {setSelect(false)}} className="form-control">
                                                          <option value=''>--</option>
                                                          {countyCode}
                                                      </select>
                                                      <div className="select_arrow"></div>
                                                  </div>
                                              </div>

                                          </div>
                                          <div className="col-md-6 col-sm-6">
                                              <div className="form-group">
                                                  <label style={{color:mobNumColor}}>Mobile Number*</label>
                                                  <input type="tel" name="telNo" onChange={constactData} className="form-control" />
                                              </div>
                                          </div>
                                      </div>
                                  </form>
                              </div>

                              <div className="passenger-detail white-bg">
                                  <div className="d-flex flex-wrap align-items-center justify-content-between forms-heading">
                                      <h2>Passenger Details</h2>
                                      <span>All form fields are required*</span>
                                  </div>

                                  <form>

                                      {/* Passenger Details form */}
                                      {adultData}
                                      {childData}
                                      {infantData}

                                      <div className="row">
                                          <div className="col-md-12">
                                              <div className="form-group">
                                                  <label style={{color:specialRequestColor}}>Special request/message for the Hotel</label>
                                                  <input type="text" name='specialRequest' onChange={constactData} className="form-control"  />
                                              </div>
                                          </div>
                                      </div>
                                      <div className="row align-items-center">
                                          <div className="col-md-12 text-right col-sm-12">
                                              <div className="submit-btn">
                                                  <input type="button" className="form-control form-check-inline brand-btn" onClick={() => submitFormData(cont)} value="Select Payment Method" aria-label="Hotel" />
                                              </div>
                                          </div>
                                      </div>
                                  </form>
                              </div>
                          </div>

                          <div className="col-sm-4 flight-hotel-details">
                              <div className="booking-heading">
                                  <h2>All Booking Details</h2>
                              </div>
                              <div className="flight-detail">
                                  <div className="airline-heading bg-white">
                                      <h2><i className="fa fa-plane" aria-hidden="true"></i>Flight Details</h2>
                                      <div className="going-flight">
                                          <div className="d-flex flex-wrap">
                                              <div className="ariline-icon">
                                                  <img className="airlogosSumm" src={process.env.PUBLIC_URL + "/assets/images/airlogos/" + flightimg} />
                                              </div>
                                              <div className="airline-content">
                                                  <span className="name">{airlineName}</span>
                                                  <span className="depart">Depart: <b>{moment(flightDateTime).format('ddd, MMM DD, YYYY')}</b></span>
                                              </div>
                                          </div>
                                          <div className="d-flex flex-wrap mb-3">
                                              <div className="returing">
                                                  <span>{disTown}<span className="date">{moment(flightDateTime).format('hh:mm')}</span></span>
                                                  <i className="fa fa-fighter-jet" aria-hidden="true"></i>
                                                  <span>{arrTown}<span className="date">{moment(flightDateTimeArr).format('hh:mm')}</span></span>
                                              </div>
                                          </div>

                                          <div className="d-flex flex-wrap align-items-center justify-content-between">
                                              <div className="no-of-passenger">
                                                  <span>Passengers:</span><i className="fa fa-user" aria-hidden="true"></i> {totalpassenger}
                                              </div>

                                          </div>
                                      </div>
                                  </div>
                              </div>



                              <PassengersSummaryHotelDetail depFrom={depFrom} depTo={depTo} retFrom={retFrom} retTo={retTo} depDate={depDate} retDate={retDate} adult={adult} child={child} infant={infant} cache_key={cache_key} code={code} p_key={p_key} />




                              <div className="price-detail bg-white">
                                  <div className="airline-heading bg-white">
                                      <div className="d-flex flex-wrap align-items-center justify-content-between spacing-20">
                                          <h2><i className="fa fa-usd" aria-hidden="true"></i>Price Details</h2>
                                          <span className="rating off">21% discount</span>
                                      </div>

                                      <div className="spacing">
                                          <div className="saving">
                                              <div className="d-flex flex-wrap align-items-center justify-content-between">
                                                  <div className="saving-detail">
                                                      <h4>Flight + Hotel</h4>
                                                  </div>
                                                  <div className="saving-price">
                                                      <span>${beforeDiscountPrice}</span>
                                                  </div>
                                              </div>
                                              <div className="d-flex flex-wrap align-items-center justify-content-between save">
                                                  <div className="saving-detail">
                                                      <h4>Savings</h4>
                                                  </div>
                                                  <div className="saving-price">
                                                      <span>${discountPrice}</span>
                                                  </div>
                                              </div>
                                          </div>

                                          <div className="total">
                                              <div className="d-flex flex-wrap align-items-center justify-content-between">
                                                  <div className="total-text">
                                                      <h4>total</h4>
                                                  </div>
                                                  <div className="total-price">
                                                      <span>${totalPackagePrice}</span>
                                                  </div>
                                              </div>
                                          </div>

                                          <div className="hurry-message white-bg">
                                              <p><i className="fa fa-check" aria-hidden="true"></i>
                                                  <span>Congrats! You saved
                                                    ${discountPrice} on this booking</span></p>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </main>
          <Footer footerclassName="page-3 passenger-summary"/>
      </React.Fragment>
    )
}