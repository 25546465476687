
/**
  Modify Search In Flight List page.
  Data display by API, 
  User can modify search
  This modify search work same as home page
**/

import React, {useState, useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getDeparturecity, getdestinations } from '../redux/actions/dataActions'; 
import axios from 'axios';
import HomeCities from './HomeCities';
import HomeDestination from './HomeDestination';
import Modal from 'react-modal'; //Can Open model
import moment from 'moment';
import DatePicker from "react-datepicker";
import Geocode from "react-geocode"; //Can Get lat loang
import { HomePassengers } from './HomePassengers';
import { commanConst } from '../redux/constants/commanConst';
import $ from 'jquery';

const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
};
export const FlightListModifySearch = ({filterForm}) => {

    const cities = useSelector((state) => state);
    const dispatch = useDispatch();

    // save data to Storage
    const [formState, setFormState] = useState({yourBudget: "300", departureCityName: "", departureCity: "", destinationName: "", destination: "", regionCode: "", numberOfDays: "", redirecturl: "", passengers: 0, totalAdults:0, totalChildren:0, totalInfants:0, totalYouth:0, setDisable: false });
    const {yourBudget, departureCityName, destinationName, departureCity, destination, regionCode, numberOfDays, redirecturl, passengers, totalAdults, totalChildren, totalInfants, totalYouth, setDisable } = formState;

    //Default Returndate
    let mndate = new Date();
    mndate.setDate(mndate.getDate() + 1);

    //save in state
    const [departureDate, setDepartureDate] = useState(new Date());
    const [returnDate, setReturnDate] = useState(mndate);
    const [minreturnDate, setMinReturnDate] = useState(mndate);
    const [modalIsOpenPass, setIsOpenPass] = useState(false);
    const [modalIsOpenPassZero, setIsOpenPassZero] = useState(false);
    const [modalIsOpenCity, setIsOpenCity] = useState(false);
    const [destLat, setDestLat] = useState('');
    const [destLon, setDestLon] = useState('');

    const roomsArray = [
        {
            hideshow: 'show',
            adults: 0,
            children: 0,
            infants: 0,
            youth: 0,     
            
        } 
    ];

    const childAge = [
        {
            ages: []
        }
    ];
    const [roomsWisePess, setRoomsWisePess] = useState(roomsArray);
    const [childAgeManage, setChildAgeManage] = useState(childAge);

    //Set max Date
    let mdate = new Date();
    mdate.setDate(mdate.getDate() + 365);
    const maxSelDate = mdate;

    //Change filter data
    const handleChange = (e) => {
        let value = e.target.value;
        let name = e.target.name;
        
        setFormState((prev) => ({...prev, [name]: value}));

        if(name == "numberOfDays"){
            let rdate = new Date(moment(departureDate).format('YYYY-MM-DD'));
            
            // add a day
            rdate.setDate(rdate.getDate() + parseInt(value));
            if(rdate != "Invalid Date"){
                setReturnDate(rdate);
            }
        }
        
      
        //Get Data From API
        if(name == 'departureCity'){
            fetchcities(departureCity);
            setFormState((prev) => ({...prev, ["departureCityName"]: value}));
        }
        
        if(name == 'destination'){
            fetchdestins(destination);
            setFormState((prev) => ({...prev, ["destinationName"]: value}));
        }
           
    };


    useEffect(() => {
        const formS = JSON.parse(localStorage.getItem("homeSerach"));
        let dpsdate = localStorage.getItem("departureDate");
        let rtsdate = localStorage.getItem("returnDate");
        let destLat = localStorage.getItem("destLat");
        let destLon = localStorage.getItem("destLon");
        let rmWisePess = localStorage.getItem("roomsWisePess");
        let rmWisechage = localStorage.getItem("childAgeManage");
        let Todaydpdate = new Date();
        let Todaydpdtpone = new Date();
        Todaydpdtpone.setDate(Todaydpdtpone.getDate() + 1); 
        let Todaydpdate1 = moment(Todaydpdate,'YYYY-MM-DD').format('YYYY-MM-DD');
        
        if(destLat != null){
            setDestLat(JSON.parse(destLat));
        }

        if(destLon != null){
            setDestLon(JSON.parse(destLon));
        }

        if(dpsdate != null){
            let dpdate = new Date(JSON.parse(localStorage.getItem("departureDate")));
            let Todaydpdtpone1 = moment(dpdate,'YYYY-MM-DD').format('YYYY-MM-DD');
             console.log(Todaydpdtpone1);

            if(Todaydpdate1 !== Todaydpdtpone1 && dpdate.getTime() < Todaydpdate.getTime()){
                setDepartureDate(Todaydpdate); //Set today Date
                setFormState((prev) => ({...prev, ["numberOfDays"]: 1}));
            }else{
                setDepartureDate(dpdate);
            }
             
        }
        if(rtsdate != null){
            
            let dpdate = new Date(JSON.parse(localStorage.getItem("departureDate")));
            let rtdate = new Date(JSON.parse(localStorage.getItem("returnDate")));           
            let Todaydpdtpone1 = moment(dpdate,'YYYY-MM-DD').format('YYYY-MM-DD');
            
            if(Todaydpdate1 !== Todaydpdtpone1 && dpdate.getTime() < Todaydpdate.getTime()){
                setReturnDate(Todaydpdtpone); //Set today Date plush one
            }else{
                setReturnDate(rtdate);
            }
        }
        
        if(departureCity === "" && destination === ""){
            setFormState((prev) => ({ ...prev, ...formS }));  
        }

        if(rmWisePess != null && rmWisePess != undefined){
            setRoomsWisePess(JSON.parse(rmWisePess));
        }

        if(rmWisechage != null && rmWisechage != undefined){
            setChildAgeManage(JSON.parse(rmWisechage));
        }
    },[]);

    useEffect(() => {

        // set Google Maps Key.
        Geocode.setApiKey(commanConst.GOOGLE_KEY);
        
        // set response language.
        Geocode.setLanguage("en");
        
        // A Geocoding request with region=es (Spain) will return the Spanish city.
        Geocode.setRegion(regionCode);

        // Get latitude & longitude from address.
        if(destinationName !== ''){
            Geocode.fromAddress(destinationName).then(
                (response) => {
                const { lat, lng } = response.results[0].geometry.location;
                setDestLat(lat);
                setDestLon(lng);
                },
                (error) => {
                console.error(error);
                }
            );
        }
        
        
        const timer = setTimeout(() => {
            fetchcities(departureCity);
          }, 2000); 
        const timer1 = setTimeout(() => {
        fetchdestins(destination);
        }, 3000);


          let totalpas = 0;
          let ttlAdults = 0;
          let ttlChildren = 0;
          let ttlInfants = 0;
          let ttlYouth = 0;
          //Total passengers
        roomsWisePess.map((room) => {
            totalpas += room.adults + room.children + room.infants + room.youth; 
            ttlAdults += room.adults;
            ttlChildren += room.children;
            ttlInfants += room.infants;
            ttlYouth += room.youth;
        });
 
          
          if(totalpas < 9){ 
            setFormState((prev) => ({...prev, ["setDisable"]: false}));
          }else{
            setFormState((prev) => ({...prev, ["setDisable"]: true}));
          }
        

          //Total passengers
          setFormState((prev) => ({...prev, ["passengers"]: totalpas}));
          setFormState((prev) => ({...prev, ["totalAdults"]: ttlAdults}));
          setFormState((prev) => ({...prev, ["totalChildren"]: ttlChildren}));
          setFormState((prev) => ({...prev, ["totalInfants"]: ttlInfants}));
          setFormState((prev) => ({...prev, ["totalYouth"]: ttlYouth}));
          
        //Redirect Url
        let rurl = `/hotellist/wannafly/${yourBudget}/${departureCityName}/${departureCity}/${destinationName}/${destination}/${destLat}/${destLon}/${moment(departureDate).format('MM-DD-YYYY')}/${moment(returnDate).format('MM-DD-YYYY')}/${passengers}/${totalAdults}/${totalChildren}/${totalInfants}/${totalYouth}`;
        
        setFormState((prev) => ({...prev, ["redirecturl"]: rurl}));

        localStorage.setItem("homeSerach",JSON.stringify(formState));
        localStorage.setItem("departureDate",JSON.stringify(departureDate));
        localStorage.setItem("returnDate",JSON.stringify(returnDate));
        localStorage.setItem("destLat",JSON.stringify(destLat));
        localStorage.setItem("destLon",JSON.stringify(destLon));
        localStorage.setItem("roomsWisePess",JSON.stringify(roomsWisePess));
        localStorage.setItem("childAgeManage",JSON.stringify(childAgeManage));
 
    }, [yourBudget, departureCity, destination, departureDate, numberOfDays, returnDate,passengers, roomsWisePess, childAgeManage ]);


    //Get Departure City
    const fetchcities = async (props) => {
        axios
        .get(`https://achelper.militaryfares.com/autocomplete?q=${props}&hl=en`)
        .then(response => {            
            if( typeof response != 'undefined' &&  response != null && response.status === 200){                 
                    if (response.data.status != 'ERR') {
                        dispatch(getDeparturecity(response.data));
                    }
                }
            })
        .catch((err) => {
            console.log("Err", err);
        });
            
    };

    //Get destination
    const fetchdestins = async (props) => {
        axios
        .get(`https://achelper.militaryfares.com/autocomplete?q=${props}&hl=en`)
        .then(response => {                
            if( typeof response != 'undefined' &&  response != null && response.status === 200){                   
                if (response.data.status != 'ERR') {
                    dispatch(getdestinations(response.data));
                }                      
            }
        })
        .catch((err) => {
            console.log("Err", err);
        });            
    };    

    
    //Change Departure Date
    const departureDateChange = (e) => {
         
        //Set departure Date
        setDepartureDate(e);
        
        let rdate = new Date(moment(e).format('YYYY-MM-DD'));
            
        // add a day
        rdate.setDate(rdate.getDate() + 1);        
        setReturnDate(rdate);

        // setReturnDate(e);
        setFormState((prev) => ({...prev, ["numberOfDays"]: 1}));

        //Set Min Return Date
        setMinReturnDate(rdate);

    } 

    //Change Date Format
    const departureDateChangeFrm = () => {
        return moment(departureDate,'DD/MM/YYYY').format('DD/MM/YYYY');
    }

    //Date picket Custom Field
    const CustomInputDepart = React.forwardRef((props,ref) => {
        return (
            <input
                 
                onClick={props.onClick}
                value={departureDateChangeFrm()}
                type="text"
                readOnly={true}
                ref={ref}
                 
            />
        )
    })

     
    //Change Return Date
    const returnDateChange = (e) => {
        setReturnDate(e);
        
        const startDate = moment(departureDate);
        const timeEnd = moment(e);
        const diff = timeEnd.diff(startDate);
        const diffDuration = moment.duration(diff);
        
        // console.log();
        // setFormState((prev) => ({...prev, ["numberOfDays"]: diffDuration.days()}));

        let msDiff = new Date(e).getTime() - new Date(departureDate).getTime();    //Future date - current date
        let datediff = (Math.floor(msDiff / (1000 * 60 * 60 * 24))) + 1;
        
        setFormState((prev) => ({...prev, ["numberOfDays"]: datediff}));
    }
    
    
    //Change Date Format
    const returnDateChangeFrm = () => {
        return moment(returnDate,'DD/MM/YYYY').format('DD/MM/YYYY');
    }

    //Date picket Custom Field
    const CustomInputReturn = React.forwardRef((props,ref) => {
        return (
            <input
                onClick={props.onClick}
                value={returnDateChangeFrm()}
                type="text"
                readOnly={true}
                ref={ref}
            />
        )
    })


    //Open passengers limit popup
    const openModalPass = () => {
        setIsOpenPass(true);
    }

    //close passengers limit popup
    const closeModalPass = () => {
        setIsOpenPass(false);
    }
  
    //passengers validation popup
    const openModalValidation = () => {        
        if(departureCity == ""){
            setIsOpenCity(true);
        }else if(passengers == 0){
            setIsOpenPassZero(true);
        }else{
            window.location.href = redirecturl;
        }        
    }

    //close passengers limit popup
    const closeModalCity = () => {
        setIsOpenCity(false);
    }

    //Close passengers validation popup
    const closeModalPassZero = () => {
        setIsOpenPassZero(false);
    }

    //Add Rooms
    const addRooms = () => {
        
        setRoomsWisePess(
            roomsWisePess.map((prevRoom,idx) =>
                prevRoom.hideshow == 'show'
                ? { ...prevRoom, hideshow: ''}
                : { ...prevRoom }
        ));

        //Open maximum number message
        if((passengers + 1) >= 10){
            openModalPass();
            return false;
        }

        //Add new room
        let rooNum = roomsWisePess.length;
        setRoomsWisePess((prevRoom) => [
            ...prevRoom,
            {
                // id: rooNum,
                hideshow: 'show',
                adults: 1,
                children: 0,
                infants: 0,
                youth: 0,  
            },
        ]);

        //add chile age room wise
        setChildAgeManage((prev) => [
            ...prev,
            {
                ages: []
            }
        ])
    }
   
    const increasebtnPass = (e) => {
        let incClass = e.target.className.split(' ');
        let splitval = incClass[1].split('_'); 
        
            //Open maximum number message
            if((passengers + 1) >= 10){
                openModalPass();
            }else{
                //Add age room wise
                if(splitval[0] == 'children'){
                    setChildAgeManage(
                        childAgeManage.map((prev,i) =>
                            i == splitval[1]
                                ? {  
                                    ...prev,
                                    ages: [...prev.ages, {age:1}],
                                }
                                : { ...prev }
                        )
                    )
                }
            }    

        setRoomsWisePess(
        roomsWisePess.map((prevRoom,idx) =>
         
        //Increase passenger 
        idx == splitval[1]
            ? splitval[0] == 'adults'
                ? { ...prevRoom, adults: setDisable == false ? prevRoom.adults + 1 : prevRoom.adults }
                : splitval[0] == 'children'
                    ? { ...prevRoom, children: setDisable == false ? prevRoom.children + 1 : prevRoom.children }
                    : splitval[0] == 'infants'
                        ? { ...prevRoom, infants: setDisable == false ? prevRoom.infants + 1 : prevRoom.infants }
                        : splitval[0] == 'youth'
                            ? { ...prevRoom, youth: setDisable == false ? prevRoom.youth + 1 : prevRoom.youth }
                            : { ...prevRoom }
            : { ...prevRoom }
        ));

    }
    
    const decreasebtnPass = (e) => {
        let decClass = e.target.className.split(' ');
        let splitval = decClass[1].split('_'); 
        let childAgelth = childAgeManage[splitval[1]]['ages'].length - 1;

        setRoomsWisePess(
        roomsWisePess.map((prevRoom,idx) =>
        
        //Decrease passenger
        idx == splitval[1]
            ? splitval[0] == 'adults'
                ? { ...prevRoom, adults: prevRoom.adults != 0 ? prevRoom.adults - 1 : 0 }
                : splitval[0] == 'children'
                    ? { ...prevRoom, children: prevRoom.children != 0 ? prevRoom.children - 1 : 0 }
                    : splitval[0] == 'infants'
                        ? { ...prevRoom, infants: prevRoom.infants != 0 ? prevRoom.infants - 1 : 0 }
                        : splitval[0] == 'youth'
                            ? { ...prevRoom, youth: prevRoom.youth != 0 ? prevRoom.youth - 1 : 0 }
                            : { ...prevRoom }
            : { ...prevRoom }
        ));

        //Remove chiled age
        if(splitval[0] == 'children'){
            setChildAgeManage(
                childAgeManage.map((prev,i) =>
                    i == splitval[1]
                        ? {  
                            ...prev,
                            ages: [...prev.ages.filter((item,i) => i !== childAgelth)],
                        }
                        : { ...prev }
                )
            )
        }
    }

    //Remove Room
    const removeRoom = (e) => {
        //Remove age
        setChildAgeManage(childAgeManage.filter((item,idex) => (idex) !== e));
        
        //Remove room
        setRoomsWisePess(roomsWisePess.filter((item,idex) => (idex) !== e));
    }

    //Select Age
    const selChiledAge = (room,inx,age,r) => {
        
        setChildAgeManage(
            childAgeManage.map((prev,i) =>
                i == room
                    ? {  
                          ...prev,
                          ages: [...prev.ages.map((itm,i) => 
                            i == inx
                            ? {...itm, age:age}
                            : {...itm}
                          )],
                      }
                    : { ...prev }
            )
        )

        //Hide chiled age list
        $(`#manageChildAge_${room}_${r}_${inx}`).removeClass('show');
    }
 
    return (
        <div className={`modify_toggle_div collapse ${filterForm}`} id="modifybtn_toggle">
            <div className="card">
                <p><span><i className="fas fa-suitcase-rolling mr-2"></i>+<i className="fas fa-plane ml-2"></i></span><span
                    className="ml-3">Hotel<span className="mx-2">+</span>Flight</span></p>

                <label>Your Budget US $</label>
                <div className="ranger">
                    <form>
                        <input type="range" name="yourBudget" min="300" max="5000" onChange={handleChange} value={yourBudget} />
                        <output htmlFor="foo" className="lbldisp"></output>
                    </form>
                </div>

                <label>Departure City</label>
                <div className="input-group mb-3">
                    <div className="input-group-prepend">
                        <span className="input-group-text" id="basic-addon1"><i className="fa fa-map-marker" aria-hidden="true"></i>
                        </span>
                    </div>
                    <input type="text" name="departureCity" id="departureCity" placeholder="Type To Serach" onChange={handleChange} className="cityanddesti" value={departureCityName} />
                        <ul className="cityanddestilist managecitylist">
                        <HomeCities onCitySelect={(name,value) => {setFormState((prev) => ({...prev, ["departureCityName"]: name})); setFormState((prev) => ({...prev, ["departureCity"]: value}))}}/>
                        </ul>
                        <Modal
                        isOpen={modalIsOpenCity}                                                             
                        onRequestClose={closeModalCity}
                        style={customStyles}
                        >
                        <h2>Please enter departure city. </h2>
                        <div className="passenger_modal_btn">
                            <button onClick={closeModalCity}>OK</button>
                        </div>
                    </Modal>
                </div>

                <label>Destination</label>
                <div className="input-group mb-3">
                    <div className="input-group-prepend">
                        <span className="input-group-text" id="basic-addon1"><img src={process.env.PUBLIC_URL + "/assets/images/sparkler.png"} /></span>
                    </div>
                    <input type="text" name="destination" id="destination" placeholder="Type To Serach" onChange={handleChange} className="cityanddesti" value={destinationName} />
                    <ul className="cityanddestilist managedestinlist">
                    <HomeDestination onDestinsSelect={(name,value) => {
                        let destcode = value.split("_");
                        setFormState((prev) => ({...prev, ["destinationName"]: name})); 
                        setFormState((prev) => ({...prev, ["destination"]: destcode[0]}))
                        setFormState((prev) => ({...prev, ["regionCode"]: destcode[1]}))
                        }} 

                    />
                    </ul>
                </div>

                <label>Departure Date</label>
                <div className="input-group flight_filter_date mb-3">
                    <div className="input-group-prepend">
                        <span className="input-group-text" id="basic-addon1"><i className="fa fa-calendar" aria-hidden="true"></i>
                        </span>
                    </div>
                    <DatePicker  
                    selected={departureDate}
                    customInput={<CustomInputDepart />}
                    onChange={date=>departureDateChange(date)}
                    // onChange={(date) => {setFormState((prev) => ({...prev, ["departureDate"]: date}))}}
                    dateFormat='dd/MM/yyyy'
                    minDate={new Date()}
                    maxDate={maxSelDate}
                    className="form-control"
                    />
                </div>
                <label>Number of Days</label>
                    <div className="input-group mb-3 select-option">
                    <select className="form-control" name="numberOfDays" id="numberOfDays" onChange={handleChange} value={numberOfDays}>
                        {(() => {
                        let daylist = [];
                        for(let i=1; i<=365; i++){
                            daylist.push(<option key={i} >{i}</option>)
                        }
                        return daylist;
                    })()}    
                    
                    </select>
                </div>

                <label>Return Date</label>
                <div className="input-group flight_filter_date mb-3">
                    <div className="input-group-prepend">
                        <span className="input-group-text" id="basic-addon1"><i className="fa fa-calendar" aria-hidden="true"></i>
                        </span>
                    </div>
                    <DatePicker  
                    selected={returnDate}
                    customInput={<CustomInputReturn />}
                    onChange={date=>returnDateChange(date)}
                    // onChange={(date) => {setFormState((prev) => ({...prev, ["departureDate"]: date}))}}
                    dateFormat='dd/MM/yyyy'
                    minDate={minreturnDate}
                    maxDate={maxSelDate}
                    className="form-control"
                    />
                </div>

                <div className="flight_dropdown">
                    <label>Passengers</label>
                    <div className="input-group mb-3 select-option passenger-dropdown cityanddestiicon">
                        <div className="input-group-prepend">
                            <span className="input-group-text" id="basic-addon1"><i className="fa fa-user" aria-hidden="true"></i>
                            </span>
                        </div>
                        <input type="text" name="passengers" id="passengers" value={passengers + " Passengers"} readOnly className="form-control passpont" placeholder="0 Passenger" aria-describedby="basic-addon1" />
                    </div>
                    
                    <div className="list-dropdown-passengers passenger_flight">
                        {roomsWisePess != undefined ?
                            <HomePassengers roomsWisePess={roomsWisePess} removeRoom={removeRoom} decreasebtnPass={decreasebtnPass} increasebtnPass={increasebtnPass} childAgeManage={childAgeManage} selChiledAge={selChiledAge}/>
                        :''}

                        <button type="button" onClick={addRooms} className="add-room btn btn-primary mx-auto mt-3"><svg aria-hidden="true"
                            focusable="false" data-prefix="fas" data-icon="plus" className="svg-inline--fa fa-plus fa-w-14 "
                            role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                            <path fill="currentColor"
                                d="M416 208H272V64c0-17.67-14.33-32-32-32h-32c-17.67 0-32 14.33-32 32v144H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h144v144c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32V304h144c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z">
                        </path>
                        </svg> Room</button>
                    </div>

                    <Modal
                        isOpen={modalIsOpenPassZero}                                                             
                        onRequestClose={closeModalPassZero}
                        style={customStyles}
                        >
                        <h2>Please enter passenger. </h2>
                        <div className="passenger_modal_btn">
                            <button onClick={closeModalPassZero}>OK</button>
                        </div>
                    </Modal>
                    
                    <Modal
                        isOpen={modalIsOpenPass}                                                             
                        onRequestClose={closeModalPass}
                        style={customStyles}
                    >
                        <h2>Maximum number of passengers is 9.</h2>
                        <div className="passenger_modal_btn">
                            <button onClick={closeModalPass}>OK</button>
                        </div>
                        
                    </Modal>

                </div>
                <button className="btn modify_search_btn" onClick={openModalValidation}><i className="fas fa-search"></i>Search</button>

            </div>
        </div>
    )
}