import React from 'react';
import './App.css';
import Home from './containers/Home';
import HotelList from './containers/HotelList';
import {Route, Switch} from 'react-router-dom';
import { HotelDetails } from './containers/HotelDetails';
import { FlightList } from './containers/FlightList';
import { PassengersSummary } from './containers/PassengersSummary';
import ThankYou from './containers/ThankYou';

function App() {
  return (
     <React.Fragment>
        <Switch> 
          <Route exact path="/" component={Home}/>
          <Route exact path="/home" component={Home}/>
          <Route exact path="/hotellist/:a/:budget/:depacityName/:depacity/:destinationName/:destination/:destLat/:destLon/:depadate/:returndate/:passengers/:totalAdult/:totalChild/:totalInfant/:totalYouth/" component={HotelList}/>
          <Route exact path="/hotel-details/:a/:budget/:depacityName/:depacity/:destinationName/:destination/:destLat/:destLon/:depadate/:returndate/:passengers/:totalAdult/:totalChild/:totalInfant/:totalYouth/:c_key/:hid" component={HotelDetails} />    
          <Route exact path="/flightlist/:a/:budget/:depacityName/:depacity/:destinationName/:destination/:destLat/:destLon/:depadate/:returndate/:passengers/:totalAdult/:totalChild/:totalInfant/:totalYouth/:c_key/:hid/:room_key" component={FlightList} />    
          <Route exact path="/pass-summary/:c_key/:hid/:room_key/:tval/:api_key/:fc_key" component={PassengersSummary} />
          <Route exact path="/ThankYou/" component={ThankYou} />
        </Switch>
     </React.Fragment>  
  );
}

export default App;
