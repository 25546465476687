import {ActionTypes} from '../constants/actionTypes';

export const getDeparturecity = (cities) => {
    return {
        type:ActionTypes.GET_DEPARTURECITY,
        payload: cities
    }
}

export const getdestinations = (destins) => {
    return {
        type:ActionTypes.GET_DESTINATION,
        payload: destins
    }
}

export const getHotels = (hotels) => {
    return {
        type:ActionTypes.GET_HOTELS,
        payload: hotels
    }
}

export const getHotelsMap = (hotelsMap) => {
    return {
        type:ActionTypes.GET_HOTELSMAP,
        payload: hotelsMap
    }
}

export const getHotelDetails = (hotelDetails) => {
    return {
        type:ActionTypes.GET_HOTELDETAILS,
        payload: hotelDetails
    }
}

export const getFlights = (flights) => {
    return {
        type:ActionTypes.GET_FLIGHTS,
        payload: flights
    }
}

export const getPassSummary = (passSummary) => {
    return {
        type:ActionTypes.GET_PASSSUMMARY,
        payload: passSummary
    }
}